import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box, Button, Tooltip } from '@material-ui/core';

const maxLengthText = 210;

const useStyles = makeStyles((theme) => ({
    a: {
        color: theme.palette.text.secondary,
        textDecoration: "none",
        "& :hover": {
            color: "#000"
        }
    },
    box: {
        display: "flex",
        padding: theme.spacing(2),
        // border: 'solid',
        // borderColor: 'cornflowerblue',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        minHeight: '210px',
        maxHeight: '210px',
        overflowY: 'auto',
        justifyContent: "center",
        alignItems: "flex-start"
    },
    imageBox: {
        maxHeight: "210px",
        flex: 0.5,
        paddingRight: "10px",
        float: "left"
    },
    textsBox: {
        maxHeight: "auto",
        flex: 1
    },
    newsIcon: {
        height: "150px",
        width: "150px",
        objectFit: "cover",
        flex: 1
    }
}));

export default function NewsItem(props) {
    let title = props.title;
    let text = props.text;
    let date = props.date;
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const handleOnLoad = () => {
        
    }

    return (
        <Box className={classes.box}>
            <Box className={classes.imageBox}>
                <img 
                src={process.env.PUBLIC_URL + props.img} 
                alt="" 
                className={classes.newsIcon} 
                // onLoad={handleOnLoad}
                // style={{shapeOutside: process.env.PUBLIC_URL + props.img}} 
                />
            </Box>
            <Box className={classes.textsBox}>
                <p>{props.date}</p>
                <a href="" className={classes.a}><h2>{title}</h2></a>

                {text.length > maxLengthText ?
                                        <Tooltip title={text} TransitionComponent={({ children}) => children}>
                                            <p>{text.substring(0, maxLengthText) + "..."}</p>
                                        </Tooltip>
                                        :
                                       <p>{text}</p>
                                    }

                {/* <p>{
                    text.length > maxLengthText ?
                        text.substring(0, maxLengthText) + "..."
                        :
                        text
                }
                </p> */}
            </Box>
        </Box>
    );
}