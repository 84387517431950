import { Dialog, DialogContent, TableHead, TableRow, Typography, Box, IconButton, TableBody, FormControlLabel, Checkbox, ListItemText, TextField, InputLabel, Select, Input, MenuItem, makeStyles, withStyles, Radio, Popper, Paper, List, ListItem } from "@material-ui/core";
import { Cancel, PlayArrow, GetApp, Publish, Remove } from "@material-ui/icons";
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { Table, Tooltip, FormControl, Button } from "react-bootstrap";
import BatchOptionCheckbox from "./BatchOptionCheckbox";
import { OptionsPackageDownload, OptionsPackageUpload, createBatch, executeBatch, updateBatch } from "./RestService";
import { GlobalUseStyles } from "./GlobalUseStyles";
import clsx from 'clsx';
import { GetEmpty } from "./Common";
import { useSnackbar } from 'react-simple-snackbar';
import { errorSnackbarOptions, successSnackbarOptions, warningSnackbarOptions } from './SnackbarOptions';
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import MuiTableCell from "@material-ui/core/TableCell";
import config from "../config";
import Add from "@material-ui/icons/Add";

const pdmRelationships = {"has part": "\"cws_configure_has_part\"", "all relationships": "\"cws_configure_all_relationships\"", "no relationships": "\"cws_configure_no_relationships\""};

const initialTitles = [
    { key: 'Characteristic', name: 'Characteristic', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Type', name: 'Type', resizable: true, width: "30px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Default value', name: 'Default', resizable: true, width: "5px", editable: true, frozen: true, defaultTitle: "true" },
  ];

let titles = [
    { key: 'Characteristic', name: 'Characteristic', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Type', name: 'Type', resizable: true, width: "30px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Default value', name: 'Default', resizable: true, width: "5px", editable: true, frozen: true, defaultTitle: "true" },
  ];

const CustomRadio = withStyles({
    root: {
      color: "#0071B9",
      '&$checked': {
        color: "#0071B9",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        "& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root": {
          flexWrap: "nowrap",
          overflowX: "hidden"  // or "hidden"
        }
      },
    topButtonsDiv: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        marginTop: "20px",
        //marginLeft: theme.spacing(20)
    },
    topButton: {
        marginRight: "20px"
    },
    testInfoDiv: {
        display: "flex",
        flex: 1,
        height: "32px",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "row",
        marginTop: "20px",
        marginLeft: theme.spacing(4),
        paddingTop: "20px",
        paddingBottom: "30px"
    },
    testInfoItem: {
        display: "flex",
        justifyContent: "center",
        marginRight: "8px"
    },
    koneMaterialDiv: {
        display: "flex",
        flex: 1,
        height: "32px",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "row",
        paddingTop: "20px",
        marginTop: "20px",
        marginLeft: theme.spacing(4),
    },
    textField: {
        minHeight: "24px",
        maxHeight: "32px",
        marginRight: "32px"
    },
    testInfoDesc: {
        flex: 1
    },
    testInfoName: {
        flex: 1,
        marginRight: theme.spacing(2)
    },
    testInfoID: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(6),
        //flex: 1,
        width: "190px",
        backgroundColor: "#F0F0F0",
    },
    testInfoIDInput: {
        color: "#000"
    },
    generateIdButton: {
        //marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        width: "44px",
        minWidth: "44px",
        maxWidth: "44px",
        height: "29px",
        maxHeight: "29px",
        minHeight: "29px"
    },
    supplierSpecificBtn: {
        //marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        width: "44px",
        minWidth: "44px",
        maxWidth: "44px",
        height: "29px",
        maxHeight: "29px",
        minHeight: "29px"
    },
    paper: {
        marginBottom: "0px",
        paddingBottom: "4px",
        flex: "1",
        justifyContent: "start",
        alignItems: "left",
    },
    paper2: {
        backgroundColor: "transparent",
    },
    testButton: {
        margin: theme.spacing(2),
        textTransform: 'none',
        alignSelf: "left",
        border: "none",
        '&:hover': {
            backgroundColor: "white"
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: 'white',
        },
    },
    casesButtonsBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    testButtonDiv: {
        display: "flex",
        textTransform: 'none',
        alignItems: "left",
        alignSelf: "left",
        margin: "0 auto 0 0"
    },
    testOptionsItem: {
        textAlign: "left",
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(2),
    },
    dataSheet: {
        alignSelf: "left",
        minWidth: "10vw",
        maxWidth: "80vw",
        overflow: "scroll",
        minHeight: "10.0vh",
        maxHeight: "40.0vh",
        paddingLeft: 0,//(theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    testButtonOptions: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(0),
        marginBottom: theme.spacing(1),
        alignSelf: "flex-end"
    },
    testCasesButton: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(0),
        marginBottom: theme.spacing(1),
        alignSelf: "flex-end"
    },
    testCasesButtonsDiv: {
        minWidth: "10%",
        maxWidth: "100%",
        margin: "0 0 0 auto",
        display: "inline-flex",
        justifyContent: "flex-end",
        alignSelf: "flex-end"
    },
    testCasesButtonsDivBottom: {
        paddingTop: "16px",
        display: "flex",
        justifyContent: "flex-end",
        alignSelf: "flex-end"
    },
    addCaseButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    pdmOptionButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    addCaseIcon: {
        marginLeft: "0px"
    },
    executeDataCell: {
        padding: "0"
    },
    dialogPaper: {
        padding: "0px 0px",
        // minWidth: "500px",
        // maxWidth: "700px"
        minWidth: "500px",
        maxWidth: "1000px"
        
        //minHeight: "60vh",
        //maxHeight: "60vh"
    },
    topScrollPaper: {
        alignItems: 'flex-start',
      },
      topPaperScrollBody: {
        verticalAlign: 'top',
    },
    table: {
        border: "0px solid #F0F0F0",
        borderSpacing: "0px",
        tableLayout: "fixed",
        //borderCollapse: "collapse",
    },
    shown: {
        height: "auto",
        //margin: "auto",
        transform: "scaleY(1)",
        transformOrigin: "top",
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hidden: {
        height: "0px",
        transform: "scaleY(0)",
        transformOrigin: "bottom",
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    executeOptionsTable: {
        tableLayout: "auto",
        width: "100%"
    },
    inputStyle: {
        height: "8px",
    },
    orderPopup: {
        display: "flex",
        flexDirection: "column",
        flexFlow: "wrap",
        alignItems: "end"
    },
    orderPopupItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },
    popper: {
        maxWidth: "500px",
        minWidth: "50px",
    },
    dnone: {
        display: "none",
    },
    executeTRow: {
        "&:hover": {
         backgroundColor : "white !important"
        }
    }
    // inputStyleSelect: {
    //     height: "18px",
    // }
}));

function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

export default function ExecuteDialog(props) {
    const classes = useStyles();
    const globalClasses = GlobalUseStyles();
    const [testOptionsOpen, setTestOptionsOpen] = useState(false);
    const [executeSettings, setExecuteSettings] = useState(new Map());
    const [orderTitles, setOrderTitles] = useState(titles);
    const [caseSelect, setCaseSelect] = React.useState([]);
    const [toggleAll, setToggleAll] = useState(false);
    const [orderAllSelected, setOrderAllSelected] = useState(false);
    const forceUpdate = useForceUpdate();
    const [reRender3, setReRender3] = useState(false);
    const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
    const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
    const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
    const [customRelationships, setCustomRelationships] = React.useState(["has part"]);
    const [tableOrders, setTableOrders] = useState(props.selectedBatchOrders == null ? null : props.selectedBatchOrders);
    const { instance, accounts } = useMsal();
    const [koneMaterial, setKoneMaterial] = useState(props.selectedKoneMaterial == null ? null : props.selectedKoneMaterial);
    const [koneMaterialRev, setKoneMaterialRev] = useState(props.selectedKoneMaterialRev == null ? "" : props.selectedKoneMaterialRev);
    const [defChars, setdefChars] = useState(null);  
    const [saveCase, setSaveCase] = useState(false);
    const [resetOrders, setResetOrders] = useState(false);
    const [orderInputValue, setOrderInputValue] = useState("");
    const [firstRun, setFirstRun] = useState(true);
    const [initialOpen, setInitialOpen] = useState(true);
    const [firstOpen, setFirstOpen] = useState(true);
    const [firstInitialize, setFirstInitialize] = useState(true);
    const optionFileInput = useRef(null); 
    const [optionFile, setOptionFile] = useState(null); 
    const [orderListOpen, setOrderListOpen] = useState(false);
    const [lock, setLock] = useState(false);
    const [skipModeCurrentType, setSkipModeCurrentType] = useState("KONE material");
    const [skipModeCurrentValue, setSkipModeCurrentValue] = useState("");
    const [skipModeValues, setSkipModeValues] = useState([]);
    const [tempDoMaterialSubstitutionValue, setTempDoMaterialSubstitutionValue] = useState([]);
    const [doMaterialSubstitutionValue, setDoMaterialSubstitutionValue] = useState(GetValue("doMaterialSubstitution") ? GetValue("doMaterialSubstitution") : " ");
    const history = useHistory();
    const [isMSDisabled, setIsMSDisabled] = useState(false);

    const PopperMy = function (props) {
        return <Popper {...props} style={{maxWidth: "460px", minWidth: "160px"}} placement="bottom-start" />;
     };

    function GetValue(name){
        for (var key of Array.from(executeSettings.keys())) {
            if (key === name)
            {
                return executeSettings.get(key);
            }
        }
        return null;
    }

    const handleCaseSelectChange = (e, value, r, details) => {
        var nameVal = value.map(function(el){return el["name"]});
        // value = value.map((obj) =>
        // {
        //     obj["selected"] = true;
        //     return obj;
        // });
        // console.log("reason: ", r);
        // console.log("details: ", details);
        console.log("nameVal: ", nameVal);
        console.log("value: ", value);
        console.log("caseSelect1: ", caseSelect);
        console.log("orderAllSelected: ", orderAllSelected);
        console.log("orderTitles: ", orderTitles);
        console.log("firstRun: ", firstRun);
        // let tmpLen = value.length;
        // if (value.some(val => val.name === "All"))
        // {
        //     tmpLen = tmpLen-1;
        // }
        // if (value.length < orderTitles.length-3)
        // {
        //     value = value.filter(v1 => v1.name != "All");
        // }
        caseSelect.length = 0;
        if (orderAllSelected == false && value.some(obj => obj.name === "All")) {
            setOrderAllSelected(true);
            props.setToggleAll(true);
            if (firstRun == true)
            {
                setCaseSelect([...value]);
                props.setCaseSelect([...value]);
            }
            else
            {
                props.orderTitles.forEach(element => {
                    if ("selected" in element)
                    {
                        element["selected"] = true;
                    }
                });
                setCaseSelect([...props.orderTitles.filter((a) => (!("defaultTitle" in a) || a["defaultTitle"] == "false"))]);
                props.setCaseSelect([...props.orderTitles.filter((a) => (!("defaultTitle" in a) || a["defaultTitle"] == "false"))]);
            }
        }
        else if (orderAllSelected == true && (details != null && details["option"]["name"] === "All"))
        {
            setOrderAllSelected(false);
            setCaseSelect([]);
            props.setCaseSelect([]);
        }
        else {
            setOrderAllSelected(false);
            if (value.some((obj) => obj["key"] == "All"))
            {
                value = value.filter((obj2) => obj2["key"] != "All");
            }
            props.setToggleAll(false);
            setCaseSelect([...value]);
            props.setCaseSelect([...value]);
            //setCaseSelect([]);
        }
        // var alls =  nameVal.filter(item => item === 'All');
        // if (alls.length > 1){
        //     setOrderAllSelected(false);
        //     setCaseSelect([]);
        //     value = [];
        //     setResetOrders(!resetOrders);
        // }
        //setCaseSelect([...value]);
        console.log("value2: ", value);
        console.log("caseSelect2: ", caseSelect);
        setFirstRun(false);
        setReRender3(!reRender3);
        // console.log(executeSettings);
      };

    const handleSetTestOptionsOpen = (e = null) => {
        console.log("firstOpen: ", firstOpen);
        console.log("SETTINGS: ", executeSettings);
        if (firstOpen == true)
        {
            let tmpOrderTitles = props.orderTitles;
            let tmpCaseSelect = props.caseSelect;
            console.log("orderTitles: ", tmpOrderTitles);
            console.log("caseSelect: ", tmpCaseSelect);
            console.log("toggleAll: ", props.toggleAll);
            console.log("testOptionsOpen: ", testOptionsOpen);
            let addAll = false;
            if (props.toggleAll == true)
            {
                addAll = true;
            }
            let filteredCaseSelect = tmpCaseSelect.filter(obj => tmpOrderTitles.some((obj2) => obj2.key === obj.key));
            if (addAll == true)
            {
                let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "" }
                filteredCaseSelect.push(newObj);
                setOrderAllSelected(true);
            }
            console.log("filteredCaseSelect: ", filteredCaseSelect);
            filteredCaseSelect.forEach(element => {
                element["selected"] = true;
            });
            let notSelected = [...tmpOrderTitles];
            //orderTitles.filter(obj => !filteredCaseSelect.some((obj2) => obj2.key === obj.key));
            notSelected.forEach(element => {
                if (!filteredCaseSelect.some((obj2) => obj2.key === element.key))
                {
                    element["selected"] = false;
                }
                if (addAll == true)
                {
                    element["selected"] = true;
                }
            });
            console.log("notSelected: ", notSelected);
            setOrderTitles([...notSelected]);
            props.setOrderTitles([...notSelected]);
    
            setCaseSelect([...filteredCaseSelect]);
            setTestOptionsOpen(!testOptionsOpen);
            setFirstOpen(false);
            forceUpdate();
        }
        else
        {
            console.log("toggleAll: ", toggleAll);
            console.log("props.orderTitles: ", props.orderTitles);
            console.log("props.caseSelect: ", props.caseSelect);
            let addAll = false;
            if (props.toggleAll == true)
            {
                addAll = true;
            }
            let filteredCaseSelect = props.caseSelect.filter(obj => props.orderTitles.some((obj2) => obj2.key === obj.key));
            if (addAll == true)
            {
                let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "" }
                filteredCaseSelect.push(newObj);
                setOrderAllSelected(true);
            }
            console.log("filteredCaseSelect: ", filteredCaseSelect);
            filteredCaseSelect.forEach(element => {
                element["selected"] = true;
            });
            let notSelected = [...props.orderTitles];
            //orderTitles.filter(obj => !filteredCaseSelect.some((obj2) => obj2.key === obj.key));
            notSelected.forEach(element => {
                if (!filteredCaseSelect.some((obj2) => obj2.key === element.key))
                {
                    element["selected"] = false;
                }
                if (addAll == true)
                {
                    element["selected"] = true;
                }
            });
            console.log("notSelected: ", notSelected);
            notSelected = notSelected.filter(e => e["key"] != "All");
            console.log("notSelected2: ", notSelected);
            setOrderTitles(notSelected);
            props.setOrderTitles(notSelected);
            console.log("filteredCaseSelect2: ", filteredCaseSelect);
            console.log("caseSelect: ", caseSelect);
            setCaseSelect([...filteredCaseSelect]);
            props.setCaseSelect([...filteredCaseSelect]);
            setTestOptionsOpen(!testOptionsOpen);
            forceUpdate();
        }
        if (e != null)
        {
            if (e["type"] == "mouseup" || e["type"] == "click")
            {
                if (props.mode == "batchtable")
                {
                    console.log("e titles: ", e, titles);
                    setOrderTitles(titles);
                    setCaseSelect([]);
                    props.setOrderTitles(titles);
                    props.setCaseSelect([]);
                }
            }
        }
    }

    const handleRelationshipSelectChange = (event, key) => {
        event.stopPropagation();
        var val = key.props["value"].toString();

        customRelationships.length = 0;
        customRelationships.push(val);
        setCustomRelationships([...customRelationships]);
           
        console.log(customRelationships);
        setReRender3(!reRender3);
      };

      const handleOptionFileUpload = (e) => {
        var extension = e.target.files[0].name.split('.').pop();
        if (extension.toLowerCase() != "zip")
        {
            openErrorSnackbar(<div style={{whiteSpace: "pre-line"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"File type \"." + extension + "\" is not allowed"}</p></div>,300000)
            return;
        }

        let buffer;
        let errorMsg = "";
        const handleUploadEnd = () => {
            if (errorMsg != "")
            {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{errorMsg}</p></div>,300000)
                return;
            }

            setOptionFile(e.target.files[0]);
            let optionsFileULData = null;
            let message = "";
            const optionsFileUL = async () => {
                optionsFileULData = await OptionsPackageUpload(instance, props.selectedKoneMaterial, props.selectedKoneMaterialRev, e.target.files[0].name, e.target.files[0],accounts[0].username);
            }
            optionsFileUL().catch((error)=>{message += error.message+"\n"}).then(() => {
            if (message != "")
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000)
            }
            else
            {
                openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"CAD options file upload successful."}</p></div>,300000)
            }
            });
        }; 

        if (extension.toLowerCase() == "zip")
        {
            const readBin = async () => {
                buffer = await e.target.files[0].arrayBuffer();
            };
            readBin().then(() => {
                let byteArray = new Int8Array(buffer);
                console.log("byteArray: ", byteArray);
                // magic number for empty zip
                if (byteArray[0] == 80 && 
                    byteArray[1] == 75 &&
                    byteArray[2] == 5 &&
                    byteArray[3] == 6)
                    {
                        errorMsg = "Empty zip file. Check the contents of the zip file"
                    }
                handleUploadEnd();
            });
        }
        
          
    }

    const GetRunningNum = (orderName) => {
        let splitName = orderName.split("-");
        if (splitName.length >= 2) {   
            return splitName[splitName.length - 2].slice(-4);
        }
        else {
            return orderName.slice(-4);
        }
    };

    const GetRunningNumAndPos = (orderName) => {
        let splitName = orderName.split("_");
        if (splitName.length >= 2) {   
            return splitName[splitName.length - 1];
        }
        else {
            let splitName2 = orderName.split("-");
            if (splitName2.length >= 2)
            {
                return splitName2[splitName2.length-2].slice(-4) + "-" + splitName2[splitName2.length-1];
            }
            else
            {
            return orderName.slice(-4);
        }
        }
    };

    const handleExecuteBatch = () => {
        if (caseSelect.length == 0)
        {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"No orders selected"}</p></div>,300000);
            return;
        }
        let executeData = null;
        // console.log("BATCH: ", selectedBatch);
        // console.log("ID: ", selectedBatch["id"]);
        // console.log("koneMaterial: ", koneMaterial);
        // console.log("koneMaterialRev: ", koneMaterialRev);

        //const promises = [];
        // if (executeSettings.has("confPDM")){
        //     if (executeSettings.get("confPDM") == "true")
        //     {
        //         executeSettings.set("relationships", customRelationships);
        //     }
        // }

        let checkedItemsObj = {};
        // console.log("executeSettings: ", executeSettings);
        if (executeSettings.has("deepScan"))
        {
            if (executeSettings.get("deepScan") == true)
            {
                executeSettings.set("levelOfScan", "deep_check");
                executeSettings.set('saveTrace', true);
            }
            else
            {
                executeSettings.set("levelOfScan", "none");
                executeSettings.set('saveTrace', false);
            }
        }
        else
        {
            executeSettings.set("levelOfScan", "none");
            executeSettings.set('saveTrace', false);
        }
        executeSettings.delete("deepScan");
        executeSettings.forEach((value, key) => (checkedItemsObj[key] = value));
        let tempSelectedOrders = [];

        console.log("orderTitles: ", orderTitles);
        console.log("caseSelect: ", caseSelect);
        
        if (caseSelect[0]["key"] == "All")
        {
            orderTitles.filter((a) => (!("defaultTitle" in a) || a["defaultTitle"] == "false")).forEach(element => {
                tempSelectedOrders.push(GetRunningNumAndPos(element["key"]));
            });
        }
        else {
            caseSelect.forEach(element => {
                tempSelectedOrders.push(GetRunningNumAndPos(element["key"]));
            });
        }
        
        let tmpCustomRelationShips = [];
        customRelationships.forEach(element => {
            if (Object.keys(pdmRelationships).includes(element))
            {
                tmpCustomRelationShips.push(pdmRelationships[element]);
            }
        });
        
        if(GetValue("confPDM") === false) {
            tmpCustomRelationShips = [];
            tmpCustomRelationShips.push(pdmRelationships['no relationships']);
        }

        checkedItemsObj["relationships"] = tmpCustomRelationShips.join(';');
        checkedItemsObj["environment"] = "qa";
        console.log("checkedItemsObj: ", checkedItemsObj);
        console.log("selectedBatch: ", props.selectedBatch);
        console.log("tempSelectedOrders: ", tempSelectedOrders);
        let message = "";
        const execute = async () => {
            // console.log("CHECKED: ", checkedItemsObj); 
            executeData = await executeBatch(instance, props.selectedBatch["id"], tempSelectedOrders, checkedItemsObj);
            console.log("executeData: ", executeData); 
            if (executeData["exception"])
            {
                message += executeData["exception"];
            }
            // if (executeData["executeOrders"])
            // {
            //     selectedBatch["executeOrders"] = executeData["executeOrders"]; 
            // }
            // if (executeData["executeArguments"])
            // {
            //     selectedBatch["executeArguments"] = executeData["executeArguments"]; 
            // }
        }; 
        execute().catch((error) => {message = error.message.toString();}).then(() => {
            // console.log("EXECUTEDATA: ", executeData);
            if (message == ""){
                
                openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch execute started successfully."}</p></div>,300000);
                let tmpBatch = props.selectedBatch;
                tmpBatch["status"] = "Processing";
                let tmpArr = [tmpBatch];
                props.setSelectedBatch(tmpBatch);
                props.setBatches(props.batches.map(obj => tmpArr.find(o => o.id === obj.id) || obj));
            } else {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
            }
    
        });
        
        // if (lockFields == false) {
        //     // promises.push(handleSaveCase);
        //     // promises.push(execute);
            
        //     // handleSaveCase().catch(console.error).then(() => {
        //     //     execute().catch(console.error).then(() => {
        //     //         console.log("EXECUTEDATA: ", executeData);
        //     //     });
        //     // });
        // } else {
        //     // promises.push(execute);
            
        //     // execute().catch(console.error).then(() => {
        //     //     console.log("EXECUTEDATA: ", executeData);
        //     // });
        // }
        // Promise.all(promises).then((response) => response.map(res => console.log("RES: ", response))).catch((err) => console.log(err));
    }

    const handleSaveCase = (executeAfter) => {

         if (GetValue("confPDM") === false && GetValue("sendToCAD") === false) {
              alert("Please select atleast any one configuration option!");
              return;
        }

        setLock(true);
        let batchData = null;
        let message = "";
        let batchID = "";
        let checkedItemsObj = {};
        let tempSelectedOrders = [];

        if (props.selectedBatchID.trim() == "")
          message = "Missing batch name.";    
        if (props.batchDescription.trim() == "")
          message = "Missing batch description.";
        if (message != "")
        {
          openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000);
          setLock(false);
          return;
        }
                
        const data = async () => {
            let foundMaterials = [];
            // console.log("BATCH: ", selectedBatch);
            //console.log("tableOrders: ", tableOrders);
            // console.log("props.newBatch: ", props.newBatch);
            //console.log("koneMaterial: ", koneMaterial);
            // console.log("props.batches: ", props.batches);
            // console.log("customRelationships: ", customRelationships);

            let skipModeString = "";
            let skipModeValuesArr = [];
            if (executeSettings.has("cws_skipmode"))
            {
                if (executeSettings.get("cws_skipmode") == true)
                {
                    skipModeValues.forEach((val) => {
                        let type = "";
                        if (val["type"].toLowerCase() == "price key")
                        {
                            type = "pricekey:";
                        }
                        else if (val["type"].toLowerCase() == "license")
                        {
                            type = "license:";
                        }
                        let tmpVal = val["value"];
                        skipModeValuesArr.push(type+tmpVal);
                    });
                }
            }
            skipModeString = skipModeValuesArr.join(",");
            //console.log("skipModeString:", skipModeString);

            if (skipModeString != "")
            {
                executeSettings.set("cws_testing_skip_objects", skipModeString);
            }
            
            if(executeSettings.get("doMaterialSubstitution") == true)
            {
                executeSettings.set("doMaterialSubstitutionValue", doMaterialSubstitutionValue);
            }
            
            if (executeSettings.has("deepScan"))
            {
                if (executeSettings.get("deepScan") == true)
                {
                    executeSettings.set("levelOfScan", "deep_check");
                    executeSettings.set('saveTrace', true);
                }
                else
                {
                    executeSettings.set("levelOfScan", "none");
                }
            }
            else
            {
                executeSettings.set("levelOfScan", "none");
            }
            executeSettings.delete("deepScan");
            executeSettings.forEach((value, key) => (checkedItemsObj[key] = value));
            

            if(GetEmpty(caseSelect) == false)
            {
                if (caseSelect[0]["key"] == "All")
                {
                    orderTitles.filter((a) => (!("defaultTitle" in a) || a["defaultTitle"] == "false")).forEach(element => {
                        tempSelectedOrders.push(GetRunningNumAndPos(element["key"])); // element["key"].slice(-4));
                    });
                }
                else {
                    caseSelect.forEach(element => {
                        tempSelectedOrders.push(GetRunningNumAndPos(element["key"]));
                    });
                }
            }

            let tmpCustomRelationShips = [];
            customRelationships.forEach(element => {
                if (Object.keys(pdmRelationships).includes(element))
                {
                    tmpCustomRelationShips.push(pdmRelationships[element]);
                }
            });

            if(GetValue("confPDM") === false) {
                tmpCustomRelationShips = [];
                tmpCustomRelationShips.push(pdmRelationships['no relationships']);
            }

            checkedItemsObj["relationships"] = tmpCustomRelationShips.join(',');
            checkedItemsObj["environment"] = config.environment;
            tempSelectedOrders = tempSelectedOrders.filter((obj2) => obj2 != "All");

            let tmpKoneMaterial = props.selectedKoneMaterial;
            let tmpKoneMaterialRev = props.selectedKoneMaterialRev;
            let tmpTableOrders = props.tableOrders;
            let tmpDefCharacteristics = props.defChars;

            if (props.mode == "batchtable")
            {
                tmpKoneMaterial = props.selectedBatch["material"];
                tmpKoneMaterialRev = props.selectedBatch["materialRevision"];
                tmpTableOrders = props.selectedBatch["orders"];
                tmpDefCharacteristics = props.selectedBatch["defaultCharacteristics"];
            }
            console.log("props.selectedBatch: ", props.selectedBatch);
            console.log("props.selectedBatchID: ", props.selectedBatchID);
            console.log("koneMaterial: ", tmpKoneMaterial);
            console.log("koneMaterialRev: ", tmpKoneMaterialRev);
            console.log("props.batchDescription: ", props.batchDescription);
            console.log("tempSelectedOrders: ", tempSelectedOrders);
            console.log("tableOrders: ", tmpTableOrders);
            console.log("props.tableOrders: ", props.tableOrders);
            console.log("defChars: ", tmpDefCharacteristics);
            console.log("checkedItemsObj: ", checkedItemsObj);
            console.log("props.mode: ", props.mode);

            let defCharKeys = [];
            let tmpDefCharacteristics2 = tmpDefCharacteristics;
            if (props.newBatch == false)
            {
                tmpDefCharacteristics2 = props.selectedBatch["defaultCharacteristics"]
            }
            tmpDefCharacteristics2.forEach(element => {
                defCharKeys.push(element["name"]);
            });

            tmpTableOrders.forEach(element => {
                defCharKeys.forEach(defCharKey => {
                    if (!(defCharKey in element["characteristics"]))
                    {
                        element["characteristics"][defCharKey] = ""
                    }
                });
            });

            if (props.newBatch == true)
            {
                batchData = await createBatch(instance, props.selectedBatchID, props.batchDescription, tmpKoneMaterial, tmpKoneMaterialRev, tmpTableOrders, tmpDefCharacteristics, checkedItemsObj, tempSelectedOrders, props.selectedPDMDescription);
                console.log("batchData: ", batchData);
                batchData["executeArguments"] = checkedItemsObj;
                batchData["executeOrders"] = tempSelectedOrders;
                batchData["material"] = tmpKoneMaterial;
                batchData["materialRevision"] = tmpKoneMaterialRev;
                batchData["orders"] = tmpTableOrders;
                let tempBatches = [];
                if (props.batches != null)
                {
                    tempBatches = [...props.batches];
                }
                tempBatches.push(batchData);
                props.setBatches(tempBatches);
            }
            else 
            {
            
             console.log("update batch");   
             console.log(props.selectedBatch["defaultCharacteristics"]);
                batchData = await updateBatch(instance, props.selectedBatch["id"], props.selectedBatchID, props.batchDescription, tmpTableOrders, props.selectedBatch["defaultCharacteristics"], checkedItemsObj, tempSelectedOrders);
                console.log("batchData: ", batchData);
                batchData["executeArguments"] = checkedItemsObj;
                batchData["executeOrders"] = tempSelectedOrders;
                batchData["material"] = tmpKoneMaterial;
                batchData["materialRevision"] = tmpKoneMaterialRev;
                batchData["orders"] = tmpTableOrders;
                let tmpArr = [batchData];
                props.setBatches(props.batches.map(obj => tmpArr.find(o => o.id === obj.id) || obj));
            }    
                 
            // console.log("props.batches2: ", props.batches);             
            // console.log("batchData: ", batchData);   
            batchID = batchData["id"];          
            props.setSelectedBatch(batchData);
            //console.log(selectedBatch);
        };
        data().catch((error)=>{message += error.message+"\n"}).then(() => {
            if (executeAfter == true) {
                if (caseSelect.length == 0)
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"No orders selected"}</p></div>,300000);
                    setLock(false);
                    return;
                }
                let executeData = null;

                const execute = async () => {
                    // console.log("CHECKED: ", checkedItemsObj); 
                    executeData = await executeBatch(instance, batchID, tempSelectedOrders, checkedItemsObj, batchData["orders"], accounts[0].username);
                };
                execute().catch(()=>{message += console.error+"\n"}).then(() => {
                    //console.log("EXECUTEDATA: ", executeData);
                    //dispatch(showSnackbar("Batch saved", "success"));
                    if (message != "")
                    {
                        openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
                        setLock(false);
                    } 
                    else 
                    {
                        openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch execute started successfully."}</p></div>,300000);
                        batchData["status"] = "Processing";
                        batchData["lastRun"] = executeData["lastRun"];
                        batchData["lastRunBy"] = executeData["lastRunBy"];
                        batchData["executeArguments"] = executeData["executeArguments"];
                        batchData["executeOrders"] = executeData["executeOrders"];
                        batchData["orders"].forEach(element => {
                            tempSelectedOrders.forEach(element2 => {
                                if (element["name"].endsWith(element2))
                                {
                                    element["status"] = "Processing";
                                }
                            });
                        });
                        let tmpArr = [batchData];
                        if (props.batches.find(o => o.id == batchData.id))
                        {
                            props.setBatches(props.batches.map(obj => tmpArr.find(o => o.id === obj.id) || obj));
                        }
                        else
                        {
                            let tempBatches = [];
                            if (props.batches != null)
                            {
                                tempBatches = [...props.batches];
                            }
                            tempBatches.push(batchData);
                            props.setBatches(tempBatches);
                        }
                        props.setNewBatch(false);
                        setLock(false);
                        if (props.mode == "batchtable")
                        {
                            //location.reload();
                            handleSetTestOptionsOpen()
                        }
                        else
                        {
                            history.push("/batches");
                        }
                    }
                });
            } else {
                if (message != "")
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
                    setLock(false);
                } 
                else 
                {
                    openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch saved successfully."}</p></div>,300000);
                    setLock(false);
                    props.setNewBatch(false);
                }
            }
            });
        
        setSaveCase(!saveCase);
    }

    const handleExecuteSettingClick = (e, name, value) => {
        e.stopPropagation();
        executeSettings.set(name, value);
        if(GetValue("downloadMFG") === true && GetValue("sendToCAD") === true)
        {
            executeSettings.set("sendToCAD", true);
            executeSettings.set("downloadMFG", true);
        } else if (GetValue("downloadMFG") === false && GetValue("sendToCAD") === true) {
            executeSettings.set("sendToCAD", true);
            executeSettings.set("downloadMFG", false);
        } else if (GetValue("sendToCAD") === false) {
            executeSettings.set("sendToCAD", false);
            executeSettings.set("downloadMFG", false);
        }
        if(name == "downloadMFG" && value == true && GetValue("sendToCAD") === false)
        {
            alert("INFO : Please opt for Configure in CAD option, as its mandatory for MFG package download!");
        }

        if(name == "doMaterialSubstitution" && value == true)
        {
            executeSettings.set("doMaterialSubstitution", true);
            executeSettings.set("doMaterialSubstitutionValue", doMaterialSubstitutionValue);
        }
        
        setExecuteSettings(executeSettings);

        setReRender3(!reRender3);
    };

    const InitializeValues = () => {
        console.log("executeSettings: ", executeSettings);
        console.log("firstInitialize: ", firstInitialize);
        let _setExecuteSettings = false;
        if (firstInitialize == true)
        {
            _setExecuteSettings = true;
            setFirstInitialize(false);
        }
        setOrderTitles(initialTitles);

        if (_setExecuteSettings == true)
        {
            executeSettings.set('deepScan', false);
            executeSettings.set('confPDM', true);
            executeSettings.set('useDefaultCadOptions', true);
            executeSettings.set('sendToCAD', false);
            executeSettings.set('usePdmUpload', false);
            executeSettings.set('downloadMFG', false);
            executeSettings.set('saveTrace', false);
            executeSettings.set('structureConversion', false);
            executeSettings.set('selectRawMaterials', false);
            executeSettings.set('cws_error_recording', false);
            executeSettings.set('cws_skipmode', false);
            executeSettings.set('doMaterialSubstitution', false);
            executeSettings.set('doMaterialSubstitutionValue', " ");
        }

        if (config.environment == "prod")
        {
            setIsMSDisabled(true);
        }
        
        console.log("selectedBatch:", props.selectedBatch); 
        console.log("orderTitles1: ", orderTitles);
        console.log("props.copyBatch: ", props.copyBatch);
        if (GetEmpty(props.selectedBatch) == false)        
        {
            if (GetEmpty(props.selectedBatch["executeArguments"]) == false)
            {
                if (_setExecuteSettings == true || props.mode == "batchtable")
                {
                    for (const [key, value] of Object.entries(props.selectedBatch["executeArguments"])) {        
                        if (key == "levelOfScan")
                        {
                        if (value == "deep_check")
                            {
                                executeSettings.set("deepScan", true);
                            }
                            else
                            {
                                executeSettings.set("deepScan", false);
                            }
                        }
                        else if(key == "cws_testing_skip_objects")
                        {
                            let splitSkipObjects = value.split(",");
                            let tmpSkipModeValues = [];
                            splitSkipObjects.forEach(element => {
                                let elementSplit = element.split(":");
                                let tmpVal = "";
                                let tmpType = "";
                                if (elementSplit.length > 1)
                                {
                                    tmpType = elementSplit[0];
                                    tmpVal = elementSplit[1];
                                }
                                else
                                {
                                    tmpVal = element.trim();
                                }
                                const foundVal = skipModeValues.find((val) => val.type == elementSplit[0] && val.value == elementSplit[1].trim())
                                console.log("FOUNDVAL: ", foundVal);
                                if (foundVal == undefined)
                                {
                                    let newType = "KONE material";
                                    if (elementSplit[0].toLowerCase() == "pricekey")
                                    {
                                        newType = "Price key";
                                    }
                                    else if (elementSplit[0].toLowerCase() == "license")
                                    {
                                        newType = "License";
                                    }
                                    tmpSkipModeValues.push({"type": newType, "value": tmpVal})
                                }
                            });
                            console.log("tmpSkipModeValues: ", tmpSkipModeValues);
                            setSkipModeValues(tmpSkipModeValues);
                            props.setSkipModeValues(tmpSkipModeValues);
                        }
                        else
                        {
                            if (typeof value === 'string' || value instanceof String)
                            {
                                executeSettings.set(key, (value === 'true'));
                            }
                            else
                            {
                                executeSettings.set(key, (value === true));
                            }
                        }
                    }
                    executeSettings.delete("environment");                
                    executeSettings.delete("relationships");   
                    console.log("executeSettings edit values:", executeSettings);  
                }
            }        
            
            if (GetEmpty(props.selectedBatch["executeOrders"]) == false)
            {
                if (GetEmpty(props.selectedBatch["orders"]) == false)
                {   
                    if (props.mode == "batchtable")
                    {
                        let initialOrders = [];
                        let regex = new RegExp("(?:" + props.selectedBatch["executeOrders"].join("|") + ")$");
                        props.selectedBatch["orders"].map((obj) => {     
                            if(obj["name"].match(regex))
                            {
                                let splitObjNameArr = obj["name"].split("_");
                                let splitObjName = splitObjNameArr[splitObjNameArr.length-1];
                                splitObjName = splitObjName.replace(/^0+/, '');
                                initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                            }
                        })
                        if (!initialOrders.some(obj => obj["key"] === "All"))
                        {
                            let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "" }
                            if (initialOrders.length == props.selectedBatch["orders"].length)
                            {
                                initialOrders.push(newObj);
                                setOrderAllSelected(true);
                            }
                        }
                        setCaseSelect([...initialOrders]);
                        handleCaseSelectChange(null, initialOrders, null, null);
                        setReRender3(!reRender3);
                        setResetOrders(!resetOrders);

                    }
                    else if (props.copyBatch == false)
                    {
                        let initialOrders = [];
                        let regex = new RegExp("(?:" + props.selectedBatch["executeOrders"].join("|") + ")$");
                        props.selectedBatch["orders"].map((obj) => {     
                            if(obj["name"].match(regex))
                            {
                                let splitObjNameArr = obj["name"].split("_");
                                let splitObjName = splitObjNameArr[splitObjNameArr.length-1];
                                splitObjName = splitObjName.replace(/^0+/, '');
                                initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                            }
                        })
                        if (!initialOrders.some(obj => obj["key"] === "All"))
                        {
                            let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "" }
                            if (initialOrders.length == props.selectedBatch["orders"].length)
                            {
                                initialOrders.push(newObj);
                                setOrderAllSelected(true);
                            }
                        }
                        setCaseSelect([...initialOrders]);
                        handleCaseSelectChange(null, initialOrders, null, null);
                        setReRender3(!reRender3);
                        setResetOrders(!resetOrders);

                        if (GetEmpty(executeSettings) == false)
                        {
                            if (executeSettings.has("levelOfScan"))
                            {
                                if (executeSettings.get("levelOfScan") == "deep_check")
                                {
                                    executeSettings.set("deepScan", true);
                                }
                            }       
                        }
                    }
                } 
            }
        }
       
        console.log("executeSettings: ", executeSettings);
        props.setExecuteSettings(executeSettings);
        setExecuteSettings(executeSettings);

    };

    useEffect(() => {
        console.log("Hello: ", props.open);
        if (initialOpen == true)
        {
            setInitialOpen(false);
            return;
        }
        InitializeValues();
        handleSetTestOptionsOpen();
    }, [props.open]);

    useEffect(() => {
        if (props.mode == "batchtable")
        {
            console.log(props.caseSelect);
            console.log("Ordertitles: ", orderTitles);
            setReRender3(!reRender3);
            props.setReRender(!props.reRender)
        }
        
    }, [props.caseSelect]);

    const modeCheckBox = (label, attributeName) => {
        return (
                <BatchOptionCheckbox label={label} onChange={(e)=>handleExecuteSettingClick(e, attributeName, e.target.checked ? true : false)} checked={GetValue(attributeName) == true} className={globalClasses.checkbox}/>
        );
    }

    const addMaterialSubstitution = () => {
        
        return (
            <TextField
                style={{paddingLeft: "4px", minWidth: "220px", maxWidth: "220px", textOverflow: "clip"}}
                id="outlined-basic" value={doMaterialSubstitutionValue} 
                    variant="outlined" 
                    size="small" 
                onChange={(e) => {
                    setDoMaterialSubstitutionValue(e.target.value.toString());
                }}
                inputProps={{ maxLength: 32 }}
                className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} >
            </TextField>
        );
    }

    const skipModeList = () => {
        
        return (
            <Paper className={globalClasses.addBorder} style={{maxHeight: 200, maxWidth: "400px", overflowY: 'auto'}}>
            <List style={{padding: "0"}}>
                <ListItem style={{padding: "0"}}>
                <Select
                value={skipModeCurrentType}
                onChange={(e) => {
                    console.log("E1: ", e);
                    setSkipModeCurrentType(e.target.value.toString());
                }}
                style={{minWidth: "135px", paddingLeft: "4px"}}
                className={globalClasses.addBorder}
                MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                    <MenuItem value={"KONE material"}>KONE material</MenuItem>
                    <MenuItem value={"Price key"}>Price key</MenuItem>
                    <MenuItem value={"License"}>License</MenuItem>
                </Select>
                <TextField
                style={{paddingLeft: "4px", minWidth: "220px", maxWidth: "220px", textOverflow: "clip"}}
                className={globalClasses.addBorder}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        let tmpVal = skipModeCurrentValue.trim();
                        if (tmpVal != "")
                        {
                            if (tmpVal.length > 100)
                            {
                                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Maximum length of entry is 100 characters"}</p></div>,300000)
                            }
                            else
                            {
                                const foundVal = skipModeValues.find((val) => val.type == skipModeCurrentType && val.value == tmpVal)
                                console.log("FOUNDVAL: ", foundVal);
                                if (foundVal == undefined)
                                {
                                    if (skipModeCurrentType == "KONE material")
                                    {
                                        let tmpSplit = tmpVal.split("/");
                                        if (tmpSplit.length == 1)
                                        {
                                            tmpVal = tmpVal.substring(0, 98);
                                            tmpVal = tmpVal + "/*";
                                        }
                                    }
                                    let tmpSkipModeValues = [...skipModeValues]
                                    tmpSkipModeValues.push({"type": skipModeCurrentType, "value": tmpVal})
                                    console.log("tmpSkipModeValues: ", tmpSkipModeValues);
                                    setSkipModeValues(tmpSkipModeValues);
                                    props.setSkipModeValues(tmpSkipModeValues);
                            }
                            }
                            
                        }
                      ev.preventDefault();
                    }
                  }}
                onChange={(e) => {
                    setSkipModeCurrentValue(e.target.value.toString());
                }}
                ></TextField>

                    <IconButton edge="end" aria-label="add" style={{padding: 0, flexDirection: "column", marginLeft: "auto", marginRight: "8px"}}
                    onClick={()=> {
                        let tmpVal = skipModeCurrentValue.trim();
                        if (tmpVal != "")
                        {
                            if (tmpVal.length > 100)
                            {
                                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Maximum length of entry is 100 characters"}</p></div>,300000)
                            }
                            else
                            {
                                const foundVal = skipModeValues.find((val) => val.type == skipModeCurrentType && val.value == tmpVal)
                                console.log("FOUNDVAL: ", foundVal);
                                if (foundVal == undefined)
                                {
                                    if (skipModeCurrentType == "KONE material")
                                    {
                                        let tmpSplit = tmpVal.split("/");
                                        if (tmpSplit.length == 1)
                                        {
                                            tmpVal = tmpVal + "/*";
                                        }
                                    }
                                    let tmpSkipModeValues = [...skipModeValues]
                                    tmpSkipModeValues.push({"type": skipModeCurrentType, "value": tmpVal})
                                    console.log("tmpSkipModeValues: ", tmpSkipModeValues);
                                    setSkipModeValues(tmpSkipModeValues);
                                    props.setSkipModeValues(tmpSkipModeValues);
                                }
                            }
                            
                        }
                    }}
                    >
                      <Add />
                    </IconButton>
                </ListItem>
                {skipModeValues.map((val, index) => {
                    return(
                        <ListItem key={val["type"]+index.toString()} style={{padding: "0"}}>
                <Select
                    value={val["type"]}
                    onChange={(e) => {
                        val["type"] = e.target.value.toString();
                        setReRender3(!reRender3);
                    }}
                    style={{minWidth: "135px", paddingLeft: "4px"}}
                    className={globalClasses.addBorder}
                    MenuProps={{
                        anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                        },
                        transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    >
                    <MenuItem value={"KONE material"}>KONE material</MenuItem>
                    <MenuItem value={"Price key"}>Price key</MenuItem>
                    <MenuItem value={"License"}>License</MenuItem>
                </Select>
                <TextField
                    style={{paddingLeft: "4px", minWidth: "220px", maxWidth: "220px", textOverflow: "clip"}}
                    className={globalClasses.addBorder}
                    onChange={(e) => {
                        val["value"] = e.target.value.toString();
                        setReRender3(!reRender3);
                    }}
                    value={val["value"]}
                    ></TextField>


                    <IconButton edge="end" aria-label="add" style={{padding: 0, flexDirection: "column", marginLeft: "auto", marginRight: "8px"}}
                    className={globalClasses.redButtonAlt}
                    onClick={()=> {
                        const foundVal = skipModeValues.find((tmpVal) => val.type == tmpVal.type && val.value == tmpVal.value)
                        console.log("FOUNDVAL: ", foundVal);
                        if (foundVal != undefined)
                        {
                            let tmpSkipModeValues = [...skipModeValues]
                            const index = tmpSkipModeValues.findIndex(item => item["type"] == foundVal["type"] && item["value"] == foundVal["value"]);
                            console.log("index: ", index);
                            if (index !== -1)
                            {
                                tmpSkipModeValues.splice(index, 1);
                                setSkipModeValues(tmpSkipModeValues);
                                props.setSkipModeValues(tmpSkipModeValues);
                            }   
                        }
                    }}
                    >
                      <Remove />
                    </IconButton>
                </ListItem>
                    );
                })}
            </List>
            </Paper>
        );
    }
    return (
    <div>
    <Dialog open={testOptionsOpen} onClose={(e, r) => {
            console.log("EVENT: ", e);
            console.log("REASON: ", r);
            handleSetTestOptionsOpen(e);
        }} fullWidth={true} maxWidth="md" classes={{paper: classes.dialogPaper, scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody}}>
    {testOptionsOpen ? 
        <DialogContent style={{padding: "12px 18px"}}>
                                
                <Table className={classes.executeOptionsTable}>
                    <TableHead>
                {/* TR1 */}
                    <TableRow>
                        <TableCell align='left' className={classes.executeDataCell} style={{minWidth: "218px", paddingRight: "8px"}}>
                            <Typography style={{fontWeight: "bold"}}>{"Configuration options PDM"}</Typography>
                        </TableCell>
                        {/* <TableCell align='left' className={classes.executeDataCell}>
                            <Typography style={{fontWeight: "bold"}}>{"Actions"}</Typography>
                        </TableCell> */}
                        <TableCell align='left' className={classes.executeDataCell} style={{minWidth: "218px", paddingRight: "8px"}}>
                            <Typography style={{fontWeight: "bold"}}>{"Configuration options CAD"}</Typography>
                        </TableCell>
                        <TableCell align='left' className={classes.executeDataCell}>
                            <Typography style={{fontWeight: "bold"}}>{"Level of Scan"}</Typography>
                        </TableCell>
                        <TableCell align='left' className={clsx(classes.executeDataCell, classes.dnone)}>
                            <Typography style={{fontWeight: "bold"}}>{"Execute orders"}</Typography>
                        </TableCell>
                        <TableCell className={classes.executeDataCell} align="right">
                            <Box display={"flex"} justifyContent={"end"}>
                                <Tooltip title={"Cancel"}>
                                    <IconButton disabled={lock} className={clsx(classes.topButton, globalClasses.blueButton)} onClick={(e) =>{
                                        console.log("EVENT2: ", e);
                                        handleSetTestOptionsOpen(e);
                                        }}>
                                        <Cancel />
                                    </IconButton>
                                    {/* <Button variant="contained" style={{height: "40px"}} className={clsx(classes.topButton, classes.blueButton)}>
                                        Cancel
                                    </Button> */}
                                </Tooltip>
                                <Tooltip title={"Confirm"}>
                                    <span>
                                    <IconButton disabled={caseSelect.length <= 0 || lock} className={clsx(classes.topButton, globalClasses.blueButton, caseSelect.length <= 0 && globalClasses.disabledButton)} onClick={()=>{
                                        handleSaveCase(true);
                                        // if (props.newBatch == true || props.copyBatch == true)
                                        // {
                                        //     handleSaveCase(true);
                                        // }
                                        // else 
                                        // {
                                        //     handleExecuteBatch();
                                        // }
                                        
                                        //handleSetTestOptionsOpen();
                                        }}>
                                        <PlayArrow />
                                    </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        </TableCell>
                    </TableRow>
                    </TableHead>

                    <TableBody>
                        {/* TR2 */}
                    <TableRow className={classes.executeTRow}>
                        <TableCell align='left' className={classes.executeDataCell}>
                            <BatchOptionCheckbox label="Configure in PDM" onChange={(e)=>handleExecuteSettingClick(e, "confPDM", e.target.checked ? true : false)} checked={GetValue("confPDM") == true} className={globalClasses.checkbox}/>
                        </TableCell>
                        {/* <TableCell align='left' className={classes.executeDataCell}>
                            <BatchOptionCheckbox label="Data validation" onChange={(e)=>handleExecuteSettingClick(e, "dataValidation", e.target.checked ? true : false)} checked={GetValue("dataValidation") == true} className={globalClasses.checkbox}/>
                        </TableCell> */}
                        
                        <TableCell align='left' className={classes.executeDataCell}>
                        <BatchOptionCheckbox label="Configure in CAD" onChange={(e)=>handleExecuteSettingClick(e, "sendToCAD", e.target.checked ? true : false)} checked={GetValue("sendToCAD") == true} className={globalClasses.checkbox}/>
                        </TableCell>
                        

                        <TableCell align='left' className={classes.executeDataCell}>
                        <FormControlLabel
                            value="None"
                            control={<CustomRadio onChange={(e)=>handleExecuteSettingClick(e, "deepScan", false)} checked={GetValue("deepScan") == false}/>}
                            label="None"
                            labelPlacement="end"
                            />
                        </TableCell>

                        <TableCell align='left' className={clsx(classes.executeDataCell, classes.dnone)}>
                            {/*BOX*/}
                            <Box display={"flex"} justifyContent={"start"} className={classes.root}>
                                {/* <InputLabel shrink={true}>Orders</InputLabel> */}
                                
                                <Autocomplete 
                                key={resetOrders}
                                multiple
                                freeSolo
                                open={orderListOpen}
                                onFocus={() => setOrderListOpen(true)}
                                onBlur={() => setOrderListOpen(false)}
                                value={caseSelect || []}
                                //defaultValue={caseSelect}
                                getOptionSelected={(option, value) => {
                                    if (caseSelect.indexOf("All") > -1)
                                    {
                                        return true;
                                    }
                                    // console.log("option: ", option);
                                    // console.log("value: ", value);
                                    return option.name === value.name || (value.name === "0001-010" && option.name === "1-010");
                                }}
                                // getOptionSelected={(option, value) => {
                                //     let isSelected = false;
                                //     caseSelect.map((obj) =>{
                                //         if (obj["name"] == value["name"])
                                //         {
                                //             isSelected = true;
                                //         }
                                //     });
                                //     return isSelected;
                                // }}
                                inputValue={orderInputValue}
                                options={
                                    props.orderTitles.filter((a) => (!("defaultTitle" in a) || a["defaultTitle"] == "false"))
                                //     .map((t) => {
                                //     console.log(t);
                                //     let tempArr = [];
                                //     if (!("defaultTitle" in t)) {
                                //         //tempArr.push(t["name"]);
                                //         return (t["name"]).toString();
                                //     }
                                //     //return tempArr;
                                // })
                                }
                                filterOptions={(options)=>{
                                    let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "" }
                                    let returnArr = [];
                                    if (options.some(e => e["key"] === "All"))
                                    {
                                        returnArr = [...options];
                                    }
                                    else
                                    {
                                        returnArr = [newObj, ...options];
                                    }
                                    return returnArr.filter((str) => {
                                        if (str["name"].toLowerCase().includes(orderInputValue.toLowerCase()) || (str["description"] != null && str["description"].toLowerCase().includes(orderInputValue.toLowerCase())))
                                        {
                                            let copyStr = str;
                                            // caseSelect.map((obj) => {
                                            //     if (obj["name"] == copyStr["name"])
                                            //     {
                                            //         if ("selected" in obj)
                                            //         {
                                            //             copyStr["selected"] = obj["selected"];
                                            //             str["selected"] = obj["selected"];
                                            //         }
                                            //     }
                                            // });
                                            copyStr["name"] = copyStr["name"].replace(/^0+/, '');
                                            return copyStr;
                                        }
                                    })
                                }}
                                disableCloseOnSelect
                                disableClearable
                                style={{ width: "160px"  }}
                                getOptionLabel={(option) => option["name"]}
                                ListboxProps={{ style: { maxHeight: '70vh' } }}
                                PopperComponent={PopperMy}
                                renderTags={value => {
                                    let resultValues = value.map(function(elem) {
                                        return elem["name"].replace(/^0+/, '');
                                    });
                                    let resultValuesString = ""
                                    if (value.length > 2)
                                    {
                                        resultValues = resultValues.slice(0, 2);
                                        resultValuesString = resultValues.join(", ") + "...";
                                    }
                                    else
                                    {
                                        resultValuesString = resultValues.join(", ");
                                    }
                                    return <Tooltip title={resultValuesString}><Typography style={{ paddingLeft: "4px" }}
                                    noWrap={true} key={"rendertags"}>{resultValuesString}</Typography></Tooltip>;
                                }
                            }
                                renderOption={(option, {selected}) => 
                                {
                                    //console.log("OPTION: ", option);
                                    let desc = option["description"] == null ? " " : option["description"];
                                    // let initiallySelected = false;
                                    // defaultSelectOrders.map((obj) => {
                                    //     if (obj["name"] == option["name"])
                                    //     {
                                    //         initiallySelected = true;
                                    //     }
                                    // });
                                return(
                                            <React.Fragment>
                                                <Tooltip title={desc}>
                                                <ListItemText
                                                    style={{ margin: "0", overflow: "ellipsis"}}
                                                    disableTypography
                                                    primary={<Typography className={globalClasses.smallFont}>{option["name"]}</Typography>}
                                                    secondary={'description' in option ?
                                                    <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{desc.length > 46 ? desc.slice(0, 43) + "..." : desc}</Typography> 
                                                    :
                                                    null
                                                }
                                                />
                                                </Tooltip>
                                            </React.Fragment>

                                        )}
                                    }
                                    renderInput={({inputProps, ...params}) => (
                                        <TextField {...params} style={{ flexWrap: "nowrap", textOverflow: "ellipsis", overflowX: "hidden"}} error={caseSelect.length <= 0} helperText={caseSelect.length <= 0 ? "No orders selected" : ""} FormHelperTextProps={{
                                            className: globalClasses.helperText
                                          }} label="Orders" InputLabelProps={{ shrink: true }} 
                                          inputProps={{ ...inputProps, readOnly: true }}
                                          />
                                )}
                                />
                            </Box>
                        </TableCell>
                        <TableCell />
                        
                        {/* <TableCell /> */}
                    </TableRow>

                    {/* TR3 */}

                    <TableRow className={classes.executeTRow}>
                    { GetValue("confPDM") === false ?
                    <TableCell align='left' className={classes.executeDataCell}>
                     <BatchOptionCheckbox label="Structure conversion" onChange={(e)=>handleExecuteSettingClick(e, "structureConversion", e.target.checked ? true : false)} checked={GetValue("structureConversion") == true} className={globalClasses.checkbox}/>
                    </TableCell>
                     : 
                        <TableCell align='left' className={classes.executeDataCell}>
                         <Box display={"inline-block"} position={"relative"}>
                            <InputLabel shrink={true} style={{position: "absolute", top: "-14px", left: "0px", display: "inline", fontSize: "16px", lineHeight: "16px"}}>Relationships</InputLabel>
                            <Select
                            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre", maxWidth: "170px", minWidth: "170px" }} 
                            value={customRelationships} 
                            onChange={handleRelationshipSelectChange}
                            label={"Relationships"}
                            input={<Input />}
                            MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                  },
                                getContentAnchorEl: null,
                                autoFocus: false,
                                PaperProps: {
                                    style: {
                                        maxHeight: "65vh"
                                    }
                                }
                              }}
                            renderValue={(selected) => selected.join(', ')}>
                                {
                                    Object.keys(pdmRelationships).map((key) => (
                                        <MenuItem key={key} value={key} style={{backgroundColor: "#fff"}}>
                                            <Checkbox style={{color: "#0071B9"}} className={globalClasses.checkbox} checked={customRelationships.indexOf(key) > -1} />
                                            <ListItemText primary={key} />
                                        </MenuItem>
                                    ))
                                }
                            
                            </Select>
                            {/* </FormControl> */}
                            </Box>
                            </TableCell>
                            }
                        
                        <TableCell align='left' className={classes.executeDataCell}>
                        <FormControlLabel
                            value="Default Options File"
                            control={<CustomRadio onChange={(e)=>handleExecuteSettingClick(e, "useDefaultCadOptions", true)} checked={GetValue("useDefaultCadOptions") === true}/>}
                            label="Default Options File"
                            labelPlacement="end"
                            />
                        </TableCell>
                        <TableCell align='left' className={classes.executeDataCell}>
                        <FormControlLabel
                            value="Deep Scan"
                            control={<CustomRadio onChange={(e)=>handleExecuteSettingClick(e, "deepScan", true)} checked={GetValue("deepScan") === true}/>}
                            label="Deep Scan"
                            labelPlacement="end"
                            />
                        </TableCell>
                        <TableCell />
                        <TableCell />
                       
                    </TableRow>
                    {/* TR4 */}

                    <TableRow className={classes.executeTRow}>
                        <TableCell align='left' className={classes.executeDataCell}>
                            <BatchOptionCheckbox label="Material Substitution" disabled={isMSDisabled} onChange={(e)=>handleExecuteSettingClick(e, "doMaterialSubstitution", e.target.checked ? true : false)} checked={GetValue("doMaterialSubstitution") == true} className={globalClasses.checkbox}/>
                        </TableCell>
                        <TableCell align='left' className={classes.executeDataCell}>
                            <FormControlLabel
                            value="Supplier Spesific Options File"
                            control={<CustomRadio onChange={(e)=>handleExecuteSettingClick(e, "useDefaultCadOptions", false)} checked={GetValue("useDefaultCadOptions") === false}/>}
                            label="Supplier Spesific Options File"
                            labelPlacement="end"
                            />
                        </TableCell>
                        <TableCell />
                        <TableCell />

                    </TableRow>

                    {/* TR5 */}

                    <TableRow className={classes.executeTRow}>
                        <TableCell align='left' className={classes.executeDataCell}>
                        { 
                            GetValue("doMaterialSubstitution") === true ? 
                                addMaterialSubstitution()
                            :
                            GetValue("confPDM") === true ?
                            <BatchOptionCheckbox label="Structure conversion" onChange={(e)=>handleExecuteSettingClick(e, "structureConversion", e.target.checked ? true : false)} checked={GetValue("structureConversion") == true} className={globalClasses.checkbox}/>
                            : 
                            <BatchOptionCheckbox label="Select Raw Materials" onChange={(e)=>handleExecuteSettingClick(e, "selectRawMaterials", e.target.checked ? true : false)} checked={GetValue("selectRawMaterials") == true} className={globalClasses.checkbox}/>
                        }
                        </TableCell>
                        {GetValue("useDefaultCadOptions") === false 
                        ?
                        <TableCell align='left' className={classes.executeDataCell} style={{display: "flex" }}>
                            <Button variant="contained" className={clsx(classes.testCasesButton, globalClasses.blueButton, classes.supplierSpecificBtn)} onClick={async() => {
                                // Supplier Specific Options File - DOWNLOAD
                                
                                const optionsFileDL = await OptionsPackageDownload(instance, props.selectedKoneMaterial, props.selectedKoneMaterialRev, accounts[0].username);
                                if (optionsFileDL != null)
                                {
                                if (optionsFileDL["exception"])
                                {
                                    openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{optionsFileDL["exception"]}</p></div>,300000)
                                }
                                }
                            }}>
                            <Tooltip title={"Download"}>   
                                <GetApp  />
                            </Tooltip>
                            
                            </Button>
                            
                            <Button variant="contained" className={clsx(classes.testCasesButton, globalClasses.blueButton, classes.supplierSpecificBtn)} onClick={async() => {
                                // Supplier Specific Options File - UPLOAD
                                let fileStream = null;
                                optionFileInput.current.click();
                            }}>
                                <Tooltip title={"Upload"}>
                                    <Publish />
                                </Tooltip>
                                <input type='file' id='file' ref={optionFileInput} onChange={handleOptionFileUpload} style={{display: 'none'}}
                                accept={".zip, application/zip"}
                                />
                            </Button>
                        </TableCell>
						:
						<TableCell align='left' className={classes.executeDataCell}>
                            <BatchOptionCheckbox label="Upload results to PDM" onChange={(e)=>handleExecuteSettingClick(e, "usePdmUpload", e.target.checked ? true : false)} checked={GetValue("usePdmUpload") == true} className={globalClasses.checkbox}/>
                        </TableCell>
						}
                        <TableCell />
                        <TableCell />

                    </TableRow>

                    {/* TR6 */}
                    <TableRow className={classes.executeTRow}>
                    { 
                        GetValue("doMaterialSubstitution") === true ? 
                            <TableCell align='left' className={classes.executeDataCell}>
                                <BatchOptionCheckbox label="Structure conversion" onChange={(e)=>handleExecuteSettingClick(e, "structureConversion", e.target.checked ? true : false)} checked={GetValue("structureConversion") == true} className={globalClasses.checkbox}/>
                            </TableCell>
                        :
                        <TableCell align='left' className={classes.executeDataCell}>
                            <BatchOptionCheckbox label="Select Raw Materials" onChange={(e)=>handleExecuteSettingClick(e, "selectRawMaterials", e.target.checked ? true : false)} checked={GetValue("selectRawMaterials") == true} className={globalClasses.checkbox}/>
                        </TableCell>
                    }
                        {
                            GetValue("useDefaultCadOptions") === false 
                            ?
                            <TableCell align='left' className={classes.executeDataCell}>
                                <BatchOptionCheckbox label="Upload results to PDM" onChange={(e)=>handleExecuteSettingClick(e, "usePdmUpload", e.target.checked ? true : false)} checked={GetValue("usePdmUpload") == true} className={globalClasses.checkbox}/>
                            </TableCell>
                            :
                            <TableCell align='left' className={classes.executeDataCell}>
                                <BatchOptionCheckbox label="Download MFG package" onChange={(e)=>handleExecuteSettingClick(e, "downloadMFG", e.target.checked ? true : false)} checked={GetValue("downloadMFG") == true} className={globalClasses.checkbox}/>
                            </TableCell>
                        }

                        <TableCell />
                        <TableCell />

                        </TableRow>

                        {/* TR7 */}
                    {
                        <TableRow className={classes.executeTRow}>
                            { 
                                GetValue("doMaterialSubstitution") === true ? 
                                    <TableCell align='left' className={classes.executeDataCell}>
                                        <BatchOptionCheckbox label="Select Raw Materials" onChange={(e)=>handleExecuteSettingClick(e, "selectRawMaterials", e.target.checked ? true : false)} checked={GetValue("selectRawMaterials") == true} className={globalClasses.checkbox}/>
                                    </TableCell>
                                :
                                GetValue("confPDM") === true ?
                                    <TableCell align='left' className={classes.executeDataCell}>
                                    {modeCheckBox("Error mode", "cws_error_recording")}
                                    </TableCell>
                                : 
                                <TableCell align='left' className={classes.executeDataCell}>
                                    {modeCheckBox("Skip mode", "cws_skipmode")}
                                </TableCell>
                            }
                    {
                        GetValue("useDefaultCadOptions") === false 
                        ?
                        <TableCell align='left' className={classes.executeDataCell}>
                            <BatchOptionCheckbox label="Download MFG package" onChange={(e)=>handleExecuteSettingClick(e, "downloadMFG", e.target.checked ? true : false)} checked={GetValue("downloadMFG") == true} className={globalClasses.checkbox}/>
                        </TableCell>
                        :
                        <TableCell/>
                    }
                    </TableRow>
                    }

                    {/* TR8 */}

                    <TableRow className={classes.executeTRow}>
                        { 
                            GetValue("doMaterialSubstitution") === true ? 
                                GetValue("confPDM") === true ?
                                <TableCell align='left' className={classes.executeDataCell}>
                                {modeCheckBox("Error mode", "cws_error_recording")}
                                </TableCell>
                                : 
                                <TableCell align='left' className={classes.executeDataCell}>
                                    {modeCheckBox("Skip mode", "cws_skipmode")}
                                </TableCell>
                            :
                            <TableCell align='left' className={classes.executeDataCell}>
                                {modeCheckBox("Skip mode", "cws_skipmode")}
                            </TableCell>
                        }
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow>

                    {/* TR9 */}

                    {/* TR8 */}

                    <TableRow className={classes.executeTRow}>
                        { 
                            GetValue("doMaterialSubstitution") === true ? 
                                <TableCell align='left' className={classes.executeDataCell}>
                                {modeCheckBox("Skip mode", "cws_skipmode")}
                                </TableCell>
                            :
                            <TableCell />
                        }
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow>

                    {/* <TableRow>
                    
                    { GetValue("confPDM") === true ?
                        GetValue("cws_skipmode") === true ? 
                        skipModeList()
                        :
                        <TableCell />
                    : 
                        <TableCell />
                    }
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow> */}

                    </TableBody>
                </Table>
                {
                    GetValue("cws_skipmode") === true ? 
                    skipModeList()
                    :
                    <></>
                }
                
            </DialogContent>
        : null}
        
        {/* <Grid container className={clsx(classes.shown, !testOptionsOpen && classes.hidden)} justifyContent="center" alignItems='center'>
            {testOptionsOpen ? BatchOptionCheckBoxes.map(item => (
                <Grid key={item.key} item xs={xsVal} sm={smVal} md={mdVal} className={classes.testOptionsItem}>
                    <label>
                        <BatchOptionCheckbox className={classes.testOptionCheckBox} label={item.label} name={item.name} checked={checkedItems.get(item.name)} onChange={(e) => handleChange(e)} />
                    </label>
                </Grid>
            )) : null}
            <Grid item xs={xsVal} sm={smVal} md={mdVal} className={classes.testOptionsItem}>
                {testOptionsOpen ?
                    <Button variant="contained" className={classes.blueButton}>
                        EDIT CAD OUTPUT OPTIONS
                    </Button>
                    : null}
            </Grid>
        </Grid> */}
    </Dialog>
    </div>
);
}