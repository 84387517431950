import { Button, IconButton, makeStyles, Table } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import React from "react";
import { DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .cwsTheme.statusJob": {
            backgroundColor: 'lightcoral' //"#ff0033",
        },
        "& .cwsTheme.statusIn": {
            backgroundColor: '#acc9e6' //"#ff0033",
        },
        "& .cwsTheme.statusProcessing": {
            backgroundColor: '#FFFF99' //"#ff0033",
        },
        "& .cwsTheme.statusCancelled": {
            backgroundColor: 'lightcoral' //"#ff0033",
        },
        "& .cwsTheme.statusCompleted": {
            backgroundColor: 'lightgreen' // "#4BB543",
        },
        "& .cwsTheme.statusFailed": {
            backgroundColor: 'lightcoral' //"#ff0033",
        },
        "& .cwsTheme.statusSuccess": {
            backgroundColor: 'lightgreen' // "#4BB543",
        },
        "& .cwsTheme.duration": {
            textAlign: 'right',
        },
        "& .cwsTheme.placeHolder": {
            backgroundColor: '#fafafa',
        },
        "& .cwsTheme.downloadHeader": {
            cursor: "default"
        },
        "& .cwsTheme.deepScanCell" : {
            justifyContent: "center",
            alignItems: "center",
        },
        "& .MuiDataGrid-window": {
            overflowX: "hidden"
        }
    },
    gridDiv: {
        height: "70.0vh",
        width: '100%',
        margin: "auto",
    },
    dataGrid: {
        "&:last-child": {
            overflowX: "hidden"
        }
    },
    statusCompleted: {
        backgroundColor: "#4BB543",
    },
    statusCancelled: {
        backgroundColor: "#ff0033",
    },
}));

export const TestResults = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        // name|status|Trace|Result download|CAD Duration|CAD Check|CAD Loops
        { field: 'case', headerName: 'Case', width: 70 },
        {
            field: 'status', headerName: 'Status', width: 104,
            cellClassName: function customCellRenderer(params) {
                let splitStatus = params.row.status.split();
                return (
                    clsx('cwsTheme', 'status' + splitStatus)
                );
            },
            sortComparator: function customSortStatus(v1, v2, param1, param2) {
                let char1 = param1.row.status[0];
                let char2 = param2.row.status[0];
                let value1 = 0;
                let value2 = 0;
                if(char1 == "C") {
                    value1 = 4;
                }
                else if(char1 == "J") {
                    value1 = 3;
                }
                else if(char1 == "I") {
                    value1 = 2;
                }
                else if(char1 == "P") {
                    value1 = 1;
                }
                if(char2 == "C") {
                    value2 = 4;
                }
                else if(char2 == "J") {
                    value2 = 3;
                }
                else if(char2 == "I") {
                    value2 = 2;
                }
                else if(char2 == "P") {
                    value2 = 1;
                }
                return (value1-value2);
            },
        },
        { field: 'trace', headerName: 'Trace', width: 70 },
        {
            field: 'resultDownload',
            headerName: 'Download',
            width: 100,
            sortable: false,
            headerClassName: function customHeaderRenderer() {
                return (
                    clsx('cwsTheme', 'downloadHeader')
                );
            },
            renderCell: function customCellRenderer() {
                return (
                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <GetApp />
                    </IconButton>
                )
            },
            // (params) => (
            //     <Button
            //   variant="contained"
            //   color="primary"
            //   size="small"
            //   style={{ marginLeft: 16 }}
            // >
            //   Open
            // </Button>


            // <p>{"hello"}</p>
            // <IconButton >
            //     <AssignmentReturn />
            // </IconButton>
            //),
        },
        {
            field: '', headerName: '', flex: 1,
            headerClassName: clsx('cwsTheme', 'placeHolder')
        },
        {
            field: 'cadCheck', headerName: 'Deep Scan', width: 104,
            cellClassName: clsx('cwsTheme', 'deepScanCell'),
            renderCell: function customCellRenderer(params) {
                let result = (<React.Fragment></React.Fragment>);
                if(params.row.status != "Completed") {
                    result = (<React.Fragment></React.Fragment>);
                }
                else if (params.row.cadCheck == "Success") 
                {
                    result = <SuccessIcon aria-label="Success" htmlColor="#4BB543" />;
                }
                else if(params.row.cadCheck == "Failed")
                {
                    result = <ErrorIcon aria-label="Failed" htmlColor="#ff0033" />;
                }
                return (
                    result
                );
            },
            sortComparator: function customSortStatus(v1, v2, param1, param2) {
                let char1 = param1.row.cadCheck[0];
                let char2 = param2.row.cadCheck[0];
                let value1 = -9999;
                let value2 = -9999;

                if(char1 != null || char1 != "")
                {
                    value1 = char1.charCodeAt(0);
                }
                if(char2 != null || char2 != "")
                {
                    value2 = char2.charCodeAt(0);
                }
                return (value1-value2);
            },
        },
        {
            field: 'cadDuration', headerName: 'CAD Duration', width: 124,
            cellClassName: function customCellRenderer(params) {
                return (
                    clsx('cwsTheme', 'duration')
                );
            },
            sortComparator: function customSortDuration(v1, v2, param1, param2) {
                let duration1 = param1.row.cadDuration.split(" ").reverse();
                let duration2 = param2.row.cadDuration.split(" ").reverse();
                let returnValue1 = 0;
                let returnValue2 = 0;
                let numbers = 0;

                if(duration1.length == 3) {
                    numbers = Number(duration1[2].replace(/\D/g, ""));
                    returnValue1 += 3600 * numbers;
                    numbers = Number(duration1[1].replace(/\D/g, ""));
                    returnValue1 += 60 * numbers;
                    numbers = Number(duration1[0].replace(/\D/g, ""));
                    returnValue1 += numbers;
                }
                else if(duration1.length == 2) {
                    numbers = Number(duration1[1].replace(/\D/g, ""));
                    returnValue1 += 60 * numbers;
                    numbers = Number(duration1[0].replace(/\D/g, ""));
                    returnValue1 += numbers;
                }
                else {
                    numbers = Number(duration1[0].replace(/\D/g, ""));
                    returnValue1 += numbers;
                }

                if(duration2.length == 3) {
                    numbers = Number(duration2[2].replace(/\D/g, ""));
                    returnValue2 += 3600 * numbers;
                    numbers = Number(duration2[1].replace(/\D/g, ""));
                    returnValue2 += 60 * numbers;
                    numbers = Number(duration2[0].replace(/\D/g, ""));
                    returnValue2 += numbers;
                }
                else if(duration2.length == 2) {
                    numbers = Number(duration2[1].replace(/\D/g, ""));
                    returnValue2 += 60 * numbers;
                    numbers = Number(duration2[0].replace(/\D/g, ""));
                    returnValue2 += numbers;
                }
                else {
                    numbers = Number(duration2[0].replace(/\D/g, ""));
                    returnValue2 += numbers;
                }
                return (returnValue1-returnValue2);
            },
        },

        { field: 'cadLoops', headerName: 'CAD Loops', width: 110, type: 'number' },
    ];

    const rows = [
        // name|status|Trace|Result download|CAD Duration|CAD Check|CAD Loops
        { id: 1, case: '001', status: 'Completed', trace: 'None', cadCheck: 'Success', cadDuration: "35min 02s", cadLoops: 10 },
        { id: 2, case: '002', status: 'Completed', trace: 'None', cadCheck: 'Failed', cadDuration: "20min 15s", cadLoops: 7 },
        { id: 3, case: '003', status: 'Job Failed', trace: 'None', cadCheck: '', cadDuration: "5min 05s", cadLoops: 2 },
        { id: 4, case: '004', status: 'Job Failed', trace: 'None', cadCheck: '', cadDuration: "56min 04s", cadLoops: 11 },
        { id: 5, case: '005', status: 'Job Failed', trace: 'None', cadCheck: '', cadDuration: "32min 00s", cadLoops: 8 },
        { id: 6, case: '006', status: 'Completed', trace: 'None', cadCheck: 'Success', cadDuration: "35min 00s", cadLoops: 5 },
        { id: 7, case: '007', status: 'Completed', trace: 'None', cadCheck: 'Failed', cadDuration: "5min 14s", cadLoops: 1 },
        { id: 8, case: '008', status: 'Completed', trace: 'None', cadCheck: 'Failed', cadDuration: "1h 5min 53s", cadLoops: 5 },
        { id: 9, case: '009', status: 'Completed', trace: 'None', cadCheck: 'Success', cadDuration: "5min 23s", cadLoops: 2 },
        { id: 10, case: '010', status: 'Job Failed', trace: 'None', cadCheck: '', cadDuration: "2min 05s", cadLoops: 1 },
        { id: 11, case: '011', status: 'Job Failed', trace: 'None', cadCheck: '', cadDuration: "7min 03s", cadLoops: 3 },
        { id: 12, case: '012', status: 'Completed', trace: 'None', cadCheck: 'Success', cadDuration: "22min 43s", cadLoops: 4 },
        { id: 13, case: '013', status: 'In Queue', trace: 'None', cadCheck: 'Success', cadDuration: "13min 02s", cadLoops: 4 },
        { id: 14, case: '014', status: 'In Queue', trace: 'None', cadCheck: 'Failed', cadDuration: "2min 05s", cadLoops: 4 },
        //{ id: 15, case: '015', status: 'Processing (worker)', trace: 'None', cadCheck: 'Success', cadDuration: "38min 05s", cadLoops: 4 },
    ];

    return (
        <div className={clsx(classes.gridDiv, classes.root)}>
            <DataGrid rowHeight={30} headerHeight={40} rows={rows} columns={columns} disableColumnMenu hideFooter className={classes.dataGrid} />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Order XML</MenuItem>
                <MenuItem onClick={handleClose}>CAD Log</MenuItem>
                <MenuItem onClick={handleClose}>CAD Result</MenuItem>
                <MenuItem onClick={handleClose}>CAD Check</MenuItem>
            </Menu>
        </div>
    );
}