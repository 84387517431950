import React, { useCallback, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import NewsBanner from './NewsBanner';
import { TestItems } from './TestItems'
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Button, Input, MenuItem, OutlinedInput, Select, Table, TableContainer, TableHead, TableRow, FormControl, Checkbox, ListItemText, TextField, Tooltip, FormControlLabel } from '@material-ui/core';
import TestPage from './BatchPage';
import { TestResults } from './TestResults';
import { ProductionPage } from './ProductionPage';
import { ReportsPage } from './ReportsPage';
import { Assignment, CheckBox, FlashOn } from '@material-ui/icons';
import TableBatches from './TableBatches';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Router, Switch, useHistory, useLocation } from 'react-router-dom';
import { errorSnackbarOptions, successSnackbarOptions, warningSnackbarOptions } from './SnackbarOptions';
import BatchPage from './BatchPage';
import { Autocomplete } from '@material-ui/lab';
import {GlobalUseStyles} from "./GlobalUseStyles.js";
import DataValidation from './DataValidation';
import EditDataValidation from './EditDataValidation';
import BomCompare from './BomCompare';
import EditBomCompare from './EditBomCompare';       
import { useMsal } from '@azure/msal-react';
import { getBatches, getBatchesErrorModeLists, getSharedWithMe } from './RestService';
import { useSnackbar } from 'react-simple-snackbar';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.mpsoft.fi/">
                MP Soft
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

var fetchBatches = true;

const statuses= ["All", "Not run", "Processing", "Failed", "Success"];

const useStyles = makeStyles((theme) => ({
    root: {
        "& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root": {
            flexWrap: "nowrap",
          overflowX: "hidden",  // or "hidden"
          padding: "0px 5px"
        },
        "& .MuiInputBase-input": {
            height: "10px"
        }
      },
  
    select: {
      padding: "0 8px"
    },
    
    paper: {
        marginBottom: "0px",
        flex: "1",
        justifyContent: "start",
        alignItems: "left",
    },
    excelList: {
        paddingTop: "20px",
        justifyContent: "flex-end"
    },
    materialDropdown: {
        minWidth: "200px",
        maxWidth: "200px",
        display: "inline-block"
    },
    topBarItem: {
        marginRight: "20px"
    },
    blueButton: {
        backgroundColor: "#0071B9",
        color: "#fff",
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#009dff',
            borderColor: '#009d00',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#4dbbff',
            borderColor: '#4dbb00',
        },
    },
    
    addIcon: {

    },
    textField: {
        minHeight: "24px",
        maxHeight: "32px",
        marginRight: "32px"
    },
    inputStyle: {
        height: "8px",
    },
}));

export default function RoutesContainer(props) {
    const indexHandler = props.indexHandler;
    const titleHandler = props.titleHandler;
    const pathHandler = props.pathHandler;
    const classes = useStyles();
    const globalClasses = GlobalUseStyles();
    const materials = ["TestBatches/A", "KM926892472398/A", "KM543262346572/A", "KM853776484026/A", "KM108463282347/A"];
    const [selectedBomCompare, setSelectedBomCompare] = useState(null);
    const [batches, setBatches] = useState(null);
    const [sharedWithMeBatches, setSharedWithMeBatches] = useState(null);
    const [batchesErrorModes, setBatchesErrorModes] = useState([]);
    const { instance, accounts } = useMsal();
    const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
    const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
    const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
    const [resetBatchesLoaded, setResetBatchesLoaded] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);
    const [showEmptyShared, setShowEmptyShared] = useState(false);
    const [batchesUpdatedTime, setBatchesUpdatedTime] = useState(null);
    const [callUpdateErrorModes, setCallUpdateErrorModes] = useState(false);
    let [defaultMaterial, setDefaultMat] = useState("TestBatches/A");
    let [selectedBatch, setSelectedBatch] = useState(null);
    let [selectedKM, setSelectedKM] = useState("");
    let [selectedKMRev, setSelectedKMRev] = useState("");
    let [selectedOrders, setSelectedOrders] = useState(null);
    let [lastID, setLastID] = useState("CWS3503554441");
    let [newBatch, setNewBatch] = useState(false);
    let [copyBatch, setCopyBatch] = useState(false);
    let [homePage, setHomepage] = useState(true);
    let [sharedFilter, setSharedFilter] = useState(false);
    let [selectedDataValidation, setSelectedDataValidation] = useState(null);
    

    const [statusFilter, setStatusFilter] = useState([]);
    const [materialSearch, setMaterialSearch] = useState("");
    const [materialSearchMem, setMaterialSearchMem] = useState("");
    const [lastLocation, setLastLocation] = useState("/");

    const [reRender, setReRender] = useState(false);
    const history = useHistory();
    const location = useLocation();


    const onDropdownSelect = (selected) => {
        setDefaultMat(selected.label);
    };

    const handleSharedFilterChange = () => {
        setSharedFilter(!sharedFilter);
    }

    const handleTestOpen = () => {
        // indexHandler(3, name, desc);
        // pathHandler(["CWS", name]);
        
        setSelectedBatch(null);
        setSelectedKM(null);
        setSelectedKMRev(null);
        setSelectedOrders(null);
        setNewBatch(true);       
        history.push("/batch");
        indexHandler(1);
        
    };

    const handleStatusFilterChange = (event, obj) => {
        console.log("OBJ: ", obj);
        if (statusFilter.indexOf("All") > -1 && obj.indexOf("All") <= -1)
        {
            setStatusFilter([]);
        }
        else if (statusFilter.indexOf("All") <= -1 && obj.indexOf("All") > -1)
        {
            setStatusFilter(statuses);
        }
        else
        {
            setStatusFilter([...obj]);
        }        
      };

    const setCorrectMat = (mat) => {
        let splitMat = mat.split("/");
        return (splitMat[0] + "_" + splitMat[1] + ".xlsx")
    }

    const fetchData = async () => { 
        if (fetchBatches == true) {
            //console.log("fetchBatches:", fetchBatches);
            fetchBatches = false;
            
            console.log("Retrieving batch data for " + accounts[0].username);
            const sharedWithMeBatches = await getSharedWithMe(instance);
            if (sharedWithMeBatches["status"] == 404)
            {
                //openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"No shared batches found."}</p></div>)
                setShowEmptyShared(true);
            }
            else
            {
                setSharedWithMeBatches([...sharedWithMeBatches]);
                setShowEmptyShared(false);
            }

            const newBatchesErrorModes = await getBatchesErrorModeLists(instance);
            if (newBatchesErrorModes["status"] == 404)
            {
                //openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"No shared batches found."}</p></div>)
            }
            else
            {
                setBatchesErrorModes([...newBatchesErrorModes]);
                console.log("ERRORMODES: ", newBatchesErrorModes);
            }
            
            const batches = await getBatches(instance);
            // console.log("BATCHES: ", batches);
            if (batches["exception"])
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{batches["exception"]}</p></div>,300000)
                setShowEmpty(true);
            }
            else
            {
                setBatches([...batches]);
                setShowEmpty(false);
                // props.setReRender(!props.reRender);
            }

            //console.log("Retrieving material revision list from CWS Console for " + accounts[0].username);
            //let materialRevisions = []; //await getConsoleMaterials(instance,"SVC_CWS_API");
            /*let foundMaterials = [];
            if(Array.isArray(materialRevisions)) {
                let materials = [""];
                for (let mr = 0; mr < materialRevisions.length; mr++) {
                let matrev = materialRevisions[mr].split("/"); 
                if (materials.includes(matrev[0]) == false) {
                    materials.push(matrev[0]);   

                    const newData = await getMaterial(instance,matrev[0]);
                    if(Array.isArray(newData)) {
                    newData.forEach(element2 => {
                        foundMaterials.push(new Material(element2["id"], element2["revision"], element2["batches"]));
                    });
                    }
                }
                }
                consoleMaterialList = materialRevisions;
                consoleMaterials = materials;
                setBatchData([...foundMaterials]);
            console.log("materials:",materials);
            console.log("foundmaterials: ", foundMaterials);
            }*/
            }
    }

    const UpdateErrorModes = async () => { 
        const newBatchesErrorModes = await getBatchesErrorModeLists(instance);
        if (newBatchesErrorModes["status"] == 404)
        {
            //openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"No shared batches found."}</p></div>)
        }
        else
        {
            setBatchesErrorModes([...newBatchesErrorModes]);
            console.log("ERRORMODES: ", newBatchesErrorModes);
        }
    }

    const CallSetErrorModes = () => {
        setCallUpdateErrorModes(!callUpdateErrorModes);
    }

    useEffect(() => {
        setStatusFilter(statuses);
        fetchData();
    },[]);

    useEffect(() => {
        UpdateErrorModes();
    }, [callUpdateErrorModes])

    useEffect(() => {
        if (batches != null)
        {
            if (batches.length > 0)
            {
                setShowEmpty(false);
            }
        }
        if (location["pathname"] == "/")
        {
            setHomepage(true);
            setMaterialSearch("");
        }
        else
        {
            setHomepage(false);
            setReRender(!reRender);
        }
        if (location["pathname"] != "/batch")
        {
            setSelectedBatch(null);
            setSelectedKM(null);
            setSelectedKMRev(null);
            setSelectedOrders(null);
            setNewBatch(false);
            setCopyBatch(false);
        }
        if (location["pathname"] == "/batches" && lastLocation != "/batches")
        {
            setMaterialSearch(materialSearchMem);
        }
        else if(location["pathname"] != "/batches" && lastLocation == "/batches")
        {
            setMaterialSearchMem(materialSearch);
        }
        setLastLocation(location["pathname"]);
      }, [location]);

    // Dashboard
    return (
        <Switch>
             {/* MY BATCHES PAGE */}
             <Route path="/batches">
                <Container maxWidth={false} style={{width: "100%"}}>

                <div className={globalClasses.textBar}>
                    <Typography variant="h5" color="textPrimary" align="left">
                        {'Test batches'}
                    </Typography>
                    {/* <p className={classes.topBarItem}>KONE Material:</p>
                <ReactDropdown options={materials} value={defaultMaterial} placeholder="-"
                onChange={onDropdownSelect} className={clsx(classes.materialDropdown, classes.topBarItem)} /> */}
                    <div className={globalClasses.createTestButtonDiv}>
                        <Button variant="contained" className={clsx(classes.topBarItem, globalClasses.blueButton)} onClick={handleTestOpen}>
                            <AddIcon className={classes.addIcon} /> New batch
                        </Button>
                    </div>
                </div>

                {/* Filters */}
                <div style={{marginBottom: "8px", justifyContent: "flex-start", flexGrow: 1, display: "flex", alignItems: "center"}}>

                    <FormControl className={classes.root} style={{ padding: "0px", marginLeft: "4px"}}>
                                        <Autocomplete 
                                        multiple
                                        freeSolo
                                        classes={{  input: classes.inputStyle }}
                                        onChange={(event, obj) => {handleStatusFilterChange(event, obj)}}
                                        options={
                                            statuses
                                        }
                                        //defaultValue={statuses}
                                        value={statusFilter}
                                        openOnFocus
                                        
                                        disableCloseOnSelect
                                        style={{ width: "200px"  }}
                                        getOptionLabel={(option) => option}
                                        limitTags={1}
                                        ListboxProps={{ style: { maxHeight: '70vh' } }}
                                        
                                        renderOption={(option, {selected}) => (
                                                    <React.Fragment>
                                                        <Checkbox style={{color: "#0071B9"}} className={globalClasses.checkbox} checked={statusFilter.indexOf("All") > -1 ? true : statusFilter.length === 0 ? false :  selected} />
            
                                                        <ListItemText
                                                            style={{ margin: "0", overflow: "ellipsis"}}
                                                            disableTypography
                                                            primary={<Typography className={globalClasses.smallFont}>{option}</Typography>}
                                                        />
                                                    </React.Fragment>

                                                )
                                            }
                                        renderInput={(params) => (
                                                    <TextField {...params} id="outlined-basic" variant="outlined" label="Status" InputLabelProps={{ shrink: true }} />                                        )}
                                        renderTags={value => {
                                            let resultValues = value.map(function(elem) {
                                                return elem;
                                            } 
                                            ).join(", ");
                                            if (statusFilter.length === 0) {
                                                resultValues = "";
                                            }
                                                
                                            return (<Tooltip title={resultValues}>
                                            <Typography  style={{ paddingLeft: "4px" }}
                                            noWrap={true} key={"rendertags"}>{resultValues}</Typography>
                                            </Tooltip>)
                                            ;
                                        }
                                    }
                                        />
                    </FormControl>
                    <Tooltip title={"Search using Batch ID or KM Number"} TransitionComponent={({ children }) => children}>
                        <TextField id="outlined-basic" style={{marginLeft: "8px"}} InputProps={{ classes: { input: classes.inputStyle } }} placeholder={"Search"} variant="outlined" size="small" className={clsx(classes.textField)} onChange={(e) => {
                            setMaterialSearch(e.target.value.toString());
                            setResetBatchesLoaded(!resetBatchesLoaded);
                            }} value={materialSearch} />
                    </Tooltip>
                    {/* <FormControl>
                        <FormControlLabel
                        value="start"
                        control={<Checkbox value={sharedFilter} onChange={handleSharedFilterChange} className={globalClasses.checkbox} />}
                        label="Shared"
                        labelPlacement="start"
                        />
                    </FormControl> */}
                    {batchesUpdatedTime != null
                    ?
                    <Typography variant="body1" color="textSecondary" align="right" style={{fontSize: "0.75rem", marginLeft: "auto", marginRight: "7px", marginTop: "auto"}}>
                        {"Last refreshed: " + new Date(batchesUpdatedTime).toLocaleString()}
                    </Typography>
                    :
                    <></>}
                </div>
                    {
                        showEmpty == true ? <></> : <TableBatches CallSetErrorModes={CallSetErrorModes} batchesErrorModes={batchesErrorModes} setBatchesUpdatedTime={setBatchesUpdatedTime} resetBatchesLoaded={resetBatchesLoaded} setShowEmpty={setShowEmpty} batches={batches} setBatches={setBatches} materialSearch={materialSearch} statusFilter={statusFilter} showEmpty={showEmpty} homePage={homePage} setCopyBatch={setCopyBatch} setNewBatch={setNewBatch} setLastID={setLastID} reRender={reRender} setReRender={setReRender} materialName={defaultMaterial} selectedOrders={setSelectedOrders} selectedBatch={setSelectedBatch} selectedKMID={setSelectedKM} selectedKMRev={setSelectedKMRev} pathHandler={pathHandler} indexHandler={indexHandler} excelPath={defaultMaterial != "" ? setCorrectMat(defaultMaterial) : ""} />
                    }

                {/* <List className={classes.excelList}>
                <TestItems materialName={defaultMaterial} pathHandler={pathHandler} indexHandler={indexHandler} excelPath={defaultMaterial != "" ? setCorrectMat(defaultMaterial) : ""} />
                </List> */}
                </Container>
            </Route>

            {/* BATCH PAGE */}
            <Route path="/batch">
                <Container maxWidth={false} style={{width: "100%"}}>
                    <BatchPage reRender={reRender} setReRender={setReRender} batches={batches} setBatches={setBatches} setNewBatch={setNewBatch} newBatch={newBatch} copyBatch={copyBatch} lastID={lastID} selectedBatchOrders={selectedOrders} setSelectedBatchOrders={setSelectedOrders} selectedKoneMaterial={selectedKM} selectedKoneMaterialRev={selectedKMRev} koneMaterial={defaultMaterial} testName={props.testName} testDescription={props.testDescription} selectedBatch={selectedBatch} />
                </Container>
            </Route>
            
             {/* DATA VALIDATION */}
            <Route path="/datavalidation/edit">
                <Container maxWidth={false} style={{width: "100%"}}>
                    <EditDataValidation selectedDataValidation={selectedDataValidation}></EditDataValidation>
                </Container>
            </Route>
            
             <Route path="/datavalidation">
                <Container maxWidth={false} style={{width: "100%"}}>
                    <DataValidation setSelectedDataValidation={setSelectedDataValidation}></DataValidation>
                </Container>
            </Route>

            {/* BOM COMPARE */}
            <Route path="/bomcompare/edit">
                <Container maxWidth={false} style={{width: "100%"}}>
                    <EditBomCompare selectedBomCompare={selectedBomCompare}></EditBomCompare>
                </Container>
            </Route>

            <Route path="/bomcompare">
                <Container maxWidth={false} style={{width: "100%"}}>
                    <BomCompare setSelectedBomCompare={setSelectedBomCompare}></BomCompare>
                </Container>
            </Route>


            {/* HOME PAGE */}
            <Route path="/">
                <Container maxWidth={false} style={{width: "100%"}}>
                    {/* <Grid container spacing={3} style={{paddingBottom: "16px"}}>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <div>
                                <Typography className={globalClasses.blueColor} style={{paddingLeft: "16px"}} align='left' variant="h6">{"In execution"}</Typography>
                                </div>
                                <div style={{paddingTop:"18px", verticalAlign: "center"}}>
                                    <FlashOn style={{display: "inline-block", float:"left", width: "50px", height: "50px", marginLeft:"18px", borderRadius: "4px"}} className={globalClasses.blueIcon}></FlashOn>
                                    <div style={{padding: "0 16px", display: "inline-block"}}>
                                    <Typography className={globalClasses.blueColor}>{"Test order batches"}</Typography>
                                        <Typography className={globalClasses.blueColor}>{"21"}</Typography>
                                    </div>
                                    <div style={{padding: "0 16px 20px 16px", display: "inline-block"}}>
                                    <Typography className={globalClasses.blueColor}>{"Test orders"}</Typography>
                                    <Typography className={globalClasses.blueColor}>{"33"}</Typography>
                                        
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>
                                <div>
                                <Typography className={globalClasses.blueColor} style={{paddingLeft: "16px"}} align='left' variant="h6">{"Working batches"}</Typography>
                                </div>
                                <div style={{paddingTop:"18px", verticalAlign: "center"}}>
                                    <Assignment style={{display: "inline-block", float:"left", width: "50px", height: "50px", marginLeft:"18px", borderRadius: "4px"}} className={globalClasses.blueColor}></Assignment>
            
                                    <div style={{padding: "0 16px 20px 16px", display: "inline-block"}}>
                                    <Typography className={globalClasses.blueColor}>{"New test order batches"}</Typography>
                                    <Typography className={globalClasses.blueColor}>{"3"}</Typography>
                                        
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid> */}
                    {/*
                    <Paper className={classes.paper}>
                        <Typography variant="h5" color="textPrimary" align="left" style={{paddingLeft: "16px"}}>
                            {'News'}
                        </Typography>
                        <div style={{maxHeight: "100px", overflowY: "scroll"}}>
                            <Paper style={{margin: "8px"}}>
                            <Typography variant="body1" color="textPrimary" align="left" style={{paddingLeft: "16px"}}>
                                {"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at facilisis nunc. Phasellus non bibendum nulla. Interdum et malesuada fames ac ante ipsum primis in faucibus."}
                            </Typography>
                            </Paper>
                            <Paper style={{margin: "8px"}}>
                            <Typography variant="body1" color="textPrimary" align="left" style={{paddingLeft: "16px"}}>
                                {" Integer volutpat dolor at massa porta accumsan. Curabitur sodales quam lectus, ut interdum ante tempor vitae. Donec sollicitudin enim iaculis, ultricies lorem vel, egestas sem."}
                            </Typography>
                            </Paper>
                            <Paper style={{margin: "8px"}}>

                            <Typography variant="body1" color="textPrimary" align="left" style={{paddingLeft: "16px"}}>
                                {"Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut hendrerit at nisl in mattis. Donec sollicitudin arcu lacinia sapien sollicitudin pharetra."}
                            </Typography>
                            </Paper>
                            <Paper style={{margin: "8px"}}>
                            <Typography variant="body1" color="textPrimary" align="left" style={{paddingLeft: "16px"}}>
                                {"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at facilisis nunc. Phasellus non bibendum nulla. Interdum et malesuada fames ac ante ipsum primis in faucibus."}
                            </Typography>
                            </Paper>
                            <Paper style={{margin: "8px"}}>
                            <Typography variant="body1" color="textPrimary" align="left" style={{paddingLeft: "16px"}}>
                                {" Integer volutpat dolor at massa porta accumsan. Curabitur sodales quam lectus, ut interdum ante tempor vitae. Donec sollicitudin enim iaculis, ultricies lorem vel, egestas sem."}
                            </Typography>
                            </Paper>
                            <Paper style={{margin: "8px"}}>

                            <Typography variant="body1" color="textPrimary" align="left" style={{paddingLeft: "16px"}}>
                                {"Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut hendrerit at nisl in mattis. Donec sollicitudin arcu lacinia sapien sollicitudin pharetra."}
                            </Typography>
                            </Paper>
                        </div>
                    </Paper>
                    */}
                    <div className={globalClasses.textBar}>
                        <Typography variant="h5" color="textPrimary" align="left">
                            {'Latest batches'}
                        </Typography>                        
                    </div>
                    {
                        showEmpty == true ? <></> : <TableBatches CallSetErrorModes={CallSetErrorModes} batchesErrorModes={batchesErrorModes} setBatchesUpdatedTime={setBatchesUpdatedTime} key={"LatestBatches"} setShowEmpty={setShowEmpty} homePage={homePage} showEmpty={showEmpty} batches={batches} setBatches={setBatches} setLastID={setLastID} reRender={reRender} setReRender={setReRender} setCopyBatch={setCopyBatch} setNewBatch={setNewBatch} materialName={defaultMaterial} selectedOrders={setSelectedOrders} selectedBatch={setSelectedBatch} selectedKMID={setSelectedKM} selectedKMRev={setSelectedKMRev} pathHandler={pathHandler} indexHandler={indexHandler} excelPath={defaultMaterial != "" ? setCorrectMat(defaultMaterial) : ""} />
                    }
                    <div className={globalClasses.textBar}>
                        <Typography variant="h5" color="textPrimary" align="left">
                            {'Shared to me'}
                        </Typography>                        
                    </div>
                    <Container maxWidth={false}  style={{width: "100%", maxHeight: "400px", overflowY: "auto", padding: 0 }}>
                    {
                        showEmptyShared == true ? <></> : <TableBatches CallSetErrorModes={CallSetErrorModes} batchesErrorModes={batchesErrorModes} setBatchesUpdatedTime={setBatchesUpdatedTime} key={"SharedBatches"} setShowEmpty={setShowEmptyShared} homePage={homePage} showEmpty={showEmptyShared} showShared={true} batches={sharedWithMeBatches} setBatches={setSharedWithMeBatches} setCopyBatch={setCopyBatch} setNewBatch={setNewBatch} setLastID={setLastID} reRender={reRender} setReRender={setReRender} materialName={defaultMaterial} selectedOrders={setSelectedOrders} selectedBatch={setSelectedBatch} selectedKMID={setSelectedKM} selectedKMRev={setSelectedKMRev} pathHandler={pathHandler} indexHandler={indexHandler} excelPath={defaultMaterial != "" ? setCorrectMat(defaultMaterial) : ""} />
                    }
                    </Container>
                    <div style={{padding: "12px"}}></div>
                    {/* <div className={classes.textBar}>
                        <Typography variant="h5" color="textPrimary" align="left">
                            {'Shared with me'}
                        </Typography>                        
                    </div>

                    <TableBatches mode={showShared} setLastID={setLastID} reRender={setReRender} materialName={defaultMaterial} selectedOrders={setSelectedOrders} selectedBatch={setSelectedBatch} selectedKMID={setSelectedKM} selectedKMRev={setSelectedKMRev} pathHandler={pathHandler} indexHandler={indexHandler} excelPath={defaultMaterial != "" ? setCorrectMat(defaultMaterial) : ""} /> */}
                   
                </Container>
            </Route>
        </Switch>
    );
}