import { Box, Checkbox, Divider, FormControlLabel, Grid, IconButton, InputBase, makeStyles, Menu, MenuItem, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useState } from "react";
import clsx from 'clsx';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import GetApp from "@material-ui/icons/GetApp";
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .cwsTheme.state": {
            justifyContent: "center",
            alignItems: "center",
        },
        "& .cwsTheme.downloadHeader": {
            cursor: "default"
        },
        "& .cwsTheme.placeHolder": {
            backgroundColor: '#fafafa',
        },
        "& .cwsTheme.stateJob": {
            backgroundColor: 'lightcoral' //"#ff0033",
        },
        "& .cwsTheme.stateIn": {
            backgroundColor: '#acc9e6' //"#ff0033",
        },
        "& .cwsTheme.stateProcessing": {
            backgroundColor: '#FFFF99' //"#ff0033",
        },
        "& .cwsTheme.stateCancelled": {
            backgroundColor: 'lightcoral' //"#ff0033",
        },
        "& .cwsTheme.stateCompleted": {
            backgroundColor: 'lightgreen' // "#4BB543",
        },
        "& .cwsTheme.stateFailed": {
            backgroundColor: 'lightcoral' //"#ff0033",
        },
        "& .cwsTheme.stateSuccess": {
            backgroundColor: 'lightgreen' // "#4BB543",
        },
    },
    gridDiv: {
        height: "70.0vh",
        width: '100%',
        margin: "auto",
    },
    dataGrid: {
        "&:last-child": {
            overflowX: "hidden"
        }
    },
    allDiv: {
        marginTop: "20px"
    },
    topElements: {
        display: "flex",
        marginBottom: "20px",
        justifyContent: "flex-start",
        alignContent: "center"
    },
    refreshDiv: {
        border: "1px solid #d0d0d0",
        flex: "0.28",
        marginRight: "0px",
        marginLeft: "0px",
        justifyContent: "center"
    },
    rowsDiv: {
        border: "1px solid #d0d0d0",
        flex: "0.15",
        justifyContent: "center",
        alignContent: "center",
        marginLeft: "16px"
    },
    listRowsDropdown: {
        margin: "4px auto 4px auto"
    },
    listTimeDropdown: {
        margin: "4px auto 4px auto",
    },
    listTimeText: {
        width: "40px",
        marginRight: "40px"
    },
    listRowsText: {
        marginLeft: "10px"
    },
    inputRefresh: {
        width: "50px"
    },
    addMargin: {
        marginRight: "10px"
    }
}));

export const ProductionPage = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const listRows = ["5", "10", "20", "50", "100"];
    let [selectedListRows, setSelectedListRows] = useState("20");
    let [selectedListTime, setSelectedListTime] = useState("min");
    const [state, setState] = React.useState({
        refresh: true,
      });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        // name|status|Trace|Result download|CAD Duration|CAD Check|CAD Loops
        {
            field: 'state', headerName: 'State', width: 104,
            cellClassName: function customCellRenderer(params) {
                let splitStatus = params.row.state.split();
                return (
                    clsx('cwsTheme', 'state' + splitStatus)
                );
            },
            //cellClassName: clsx('cwsTheme', 'state'),
            // renderCell: function customCellRenderer(params) {
            //     let result = (<React.Fragment></React.Fragment>);
            //     if (params.row.state == "Completed") {
            //         result = <SuccessIcon aria-label="Success" htmlColor="#4BB543" />;
            //     }
            //     else if (params.row.state == "Job Failed") {
            //         result = <ErrorIcon aria-label="Failed" htmlColor="#ff0033" />;
            //     }
            //     return (
            //         result
            //     );
            // },
        },
        { field: 'koneMaterial', headerName: 'KONE Material', width: 170 },
        { field: 'orderID', headerName: 'Order ID', width: 120 },
        {
            field: '', headerName: '', flex: 1,
            headerClassName: clsx('cwsTheme', 'placeHolder')
        },
        { field: 'createdOn', headerName: 'Created on [UTC]', width: 170 },
        { field: 'modifiedOn', headerName: 'Modified on [UTC]', width: 170 },
        {
            field: 'download', headerName: 'Download', width: 120,
            sortable: false,
            headerClassName: function customHeaderRenderer() {
                return (
                    clsx('cwsTheme', 'downloadHeader')
                );
            },
            renderCell: function customCellRenderer() {
                return (
                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <GetApp />
                    </IconButton>
                )
            },
        },
    ];

    const rows = [
        // name|status|Trace|Result download|CAD Duration|CAD Check|CAD Loops
        { id: 1, state: 'Completed', koneMaterial: 'KM108463282347/A', orderID: 'MASI000022', createdOn: "08:35 09.01.2021", modifiedOn: "10:35 16.01.2021" },
        { id: 2, state: 'Job Failed', koneMaterial: 'KM108463282347/A', orderID: 'MASI000023', createdOn: "13:35 09.01.2021", modifiedOn: "13:45 13.01.2021" },
        { id: 3, state: 'Job Failed', koneMaterial: 'KM108463282347/A', orderID: 'TETU000022', createdOn: "09:45 10.01.2021", modifiedOn: "12:45 15.01.2021" },
        { id: 4, state: 'Completed', koneMaterial: 'KM543262346572/A', orderID: 'MASI000031', createdOn: "09:23 18.01.2021", modifiedOn: "08:35 20.01.2021" },
        { id: 5, state: 'Job Failed', koneMaterial: 'KM543262346572/A', orderID: 'MASI000032', createdOn: "13:35 18.01.2021", modifiedOn: "10:35 21.01.2021" },
        { id: 6, state: 'Completed', koneMaterial: 'KM543262346572/A', orderID: 'TETU000033', createdOn: "09:45 19.01.2021", modifiedOn: "10:35 20.01.2021" },
        { id: 7, state: 'Completed', koneMaterial: 'KM543262346572/A', orderID: 'TETU000034', createdOn: "10:27 19.01.2021", modifiedOn: "14:57 20.01.2021" },
        { id: 8, state: 'Completed', koneMaterial: 'KM543262346572/A', orderID: 'TETU000035', createdOn: "10:27 20.01.2021", modifiedOn: "15:34 21.01.2021" },
        { id: 9, state: 'Job Failed', koneMaterial: 'KM543262346572/A', orderID: 'MASI000036', createdOn: "09:12 21.01.2021", modifiedOn: "10:35 22.01.2021" },
        { id: 10, state: 'Completed', koneMaterial: 'KM853776484026/A', orderID: 'TETU000017', createdOn: "08:35 05.01.2021", modifiedOn: "10:35 14.01.2021" },
        { id: 11, state: 'Job Failed', koneMaterial: 'KM853776484026/A', orderID: 'TETU000018', createdOn: "12:35 05.01.2021", modifiedOn: "09:44 18.01.2021" },
        { id: 12, state: 'Completed', koneMaterial: 'KM853776484026/A', orderID: 'ANHE000019', createdOn: "09:34 06.01.2021", modifiedOn: "10:24 19.01.2021" },
        { id: 13, state: 'Completed', koneMaterial: 'KM853776484026/A', orderID: 'ANHE000020', createdOn: "08:35 08.01.2021", modifiedOn: "10:33 20.01.2021" },
        { id: 14, state: 'Completed', koneMaterial: 'KM926892472398/A', orderID: 'MASI000001', createdOn: "08:50 15.21.2020", modifiedOn: "08:45 10.01.2021" },
        { id: 15, state: 'Completed', koneMaterial: 'KM926892472398/A', orderID: 'ILNI000013', createdOn: "14:50 03.01.2021", modifiedOn: "10:35 08.01.2021" },
        { id: 16, state: 'Job Failed', koneMaterial: 'KM926892472398/A', orderID: 'ILNI000014', createdOn: "08:35 04.01.2021", modifiedOn: "09:43 10.01.2021" },
        
        { id: 17, state: 'Completed', koneMaterial: 'KM108463282347/A', orderID: 'MASI000042', createdOn: "08:35 09.01.2021", modifiedOn: "10:35 16.01.2021" },
        { id: 18, state: 'Job Failed', koneMaterial: 'KM108463282347/A', orderID: 'MASI000043', createdOn: "13:35 09.01.2021", modifiedOn: "13:45 13.01.2021" },
        { id: 19, state: 'Job Failed', koneMaterial: 'KM108463282347/A', orderID: 'TETU000052', createdOn: "09:45 10.01.2021", modifiedOn: "12:45 15.01.2021" },
        { id: 20, state: 'Completed', koneMaterial: 'KM543262346572/A', orderID: 'MASI000051', createdOn: "09:23 18.01.2021", modifiedOn: "08:35 20.01.2021" },
        { id: 21, state: 'Job Failed', koneMaterial: 'KM543262346572/A', orderID: 'MASI000053', createdOn: "13:35 18.01.2021", modifiedOn: "10:35 21.01.2021" },
        { id: 22, state: 'Completed', koneMaterial: 'KM543262346572/A', orderID: 'TETU000053', createdOn: "09:45 19.01.2021", modifiedOn: "10:35 20.01.2021" },
        { id: 23, state: 'In Queue', koneMaterial: 'KM543262346572/A', orderID: 'TETU000054', createdOn: "10:27 19.01.2021", modifiedOn: "14:57 20.01.2021" },
        { id: 24, state: 'In Queue', koneMaterial: 'KM543262346572/A', orderID: 'TETU000055', createdOn: "10:27 20.01.2021", modifiedOn: "15:34 21.01.2021" },
        { id: 25, state: 'Job Failed', koneMaterial: 'KM543262346572/A', orderID: 'MASI000056', createdOn: "09:12 21.01.2021", modifiedOn: "10:35 22.01.2021" },
        { id: 26, state: 'Completed', koneMaterial: 'KM853776484026/A', orderID: 'TETU000057', createdOn: "08:35 05.01.2021", modifiedOn: "10:35 14.01.2021" },
        { id: 27, state: 'Job Failed', koneMaterial: 'KM853776484026/A', orderID: 'TETU000058', createdOn: "12:35 05.01.2021", modifiedOn: "09:44 18.01.2021" },
        { id: 28, state: 'Completed', koneMaterial: 'KM853776484026/A', orderID: 'ANHE000049', createdOn: "09:34 06.01.2021", modifiedOn: "10:24 19.01.2021" },
        { id: 29, state: 'Completed', koneMaterial: 'KM853776484026/A', orderID: 'ANHE000060', createdOn: "08:35 08.01.2021", modifiedOn: "10:33 20.01.2021" },
        { id: 30, state: 'Completed', koneMaterial: 'KM926892472398/A', orderID: 'MASI000061', createdOn: "08:50 15.21.2020", modifiedOn: "08:45 10.01.2021" },
        { id: 31, state: 'Completed', koneMaterial: 'KM926892472398/A', orderID: 'ILNI000063', createdOn: "14:50 03.01.2021", modifiedOn: "10:35 08.01.2021" },
        { id: 32, state: 'Job Failed', koneMaterial: 'KM926892472398/A', orderID: 'ILNI000064', createdOn: "08:35 04.01.2021", modifiedOn: "09:43 10.01.2021" },
    ];

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };

      const onDropdownSelect = (selected) => {
        setSelectedListRows(selected.label);
    };

    const onDropdownSelectTime = (selected) => {
        setSelectedListTime(selected.label);
    };

    return (
        <Box className={classes.allDiv}>
            <Box className={classes.topElements}>
                    <Grid container spacing={1} alignItems="center" className={classes.refreshDiv}>
                        <Grid item className={classes.addMargin}>
                        <FormControlLabel
                        labelPlacement="start"
        control={<Checkbox color="primary" checked={state.refresh} onChange={handleChange} name="refresh" />}
        label="Refresh interval"
      />
                        </Grid>
                        <Grid item className={classes.addMargin}>
                            <InputBase
                            className={classes.inputRefresh}
                            placeholder="1"
                                id="outlined-number"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            >1</InputBase>
                        </Grid>
                        <Grid item>
                                <ReactDropdown 
                        className={classes.listTimeText}
                        options={["s", "min", "h"]} 
                        value={selectedListTime} 
                        placeholder="20"
                        onChange={onDropdownSelectTime} 
                        
                        />
                        </Grid>
                        
                        {/* <p>min</p> */}
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end" className={classes.rowsDiv}>
                    <Grid item className={classes.listRowsText}>
                            <p>List rows</p>
                        </Grid>
                        <Grid item className={classes.listRowsDropdown} >
                        <ReactDropdown 
                        options={listRows} 
                        value={selectedListRows} 
                        placeholder="20"
                        onChange={onDropdownSelect} 
                        
                        />
                        </Grid>
                        </Grid>
  
            </Box>
            <div className={clsx(classes.gridDiv, classes.root)}>
                <DataGrid rowHeight={30} headerHeight={40} rows={rows} columns={columns} hideFooterRowCount={false} className={classes.dataGrid} />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>Order XML</MenuItem>
                    <MenuItem onClick={handleClose}>CAD Log</MenuItem>
                    <MenuItem onClick={handleClose}>CAD Result</MenuItem>
                </Menu>
            </div>
        </Box>
    );
}