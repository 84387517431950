import { combineReducers } from 'redux';

const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_USER = 'SET_USER';

export function setAccessToken(newToken) {
  return {
    type: SET_ACCESS_TOKEN,
    token: newToken,
  }
}

export function setUser(user) {
  return {
    type: SET_USER,
    user: user,
  }
}

export const INITIAL_STATE = {
  token: '',
  user: {}
}


function sessionState(state=INITIAL_STATE, action) {
    switch(action.type){
        case SET_ACCESS_TOKEN:
          {
            const newToken = action.token;
            return {...state, token: newToken};
          }
        case SET_USER:
          {
            const newUser = action.user;
            return {...state, user: newUser};
          }

        default:
            return state;
    }
  }

  const session = combineReducers({
    sessionState
  });

  export default session;