import { AppBar, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, Divider, FormControl, FormControlLabel, FormHelperText, IconButton, Input, InputAdornment, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Tooltip, Typography, withStyles } from "@material-ui/core";
import { Cancel, Check, CheckBox, CheckCircleOutline, Delete, PlayArrow, Refresh, Search, Error, KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { GlobalUseStyles } from "./GlobalUseStyles";
import Add from "@material-ui/icons/Add";
import { createCompare, executeCompare, updateCompare, getBatches, getBatchesFiltered, materialRevisionItemsExists, fetchCompareId } from "./RestService";
import { useSnackbar } from "react-simple-snackbar";
import { successSnackbarOptions, errorSnackbarOptions } from "./SnackbarOptions.js";
import { useMsal } from "@azure/msal-react";
import { CheckMaterialsFound, ConvertedTimeString, GetArrayEmpty, GetEmpty, GetStringEmpty } from './Common'
import config from "../config";

const CustomRadio = withStyles({
  root: {
    color: "#0071B9",
    '&$checked': {
      color: "#0071B9",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CustomRadioDisabled = withStyles({
  root: {
    color: "#ccc",
    '&$checked': {
      color: "#ccc",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root": {
      flexWrap: "nowrap",
      overflowX: "hidden"  // or "hidden"
    }
  },
  item: {
    padding: "0 8px"
  },
  dvDiv: {
    display: "flex",
    flex: 1,
    //height: "32px",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "row",
    paddingTop: "0px",
    marginTop: "0px",
    marginLeft: theme.spacing(4),
  },
  inputStyle: {
    height: "8px",
    //   "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &[type=number]": {
    //     "-webkit-appearance": "none",
    //     display: "none",
    //     height: "29px",
    // }
  },
  multilineField: {
    width: "20%"
  },
  endAdornment: {
    paddingRight: 0
  },
  generateIdButton: {
    //marginRight: theme.spacing(2),
    marginLeft: "4px", //theme.spacing(1),
    width: "44px",
    minWidth: "44px",
    maxWidth: "44px",
    height: "29px",
    maxHeight: "29px",
    minHeight: "29px"
  },
  testInfoItem: {
    display: "flex",
    justifyContent: "center",
    marginRight: "4px"
  },
  textField: {
    minHeight: "24px",
    maxHeight: "32px",
    marginRight: "32px"
  },
  testInfoDesc: {
    flex: 1,
    //paddingRight: theme.spacing(1)
  },
  testInfoName: {
    flex: 1,
    marginRight: theme.spacing(2)
  },
  testInfoID: {
    marginRight: 0, //theme.spacing(1),
    marginLeft: theme.spacing(2),
    //flex: 1,
    width: "190px",
    backgroundColor: "#F0F0F0",
  },
  dialogPaper: {
    padding: "0px 0px",
    minWidth: "40%",
    maxWidth: "50%"
    //minHeight: "60vh",
    //maxHeight: "60vh"
  },
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  lockedTextField: {
    backgroundColor: "#F0F0F0"
  },
  helperTextOffset: {
    position: "absolute",
    left: "18px"
  },
  checkbox: {
    "&:hover": {
      backgroundColor: "rgba(0.15, 0.15, 0.7, 0.05)" //"#4D9CCE"
    },
    "&.Mui-checked:hover": {
      backgroundColor: "rgba(0.15, 0.15, 0.7, 0.05)", // "#80b8dc"
    }
  },
}))


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div

      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function EditBomCompare(props) {
  const classes = useStyles();
  const globalClasses = GlobalUseStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [allSubLevelsCheck, setAllSubLevelsCheck] = React.useState(false);

  const [addPDMCADDialog, setAddPDMCADDialog] = useState(false);
  const [addConfigurationDialog, setAddConfigurationDialog] = useState(false);
  const [addComparetoDialog, setAddComparetoDialog] = useState(false);

  const [levelsValue, setLevelsValue] = React.useState("allLevels");
  const [pdmCADValue, setPdmCADValue] = React.useState("useLatestPDM");
  const [compareName, setCompareName] = useState(props.selectedBomCompare == null ? "" : props.selectedBomCompare["name"]);
  const [compareDesc, setCompareDesc] = useState(props.selectedBomCompare == null ? "" : props.selectedBomCompare["description"]);
  const [pdmCADConfig, setPdmCADConfig] = React.useState("");
  const [testCaseSearch, setTestCaseSearch] = useState("");
  const [viewModeCase, setViewModeCase] = useState("");
  const [menuHeight, setMenuHeight] = useState("36px");
  const [windowHeight, setWindowHeight] = useState(500);
  const [submitTestCaseSearch, setSubmitTestCaseSearch] = useState("");
  const [masterStructureMaterials, setMasterStructureMaterials] = useState(props.selectedBomCompare == null ? [] : props.selectedBomCompare["configurations"])
  const [masterStructureCompareToMaterials, setMasterStructureCompareToMaterials] = useState(props.selectedBomCompare == null ? [] : props.selectedBomCompare["compareTo"])
  const [batches, setBatches] = useState(null);
  const [loadingBatches, setLoadingBatches] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredBatches, setFilteredBatches] = useState([]);
  const [filteredConfigurations, setFilteredConfigurations] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const forceUpdate = useForceUpdate();

  const [selectedConfigurations, setSelectedConfigurations] = useState([]);
  const [configurationArray, setConfigurationArray] = useState([]);
  const [testCaseElAnchor, setTestCaseElAnchor] = useState(null);

  const [selectedComparetos, setSelectedComparetos] = useState([]);
  const [comparetoArray, setComparetoArray] = useState([]);

  const [showConfigRadio, setShowConfigRadio] = useState(false);
  const [latestConfig, setLatestConfig] = useState();

  const [customLevelValue, setCustomLevelValue] = React.useState(1);
  const [selectedBomCompare, setSelectedBomCompare] = useState(props.selectedBomCompare == null ? null : props.selectedBomCompare);

  const [lock, setLock] = useState(false);
  
  const [oneToOne, setOneToOne] = useState(props.selectedBomCompare == null ? false : "options" in props.selectedBomCompare ? "oneToOne" in props.selectedBomCompare["options"] ? props.selectedBomCompare["options"]["oneToOne"] : false : false);
  const [addMaterialDialog, setAddMaterialDialog] = useState(false);
  const [newMaterial, setNewMaterial] = useState("");
  const [currentMatDialog, setCurrentMatDialog] = useState(1);

  const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
  const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
  const { instance, accounts, inProgress } = useMsal();
  const history = useHistory();

  const compareToOffset = 12;

  const handleTabChange = (event, newValue) => {
    setTestCaseSearch("");
    setTabValue(newValue);
  };

  const closeTestOrderSearch = () => {
    setSubmitTestCaseSearch("");
    setTestCaseElAnchor(null)
  };

  const handleTestOrderSearch = (namefilter) => {

    setLoadingBatches(true);
    let tmpFilteredBatches = [];
    let tmpFilteredOrders = [];
    let fetchedBatches = [];
    let optedArrayList = [...configurationArray, ...comparetoArray];
    let tmpBatches = null;
    const uniqueOrderNames = new Set(); 
    const extractPrefix = (name) => name.slice(0, 10);
    const endFunction = () => {
      if (tmpBatches["exception"]) {
        console.log(tmpBatches["exception"])
        setLoadingBatches(false);
      }
      else {
        tmpBatches.forEach(batch => {
          if (batch["status"] == "Success") {
            tmpFilteredBatches.push(batch);
            let orders = batch["orders"];
            orders.forEach(order => {
            const orderName = order["name"];
            const orderPrefix = extractPrefix(orderName);
              if (tabValue == 1) {
                if(order["status"].toLowerCase() == "job completed") {
                  let orderNamecheck = order["name"]+"-"+batch["material"]+"/"+batch["materialRevision"];
                  if(optedArrayList.length === 0 || !(optedArrayList.includes(orderNamecheck))) {
                    tmpFilteredOrders.push(
                      {
                        "orderName": order["name"],
                        "material": batch["material"],
                        "materialRevision": batch["materialRevision"],
                        "lastRun": batch["lastRun"],
                        "description": order["description"]
                      });
                  }
                }
              }
              else if (tabValue == 2 && batch["executeArguments"] != null && order["status"].toLowerCase() == "job completed") {
                if ("levelOfScan" in batch["executeArguments"])
                {
                  let executeArguments = batch["executeArguments"];
                  if (executeArguments["levelOfScan"] == "deep_check"&& !uniqueOrderNames.has(orderPrefix)) {
                    uniqueOrderNames.add(orderPrefix);
                    tmpFilteredOrders.push({
                        "orderName": orderPrefix,
                        "material": batch["material"],
                        "materialRevision": batch["materialRevision"],
                        "lastRun": batch["lastRun"],
                        "description": order["description"]
                      });
                  }
                }
              }
            });
          }
        });
      }

      tmpFilteredOrders = tmpFilteredOrders.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse().map((filteredName, index) => {
        let returnCondition = filteredName["orderName"].toLowerCase().includes(namefilter.toLowerCase())
          ||
          filteredName["material"].toLowerCase().includes(namefilter.trim().toLowerCase())
          ||
          (filteredName["material"].toLowerCase() + "/").includes(namefilter.toLowerCase())
          ||
          (filteredName["material"].toLowerCase() + "/" + filteredName["materialRevision"].toLowerCase()).includes(namefilter.toLowerCase());

        if (namefilter == "") {
          returnCondition = true;
        }

        if (returnCondition == true) {
          return filteredName;
        }
      }
      )
      tmpFilteredOrders = tmpFilteredOrders.filter(function (element) {
        return element !== undefined;
      });
      setSubmitTestCaseSearch(namefilter);
      setFilteredOrders(tmpFilteredOrders);
      setFilteredBatches(tmpFilteredBatches);
      forceUpdate();
    };
    if (batches == null) {
      let message = "";
      const CallfetchedBatches = async () => {
        fetchedBatches = await getBatches(instance)
      };
      CallfetchedBatches().catch((error) => { message += error.message + "\n" }).then(() => {
        if (message != "") {
          openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not retrieve orders"}</p></div>,300000)
          console.log("Fetching orders failed: ", message);
          setLoadingBatches(false);
        }
        else {
          setBatches(fetchedBatches);
          tmpBatches = fetchedBatches;
          endFunction();
          setLoadingBatches(false);
        }

      });

    }
    else {
      tmpBatches = batches;
      endFunction();
      setLoadingBatches(false);
    }

  };
  const handleSetConfigurationList = (orderName) => { 
    console.log("handleSetConfiguration");
    setTestCaseSearch(orderName); 
    setSubmitTestCaseSearch("");
    setFilteredConfigurations([]);
    
    const uniqueConfigurations = new Set();
    let tmpFilteredConfigurations = [];

    filteredBatches.forEach(batch => {
        // Check if the batch name matches the order name
        if (batch["name"] === orderName) { 
            let confs = batch["configurationList"];
            confs.forEach(conf => {
                // Use Set to ensure unique configuration names
                if (conf.name.startsWith(orderName) && !uniqueConfigurations.has(conf.name)) {
                    uniqueConfigurations.add(conf.name);
                    tmpFilteredConfigurations.push(conf);
                }
            });
        }
    });

    if (tmpFilteredConfigurations.length > 0) {
        tmpFilteredConfigurations.sort((conf1, conf2) => 
            new Date(conf1["lastRun"]).valueOf() - new Date(conf2["lastRun"]).valueOf()
        ).reverse();
        
        //setLatestConfig(tmpFilteredConfigurations[0].name);
        setFilteredConfigurations(tmpFilteredConfigurations);
        const displayValue = tmpFilteredConfigurations.map(conf => conf.name).join("\n");
        setLatestConfig(displayValue); 
        console.log(tmpFilteredConfigurations[0].name);
        console.log("displayValue",displayValue);
    }
};
  const handleSetConfiguration = (orderName) => {
    console.log("handleSetConfiguration");
    setTestCaseSearch(orderName);
    setSubmitTestCaseSearch("");
    setFilteredConfigurations([]);
    let tmpFilteredConfigurations = [];
    filteredBatches.forEach(batch => {
      let orders = batch["orders"];
      orders.forEach(order => {
        if (order["name"] == orderName) {
          let confs = batch["configurationList"];
          confs.forEach(conf => {
            if (conf.name.startsWith(orderName))
            tmpFilteredConfigurations.push(conf);
          });
        }
      });
    });
    if (tmpFilteredConfigurations.length > 0) {
      tmpFilteredConfigurations.sort((conf1, conf2) => new Date(conf1["lastRun"]).valueOf() - new Date(conf2["lastRun"]).valueOf()).reverse();
      setLatestConfig(tmpFilteredConfigurations[0].name);
      setFilteredConfigurations(tmpFilteredConfigurations);
      console.log(tmpFilteredConfigurations[0].name);
    }
  };

  const CallFetchCompareId = async () => {
    setLock(true);
    let message = "";
    fetchCompareId(instance).catch((error) => { message += error.message + "\n" }).then((response) => {
      if (message != "") {
        openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000)
        setLock(false);
      }
      else {
        setCompareName(response);
        setLock(false);
      }
    });
  };

  const handleRunClick = (event, type) => {
    setLock(true);
    let message = "";
    let optionsObj = {};
    let configurations = [];
    let compareto = [];
    let order = "";

    let checkStr = "";
    let checkMaterials = [];
    let trimmedMaterials = [];
    let checkSuccess = true;
    let materialsFound = [];

    console.log("handleRunClick");
    if (type == "Master Structure") {
      optionsObj["relationships"] = "cws_configure_all_relationships";
      optionsObj["oneToOne"] = oneToOne.toString();
      let conMasterStructureMaterials = masterStructureMaterials;
      let conMasterStructureCompareToMaterials = masterStructureCompareToMaterials;
      conMasterStructureMaterials = conMasterStructureMaterials.filter(x => !/^\s*$/.test(x));
      conMasterStructureCompareToMaterials = conMasterStructureCompareToMaterials.filter(x => !/^\s*$/.test(x));
      configurations.push(conMasterStructureMaterials.join('\n'));
      compareto.push(conMasterStructureCompareToMaterials.join('\n'));
      trimmedMaterials = conMasterStructureMaterials.concat(conMasterStructureCompareToMaterials);
      checkStr = trimmedMaterials.join("||");

      let duplicateMaterials = conMasterStructureMaterials.filter(function(val) {
        return conMasterStructureCompareToMaterials.indexOf(val) != -1;
      });
      if (typeof duplicateMaterials !== 'undefined' && duplicateMaterials.length > 0) {
        let errormsg = "Note: Same Material List for master structure comparison is not allowed.\n\n\n Please fix the duplicate material list for \n\n"+duplicateMaterials.join('\n');
        alert(errormsg);
        setLock(false);
        return false;
      }
    }
    else if (type == "Configuration Structure") {
      optionsObj["relationships"] = "cws_configure_all_relationships";
      optionsObj["oneToOne"] = oneToOne.toString();    
      const configurationString = configurationArray.reduce((previous, current)=>previous + "\n" + current);
      const compareString = comparetoArray.reduce((previous, current)=>previous + "\n" + current);
      configurations.push(configurationString);
      compareto.push(compareString);
      console.log("configurations: ", configurations);
      console.log("comparetoArray: ", compareto);
    }
    else {
      if (pdmCADValue == "useLatestPDM") {
         const configArray = latestConfig.split("\n");
         configurations.push(...configArray);
         compareto.push(...configArray);
       }
      else {
        configurations.push(pdmCADConfig);
        compareto.push(pdmCADConfig);
      }
      optionsObj["pdmCADValue"] = pdmCADValue;
      optionsObj["order"] = testCaseSearch;
    }

    optionsObj["environment"] = config.environment;
    const saveAndExecute = () => {
      if (selectedBomCompare == null) {
        let compareData = null;
        let executeData = null;

        const createCompareCall = async () => {
          compareData = await createCompare(instance, type, compareName, compareDesc, configurations, compareto, optionsObj);
        };
        createCompareCall().catch((error) => { message += error.message + "\n" }).then(() => {
          console.log("CREATED: ", compareData);
          setSelectedBomCompare(compareData);
          const executeCompareCall = async () => {
            executeData = await executeCompare(instance, compareData["compareID"], configurations, compareto, optionsObj, accounts[0].username);
          };

          executeCompareCall().catch((error) => { message += error.message + "\n" }).then(() => {
            console.log("EXECUTED: ", executeData);
            if (message != "") {
              openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><Error style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
              setLock(false);
            }
            else {
              openSuccessSnackbar(<div style={{ whiteSpace: "nowrap" }}><CheckCircleOutline style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"BOM Compare execute started successfully."}</p></div>,300000);
              setLock(false);
              history.push("/bomcompare");
            }
          });

        });
      }
      else {
        let compareData = null;
        let executeData = null;
        const updateCompareCall = async () => {
          compareData = await updateCompare(instance, selectedBomCompare["compareID"], compareName, compareDesc, configurations, compareto, optionsObj);
        };
        updateCompareCall().catch((error) => { message += error.message + "\n" }).then(() => {
          console.log("UPDATED: ", compareData);
          setSelectedBomCompare(compareData);
          const executeCompareCall = async () => {
            executeData = await executeCompare(instance, compareData["compareID"], configurations, compareto, optionsObj, accounts[0].username);
          };

          executeCompareCall().catch((error) => { message += error.message + "\n" }).then(() => {
            console.log("EXECUTED: ", executeData);
            if (message != "") {
              openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><Error style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
              setLock(false);
            }
            else {
              openSuccessSnackbar(<div style={{ whiteSpace: "nowrap" }}><CheckCircleOutline style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"BOM compare execute started successfully."}</p></div>,300000);
              setLock(false);
              history.push("/bomcompare");
            }
          });

        });
      }
    }

    const checkMaterialsCall = async () => {
      materialsFound = await materialRevisionItemsExists(instance, checkStr, accounts[0].username);
    };

    if (type == "Master Structure") {
      checkMaterialsCall().catch((error) => { message += error.message }).then(() => {
        if (message != "") {
          openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error during material check: " + message}</p></div>,300000)
          setLock(false);
        }
        else {
          var missingMats = CheckMaterialsFound(materialsFound, trimmedMaterials);
          if (missingMats != "") {
            openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not find the following materials: " + missingMats}</p></div>,300000)
            setLock(false);
          }
          else {
            // Materials exist, proceed to create/update and run
            saveAndExecute();
          }
        }
      });
    }
    else {
      saveAndExecute();
    }

  };

  const GetRunDisabled = () => {
    let returnValue = (GetStringEmpty(compareName) || GetStringEmpty(compareDesc));

    // Master Structure
    if (tabValue == 0) {
      returnValue = returnValue || GetEmpty(masterStructureMaterials) || GetEmpty(masterStructureCompareToMaterials);
    }
    // Configuration Structure
    else if (tabValue == 1) {
      returnValue = returnValue || GetEmpty(configurationArray) || GetEmpty(comparetoArray);
    }
    // PDM CAD Comparison
    else if (tabValue == 2) {
      returnValue = returnValue || GetEmpty(searchValue);
    }

    return returnValue || lock;
  };

  const GetViewMode = () => {
    // let returnValue = selectedBomCompare != null;

    // return returnValue;
    return false;
  };

  useEffect(() => {
    if (props.selectedBomCompare != null) {
      console.log(props.selectedBomCompare);

      if (props.selectedBomCompare["compareType"] == "Master Structure") {
        setTabValue(0);
        setMasterStructureMaterials(selectedBomCompare["configurations"][0].split("\n"));
        setMasterStructureCompareToMaterials(selectedBomCompare["compareTo"][0].split("\n"));
      }
      else if (props.selectedBomCompare["compareType"] == "Configuration Structure") {
        setTabValue(1);
        setConfigurationArray([...selectedBomCompare["configurations"][0].split("\n")]);
        setComparetoArray([...selectedBomCompare["compareTo"][0].split("\n")]);
      }
      else if (props.selectedBomCompare["compareType"] == "PDM CAD Comparison") {
        setTabValue(2);
        //configurations.push(latestConfig);
        let selectedConfigOptions = selectedBomCompare["options"];
        if (selectedConfigOptions["order"] != null) {
          setViewModeCase(selectedConfigOptions["order"]);
          //setTestCaseSearch(selectedConfigOptions["order"]);
        }
        if (selectedConfigOptions["pdmCADValue"] != null) {
          setPdmCADValue(selectedConfigOptions["pdmCADValue"]);
        if (selectedConfigOptions["pdmCADValue"] === "useLatestPDM") 
        setLatestConfig(selectedBomCompare["configurations"].map(conf => conf.trim()).join("\n"));
        else
          setPdmCADConfig(selectedBomCompare["configurations"])
                }
                else
                setLatestConfig(selectedBomCompare["configurations"].map(conf => conf.trim()).join("\n"));
                if (selectedBomCompare["configurations"] && GetEmpty(selectedBomCompare["configurations"]) == false) {
                let newTestCaseSearchValue = "";
                for (let i = 0; i < selectedBomCompare["configurations"].length; i++) {
                let splitConf = selectedBomCompare["configurations"][i].split("-");
                if (splitConf.length > 1) {
                    newTestCaseSearchValue += splitConf[0] + "-" + splitConf[1] + "\n";
                }
              }
              setTestCaseSearch(newTestCaseSearchValue.trim()); 
              }
        setShowConfigRadio(true);
      }
    }
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setMenuHeight((windowHeight - windowHeight * 0.5 < 220 && windowHeight - windowHeight * 0.5 > 72)
        ?
        (windowHeight * 0.25).toString() + "px"
        :
        windowHeight - windowHeight * 0.5 > 72
          ?
          (windowHeight * 0.5).toString() + "px"
          :
          "36px");
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLevelsValueChange = (event) => {
    setLevelsValue(event.target.value);
  };

  const handlePDMCadValue = (event) => {
    setPdmCADValue(event.target.value);
  };


  const handleConfigClick = (value) => () => {
    console.log("handleConfigClick");
    const currentIndex = selectedConfigurations.indexOf(value);
    const newChecked = [...selectedConfigurations];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedConfigurations(newChecked);
    console.log("handleConfigClick", currentIndex);
    console.log(newChecked);

  };

  const handleComparetoClick = (value) => () => {
    console.log("handleComparetoClick");
    const currentIndex = selectedComparetos.indexOf(value);
    const newChecked = [...selectedComparetos];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedComparetos(newChecked);

    console.log("handleComparetoClick", currentIndex);
    console.log(newChecked);
  };

  const handleConfigClickPDMCAD = (confobj) => () => {
    console.log("handleConfigClickPDMCAD");
    setPdmCADConfig(confobj.name);
    setAddPDMCADDialog(false);
    console.log("PDM CAD configuration selected: ", confobj.name);
  };

  const handleDeleteConfigurationClick = (value) => () => {
    let newFinalConfigs = [...configurationArray];
    newFinalConfigs = newFinalConfigs.filter((e) => { return e !== value });
    setConfigurationArray(newFinalConfigs);
  };

  const handleDeleteConfigurationClick2 = (value) => () => {
    let newFinalConfigs = [...comparetoArray];
    newFinalConfigs = newFinalConfigs.filter((e) => { return e !== value });
    setComparetoArray(newFinalConfigs);
  };

  const handleConfigurationConfirmClick = () => {
    let newFinalConfigs = [...configurationArray];
    let selectedConfigurationNames = [...selectedConfigurations.map((c) => { return c["name"] })];
    newFinalConfigs = newFinalConfigs.concat(selectedConfigurationNames);

    const uniqueConfigs = new Set(newFinalConfigs);
    const uniqueArray = Array.from(uniqueConfigs);
    setConfigurationArray(uniqueArray);
    setAddConfigurationDialog(false);
    setSelectedConfigurations([]);
    console.log("configurationArray:", configurationArray)
  };

  const handleComparetoConfirmClick = () => {
    let newFinalConfigs = [...comparetoArray];
    let selectedConfigurationNames = [...selectedComparetos.map((c) => { return c["name"] })];
    newFinalConfigs = newFinalConfigs.concat(selectedConfigurationNames);

    const uniqueConfigs = new Set(newFinalConfigs);
    const uniqueArray = Array.from(uniqueConfigs);
    setComparetoArray(uniqueArray);
    setAddComparetoDialog(false);
    setSelectedComparetos([]);
    console.log("comparetoArray:", comparetoArray)
  };

  const handleUpClick = (e, currentList) => {
    if (currentList == "configurations")
    {
        const index = configurationArray.indexOf(e);
        setConfigurationArray(moveElementUp(configurationArray, index));
    }
    else if(currentList == "compareto")
    {
      const index = comparetoArray.indexOf(e);
      setComparetoArray(moveElementUp(comparetoArray, index));
    }
    else if(currentList == "materials")
    {
      const index = masterStructureMaterials.indexOf(e);
      setMasterStructureMaterials(moveElementUp(masterStructureMaterials, index));
    }
    else if(currentList == "materials2")
    {
      const index = masterStructureCompareToMaterials.indexOf(e);
      setMasterStructureCompareToMaterials(moveElementUp(masterStructureCompareToMaterials, index));
    }
  }

  const handleDownClick = (e, currentList) => {
    if (currentList == "configurations")
      {
          const index = configurationArray.indexOf(e);
          setConfigurationArray(moveElementDown(configurationArray, index));
      }
      else if(currentList == "compareto")
      {
        const index = comparetoArray.indexOf(e);
        setComparetoArray(moveElementDown(comparetoArray, index));
      } 
      else if(currentList == "materials")
        {
          const index = masterStructureMaterials.indexOf(e);
          setMasterStructureMaterials(moveElementDown(masterStructureMaterials, index));
        }
        else if(currentList == "materials2")
        {
          const index = masterStructureCompareToMaterials.indexOf(e);
          setMasterStructureCompareToMaterials(moveElementDown(masterStructureCompareToMaterials, index));
        }
  }

  const moveElementUp = (array, index) => {
    const newArray = [...array]; // Create a shallow copy of the array
    if (index === 0) {
      const firstElement = newArray.shift(); // Remove the first element
      newArray.push(firstElement); // Add it to the end
    } else {
      [newArray[index], newArray[index - 1]] = [newArray[index - 1], newArray[index]]; // Swap
    }
    return newArray;
  };

  const moveElementDown = (array, index) => {
    const newArray = [...array]; // Create a shallow copy of the array
    if (index === newArray.length - 1) {
      const lastElement = newArray.pop(); // Remove the last element
      newArray.unshift(lastElement); // Add it to the beginning
    } else {
      [newArray[index], newArray[index + 1]] = [newArray[index + 1], newArray[index]]; // Swap
    }
    return newArray;
  };

  const handleOneToOneRadio = (e) => {
    setOneToOne(e.target.value === "true");
  }

  const handleAddMaterialConfirmClick = () => {
    if (currentMatDialog == 1)
    {
      let newFinalMats = [...masterStructureMaterials];
      newFinalMats = newFinalMats.concat(newMaterial);

      const uniqueMats = new Set(newFinalMats);
      const uniqueArray = Array.from(uniqueMats);
      setMasterStructureMaterials(uniqueArray);
    }
    else if(currentMatDialog == 2)
    {
      let newFinalMats = [...masterStructureCompareToMaterials];
      newFinalMats = newFinalMats.concat(newMaterial);

      const uniqueMats = new Set(newFinalMats);
      const uniqueArray = Array.from(uniqueMats);
      setMasterStructureCompareToMaterials(uniqueArray);
    }
    
    setAddMaterialDialog(false);
  };

  const handleDeleteMaterialClick = (value) => () => {
    let newFinalMats = [...masterStructureMaterials];
    newFinalMats = newFinalMats.filter((e) => { return e !== value });
    setMasterStructureMaterials(newFinalMats);
  };

  const handleDeleteMaterial2Click = (value) => () => {
    let newFinalMats = [...masterStructureCompareToMaterials];
    newFinalMats = newFinalMats.filter((e) => { return e !== value });
    setMasterStructureCompareToMaterials(newFinalMats);
  };


  const convertMSMToUppercase = (e) => {
    e.preventDefault();
    setMasterStructureMaterials(e.target.value.toUpperCase());
  };

  const convertMSCTMToUppercase = (e) => {
    e.preventDefault();
    setMasterStructureCompareToMaterials(e.target.value.toUpperCase());
  };
 return (
    <>
      <Paper>

        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary"
          textColor="primary" >
          <Tab label="Master Structure" disabled={selectedBomCompare == null ? false : selectedBomCompare["type"] == "Master Structure" ? false : true} />
          <Tab label="Configuration Structure" disabled={selectedBomCompare == null ? false : selectedBomCompare["type"] == "Configuration Structure" ? false : true} />
          <Tab label="PDM CAD Comparison" disabled={selectedBomCompare == null ? false : selectedBomCompare["type"] == "PDM CAD Comparison" ? false : true} />
        </Tabs>
      </Paper>

      <TabPanel value={tabValue} index={0}>
        <Paper style={{ maxHeight: "70vh", paddingBottom: "22px" }}>

          <div className={classes.dvDiv} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
            <Typography className={classes.testInfoItem}>Name:</Typography>
            {/* <Tooltip title={testName} TransitionComponent={({ children }) => children}>
                        <TextField id="outlined-basic" defaultValue={testName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoName)} />
                    </Tooltip> */}

            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled error={GetStringEmpty(compareName)} helperText={GetStringEmpty(compareName) ? "Missing BOM Compare name" : ""} FormHelperTextProps={{
                className: globalClasses.helperText
              }} InputProps={{ classes: { input: classes.inputStyle } }} id="outlined-basic" value={compareName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoID)} onChange={(e) => { setCompareName(e.target.value); }} />
            </Tooltip>

            <Tooltip title={"Generate ID"}>
              <span>

                <Button disabled={GetViewMode()} size="small" className={clsx(classes.generateIdButton, globalClasses.blueButton, GetViewMode() && globalClasses.disabledButton)} onClick={() => {
                  if (compareName == "") {
                    CallFetchCompareId();
                  }
                }} >

                  <Refresh />
                </Button>
              </span>
            </Tooltip>

            <Typography className={classes.testInfoItem} style={{ paddingLeft: "14px" }}>Description:</Typography>
            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled={GetViewMode()} error={GetStringEmpty(compareDesc)} helperText={GetStringEmpty(compareDesc) ? "Missing BOM Compare description" : ""} FormHelperTextProps={{
                className: clsx(globalClasses.helperText, classes.helperTextOffset)
              }} id="outlined-basic" InputProps={{ classes: { input: classes.inputStyle } }} value={compareDesc} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} onChange={(e) => { setCompareDesc(e.target.value); }} />
            </Tooltip>
            {/* <Typography>{"Run BOM Comparison for Master structure"}</Typography> */}
            <Tooltip title={"Run BOM Comparison"} TransitionComponent={({ children }) => children}>
              <span>
                <IconButton disabled={GetRunDisabled() || GetViewMode()} style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton, (GetRunDisabled() || GetViewMode()) && globalClasses.disabledButton)} onClick={(e) => {
                  handleRunClick(e, "Master Structure");
                }}>
                  <PlayArrow />
                </IconButton>
              </span>
            </Tooltip>
          </div>

          <div className={classes.dvDiv} style={{ paddingTop: "8px", alignItems: "flex-start", position: "relative" }}>
          <RadioGroup row aria-label="onetoone" name="onetoone" value={oneToOne.toString()} onChange={handleOneToOneRadio}>
                    <FormControlLabel
                      style={{ marginLeft: 0 }}
                      value="false"
                      control={
                        GetViewMode() ?
                          <CustomRadioDisabled style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />
                          :
                          <CustomRadio style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />}
                      label="ALL to ALL"
                      labelPlacement="end"
                      disabled={GetViewMode()}
                    />
                    <FormControlLabel
                      style={{ marginLeft: "8px" }}
                      value="true"
                      control={
                        GetViewMode() ?
                          <CustomRadioDisabled style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />
                          :
                          <CustomRadio style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />}
                      label="ONE to ONE"
                      labelPlacement="end"
                      disabled={GetViewMode()}
                    />
                  </RadioGroup>
          </div>

          <div className={classes.dvDiv} style={{ paddingTop: "26px", alignItems: "flex-start", position: "relative"  }}>

            <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)", position: "absolute", top: 6, left: 0 }}>{"Materials"}</Typography>
            <Tooltip title={"Add material"} PopperProps={{ style: { marginLeft: 665 } }} TransitionComponent={({ children }) => children}>
              <span>

                <IconButton disabled={GetViewMode()} style={{ position: "absolute", top: 0, left: 395, width: 24, height: 24, borderRadius: 8 }} className={clsx(globalClasses.blueButton)} onClick={() => {
                  setCurrentMatDialog(1);
                  setAddMaterialDialog(true);
                  setNewMaterial("");
                }}>
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
            <List style={{ minWidth: "430px", marginBottom: "12px" }} className={clsx(!GetEmpty(masterStructureMaterials) && globalClasses.addBorder, (GetEmpty(masterStructureMaterials) && !GetViewMode()) && globalClasses.errorBorder)}>
              {masterStructureMaterials.map((e, index) => {
                return (
                  <React.Fragment key={"Materials" + e + index}>
                    <ListItem classes={{ root: classes.item }}>
                    <IconButton disabled={GetViewMode()} aria-label="Up" className={globalClasses.customButton} onClick={() => handleUpClick(e, "materials")}>
                          <KeyboardArrowUp />
                        </IconButton>
                    <IconButton disabled={GetViewMode()} aria-label="Down" className={globalClasses.customButton} onClick={() => handleDownClick(e, "materials")}>
                          <KeyboardArrowDown />
                        </IconButton>
                      <ListItemText
                        primary={<Typography className={globalClasses.smallFont}>{e}</Typography>}
                      />
                      <ListItemSecondaryAction>
                        <IconButton disabled={GetViewMode()} edge="end" aria-label="delete" className={globalClasses.customButton} onClick={handleDeleteMaterialClick(e)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index == masterStructureMaterials.length - 1 ? null : <Divider />}
                  </React.Fragment>
                );
              })
              }
            </List>
            {GetViewMode() ? null : GetEmpty(masterStructureMaterials) ?
              <Typography style={{ fontSize: "0.66rem", color: "#f44336", position: "absolute", bottom: -4 + (32 * masterStructureCompareToMaterials.length), left: 0 }}>{"Material list empty"}</Typography>
              : null}
            <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)", position: "absolute", top: 6, left: 430 + compareToOffset }}>{"Compare to"}</Typography>
            <Tooltip title={"Add material"} PopperProps={{ style: { marginLeft: 815 + compareToOffset } }} TransitionComponent={({ children }) => children}>
              <span>

                <IconButton disabled={GetViewMode()} style={{ position: "absolute", top: 0, left: 815 + compareToOffset, width: 24, height: 24, borderRadius: 8 }} className={clsx(globalClasses.blueButton)} onClick={() => {
                  setCurrentMatDialog(2);
                  setAddMaterialDialog(true);
                  setNewMaterial("");
                }}>
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
            <List style={{ minWidth: "430px", marginBottom: "12px", left: compareToOffset }} className={clsx(!GetEmpty(masterStructureCompareToMaterials) && globalClasses.addBorder, (GetEmpty(masterStructureCompareToMaterials) && !GetViewMode()) && globalClasses.errorBorder)}>
              {masterStructureCompareToMaterials.map((e, index) => {
                return (
                  <React.Fragment key={"Materials2" + e + index}>
                    <ListItem classes={{ root: classes.item }}>
                    <IconButton disabled={GetViewMode()} aria-label="Up" className={globalClasses.customButton} onClick={() => handleUpClick(e, "materials2")}>
                          <KeyboardArrowUp />
                        </IconButton>
                    <IconButton disabled={GetViewMode()} aria-label="Down" className={globalClasses.customButton} onClick={() => handleDownClick(e, "materials2")}>
                          <KeyboardArrowDown />
                        </IconButton>
                      <ListItemText
                        primary={<Typography className={globalClasses.smallFont}>{e}</Typography>}
                      />
                      <ListItemSecondaryAction>
                        <IconButton disabled={GetViewMode()} edge="end" aria-label="delete" className={globalClasses.customButton} onClick={handleDeleteMaterial2Click(e)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index == masterStructureCompareToMaterials.length - 1 ? null : <Divider />}
                  </React.Fragment>
                );
              })
              }
            </List>
            {GetViewMode() ? null : GetEmpty(masterStructureCompareToMaterials) ?
              <Typography style={{ fontSize: "0.66rem", color: "#f44336", position: "absolute", bottom: -4 + (32 * masterStructureMaterials.length), left: 430 + compareToOffset }}>{"Material list empty"}</Typography>
              : null}
          </div>
        </Paper>
      </TabPanel>
      {/* CONFIGURATION STRUCTURE */}
      <TabPanel value={tabValue} index={1}>
        <Paper style={{ maxHeight: "70vh", paddingBottom: "8px" }}>
          <div className={classes.dvDiv} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
            <Typography className={classes.testInfoItem}>Name:</Typography>
            {/* <Tooltip title={testName} TransitionComponent={({ children }) => children}>
                        <TextField id="outlined-basic" defaultValue={testName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoName)} />
                    </Tooltip> */}

            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled error={GetStringEmpty(compareName)} helperText={GetStringEmpty(compareName) ? "Missing BOM Compare name" : ""} FormHelperTextProps={{
                className: globalClasses.helperText
              }} InputProps={{ classes: { input: classes.inputStyle } }} id="outlined-basic" value={compareName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoID)} onChange={(e) => { setCompareName(e.target.value); }} />
            </Tooltip>

            <Tooltip title={"Generate ID"}>
              <span>

                <Button disabled={GetViewMode()} size="small" className={clsx(classes.generateIdButton, globalClasses.blueButton, GetViewMode() && globalClasses.disabledButton)} onClick={() => {
                  if (compareName == "") {
                    CallFetchCompareId();
                  }
                }} >

                  <Refresh />
                </Button>
              </span>
            </Tooltip>

            <Typography className={classes.testInfoItem} style={{ paddingLeft: "14px" }}>Description:</Typography>
            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled={GetViewMode()} error={GetStringEmpty(compareDesc)} helperText={GetStringEmpty(compareDesc) ? "Missing BOM Compare description" : ""} FormHelperTextProps={{
                className: clsx(globalClasses.helperText, classes.helperTextOffset)
              }} id="outlined-basic" InputProps={{ classes: { input: classes.inputStyle } }} value={compareDesc} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} onChange={(e) => { setCompareDesc(e.target.value); }} />
            </Tooltip>
            {/* <Typography>{"Run BOM Comparison for Configuration structure"}</Typography> */}
            <Tooltip title={"Run BOM Comparison"} TransitionComponent={({ children }) => children}>
              <span>
                <IconButton disabled={GetRunDisabled() || GetViewMode()} style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton, (GetRunDisabled() || GetViewMode()) && globalClasses.disabledButton)} onClick={(e) => {
                  handleRunClick(e, "Configuration Structure");
                }}>
                  <PlayArrow />
                </IconButton>
              </span>
            </Tooltip>
          </div>

          <div className={classes.dvDiv} style={{ paddingTop: "8px", alignItems: "flex-start", position: "relative" }}>
          <RadioGroup row aria-label="onetoone" name="onetoone" value={oneToOne.toString()} onChange={handleOneToOneRadio}>
                    <FormControlLabel
                      style={{ marginLeft: 0 }}
                      value="false"
                      control={
                        GetViewMode() ?
                          <CustomRadioDisabled style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />
                          :
                          <CustomRadio style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />}
                      label="ALL to ALL"
                      labelPlacement="end"
                      disabled={GetViewMode()}
                    />
                    <FormControlLabel
                      style={{ marginLeft: "8px" }}
                      value="true"
                      control={
                        GetViewMode() ?
                          <CustomRadioDisabled style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />
                          :
                          <CustomRadio style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />}
                      label="ONE to ONE"
                      labelPlacement="end"
                      disabled={GetViewMode()}
                    />
                  </RadioGroup>
          </div>

          <div className={classes.dvDiv} style={{ paddingTop: "26px", alignItems: "flex-start", position: "relative" }}>
            <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)", position: "absolute", top: 6, left: 0 }}>{"Configurations"}</Typography>
            <Tooltip title={"Add configurations"}PopperProps={{ style: { marginLeft: 650 } }} TransitionComponent={({ children }) => children}>
              <span>

                <IconButton disabled={GetViewMode()} style={{ position: "absolute", top: 0, left: 395, width: 24, height: 24, borderRadius: 8 }} className={clsx(globalClasses.blueButton)} onClick={() => {
                  setAddConfigurationDialog(true);
                  setTestCaseSearch("");
                  filteredConfigurations.length = 0;
                }}>
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
            <List style={{ minWidth: "430px", marginBottom: "12px" }} className={clsx(!GetEmpty(configurationArray) && globalClasses.addBorder, (GetEmpty(configurationArray) && !GetViewMode()) && globalClasses.errorBorder)}>
              {configurationArray.map((e, index) => {
                return (
                  <React.Fragment key={"Configurations" + e + index}>
                    <ListItem classes={{ root: classes.item }}>
                    <IconButton disabled={GetViewMode()} aria-label="Up" className={globalClasses.customButton} onClick={() => handleUpClick(e, "configurations")}>
                          <KeyboardArrowUp />
                        </IconButton>
                    <IconButton disabled={GetViewMode()} aria-label="Down" className={globalClasses.customButton} onClick={() => handleDownClick(e, "configurations")}>
                          <KeyboardArrowDown />
                        </IconButton>
                      <ListItemText
                        primary={<Typography className={globalClasses.smallFont}>{e}</Typography>}
                      />
                      <ListItemSecondaryAction>
                        <IconButton disabled={GetViewMode()} edge="end" aria-label="delete" className={globalClasses.customButton} onClick={handleDeleteConfigurationClick(e)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index == configurationArray.length - 1 ? null : <Divider />}
                  </React.Fragment>
                );
              })
              }
            </List>
            {GetViewMode() ? null : GetEmpty(configurationArray) ?
              <Typography style={{ fontSize: "0.66rem", color: "#f44336", position: "absolute", bottom: -4 + (32 * comparetoArray.length), left: 0 }}>{"Configuration list empty"}</Typography>
              : null}

            <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)", position: "absolute", top: 6, left: 430 + compareToOffset }}>{"Compare to"}</Typography>
            <Tooltip title={"Add compare to"} PopperProps={{ style: { marginLeft: 850 } }} TransitionComponent={({ children }) => children}>
              <span>

                <IconButton disabled={GetViewMode()} style={{ position: "absolute", top: 0, left: 825 + compareToOffset, width: 24, height: 24, borderRadius: 8 }} className={clsx(globalClasses.blueButton)} onClick={() => {
                  setAddComparetoDialog(true);
                  setTestCaseSearch("");
                  filteredConfigurations.length = 0;
                }}>
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
            <List style={{ minWidth: "430px", marginBottom: "12px", left: compareToOffset }} className={clsx(!GetEmpty(comparetoArray) && globalClasses.addBorder, (GetEmpty(comparetoArray) && !GetViewMode()) && globalClasses.errorBorder)}>
              {comparetoArray.map((e, index) => {
                return (
                  <React.Fragment key={"Compare to" + e + index}>
                    <ListItem classes={{ root: classes.item }}>
                    <IconButton disabled={GetViewMode()} aria-label="Up" className={globalClasses.customButton} onClick={() => handleUpClick(e, "compareto")}>
                          <KeyboardArrowUp />
                        </IconButton>
                    <IconButton disabled={GetViewMode()} aria-label="Down" className={globalClasses.customButton} onClick={() => handleDownClick(e, "compareto")}>
                          <KeyboardArrowDown />
                        </IconButton>
                      <ListItemText
                        primary={<Typography className={globalClasses.smallFont}>{e}</Typography>}
                      />
                      <ListItemSecondaryAction>
                        <IconButton disabled={GetViewMode()} edge="end" aria-label="delete" className={globalClasses.customButton} onClick={handleDeleteConfigurationClick2(e)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index == comparetoArray.length - 1 ? null : <Divider />}
                  </React.Fragment>
                );
              })
              }
            </List>
            {GetViewMode() ? null : GetEmpty(comparetoArray) ?
              <Typography style={{ fontSize: "0.66rem", color: "#f44336", position: "absolute", bottom: -4 + (32 * configurationArray.length), left: 430 + compareToOffset }}>{"Configuration list empty"}</Typography>
              : null}

          </div>

        </Paper>
      </TabPanel>

      {/* PDM CAD COMPARISON */}
      <TabPanel value={tabValue} index={2}>
        <Paper style={{ maxHeight: "70vh" }}>
          {/* <div className={classes.dvDiv} style={{ paddingTop: "4px", paddingBottom: "4px" }}> */}
          {/* <Typography>{"Run PDM CAD Comparison"}</Typography> */}
          <div className={classes.dvDiv} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
            <Typography className={classes.testInfoItem}>Name:</Typography>
            {/* <Tooltip title={testName} TransitionComponent={({ children }) => children}>
                        <TextField id="outlined-basic" defaultValue={testName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoName)} />
                    </Tooltip> */}

            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled error={GetStringEmpty(compareName)} helperText={GetStringEmpty(compareName) ? "Missing BOM Compare name" : ""} FormHelperTextProps={{
                className: globalClasses.helperText
              }} InputProps={{ classes: { input: classes.inputStyle } }} id="outlined-basic" value={compareName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoID)} onChange={(e) => { setCompareName(e.target.value); }} />
            </Tooltip>

            <Tooltip title={"Generate ID"}>
              <span>

                <Button disabled={GetViewMode()} size="small" className={clsx(classes.generateIdButton, globalClasses.blueButton, GetViewMode() && globalClasses.disabledButton)} onClick={() => {
                  if (compareName == "") {
                    CallFetchCompareId();
                  }
                }} >

                  <Refresh />
                </Button>
              </span>
            </Tooltip>

            <Typography className={classes.testInfoItem} style={{ paddingLeft: "14px" }}>Description:</Typography>
            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled={GetViewMode()} error={GetStringEmpty(compareDesc)} helperText={GetStringEmpty(compareDesc) ? "Missing BOM Compare description" : ""} FormHelperTextProps={{
                className: globalClasses.helperText
              }} id="outlined-basic" InputProps={{ classes: { input: classes.inputStyle } }} value={compareDesc} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} onChange={(e) => { setCompareDesc(e.target.value); }} />
            </Tooltip>
            <Tooltip title={"Run PDM CAD Comparison"} TransitionComponent={({ children }) => children}>
              <span>
                <IconButton disabled={GetRunDisabled()} style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton, GetRunDisabled() && globalClasses.disabledButton)} onClick={(e) => {
                  handleRunClick(e, "PDM CAD Comparison");
                }}>
                  <PlayArrow />
                </IconButton>
              </span>
            </Tooltip>
            {/* </div> */}
          </div>

          <div className={classes.dvDiv} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            <Typography style={{whiteSpace: "nowrap"}}>{"Test order: "}</Typography>
            <Autocomplete
              id="testCaseSearch"
              size="small"
              classes={{ input: classes.inputStyle }}
              style={{ width: "350px", marginLeft: "4px", marginRight: "8px" }}
              clearOnBlur
              selectOnFocus
              onChange={(e, value) => {
                handleTestOrderSearch("");
                setTestCaseSearch("");
                handleSetConfigurationList(value["orderName"]);
                setSearchValue(value);
              }}
              getOptionLabel={(option) => option ? option["orderName"] : ""}
              loading={loadingBatches}
              renderOption={(option, index) =>
                loadingBatches ?
                  <CircularProgress></CircularProgress>
                  :
                  option
                    ?
                    <MenuItem disabled={GetViewMode()} key={"CaseSearch" + option["orderName"] + index}
                      onClick={(e) => {
                        setShowConfigRadio(true);
                        handleSetConfigurationList(option["orderName"]);
                      }}>
                      {/* <Typography>{filteredName["orderName"]}</Typography> */}
                      {
                        <ListItemText
                          style={{ margin: "0", overflow: "ellipsis" }}
                          disableTypography
                          primary={<Typography className={globalClasses.smallFont}>{option["orderName"]}{option["description"] ? ", " + option["description"] : ""}</Typography>}
                          secondary={
                            <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)" }}>{option["material"] + "/" + option["materialRevision"] + ", Last run: " + ConvertedTimeString(option["lastRun"])}</Typography>
                          }
                        />
                      }
                    </MenuItem>
                    :
                    <></>
              }
              filterOptions={(options) => options}
              options={
                filteredOrders
              }
              renderInput={(params) => (
                <TextField {...params} 
                error={GetEmpty(searchValue) && !GetViewMode()} 
                helperText={GetViewMode() ? "" : GetEmpty(searchValue) ? "Missing test order" : ""} 
                FormHelperTextProps={{
                  className: globalClasses.helperText
                }}
                onChange={(e) => {
                  handleTestOrderSearch(e.target.value);
                }}
                  id="outlined-basic" variant="outlined" onFocus={() => {
                    if (submitTestCaseSearch == "") {
                      handleTestOrderSearch("");
                    }
                  }} 
                  onBlur={() => {
                    handleTestOrderSearch("");
                    setTestCaseSearch("");
                  }}
                  InputLabelProps={{ shrink: true }} />
              )}
            />
          </div>

          <div className={classes.dvDiv} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            {
              showConfigRadio ?
                <div className={classes.dvDiv} style={{ marginLeft: "0px" }}>
                  <RadioGroup aria-label="position" name="position" value={pdmCADValue} onChange={handlePDMCadValue}>
                    <FormControlLabel
                      style={{ marginLeft: 0 }}
                      value="useLatestPDM"
                      control={
                        GetViewMode() ?
                          <CustomRadioDisabled style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />
                          :
                          <CustomRadio style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />}
                      label="Use latest"
                      labelPlacement="start"
                      disabled={GetViewMode()}
                    />
                    <FormControlLabel
                      style={{ marginLeft: "8px" }}
                      value="toConfigPDM"
                      control={
                        GetViewMode() ?
                          <CustomRadioDisabled style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />
                          :
                          <CustomRadio style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />}
                      label="To configuration"
                      labelPlacement="start"
                      disabled={GetViewMode()}
                    />
                  </RadioGroup>
                  {
                    // pdmCADValue == "toConfigPDM" ?
                    <>
                  <div style={{ paddingTop: "22px", paddingLeft: "8px" }}>
                  <Tooltip title={pdmCADValue === "useLatestPDM" ? latestConfig : pdmCADConfig}>
        <TextField
            disabled={pdmCADValue !== "toConfigPDM" || GetViewMode()}
            value={pdmCADValue === "useLatestPDM" ? latestConfig : pdmCADConfig}
            id="outlined-multiline-static"
            maxRows={4}
            multiline
            variant="outlined"
            style={{
                paddingBottom: "1px",
                marginLeft: "16px",
                width: "370px",
                boxSizing: "border-box",
            }}
            InputProps={{
                notched: false,
                style: {
                    overflowY: 'auto', 
                    padding: "8px", 
                    height: "calc(100% - 16px)", 
                }
            }}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: globalClasses.inputLabel,
                    shrink: "shrink"
                }
            }}
            className={clsx(classes.multilineField, {
                [classes.lockedTextField]: pdmCADValue !== "toConfigPDM",
            })}
        />
        </Tooltip>

           <IconButton disabled={GetViewMode()} style={{ marginLeft: "8px", width: 34, height: 29, borderRadius: 8 }} className={clsx(globalClasses.blueButton)} onClick={() => {
                          setAddPDMCADDialog(true);
                          setTestCaseSearch("");
                        }}>
                          <Search />
                        </IconButton>
                      </div>
                      {/* <TextField value={""} type="number" error={customLevelValue.toString() == ""} InputProps={{ classes: { input: classes.inputStyle } }} style={{ paddingTop: "42px", paddingLeft: "8px", width: "80px" }} id="outlined-basic" variant="outlined" size="small" ></TextField>
                  <FormHelperText style={{ paddingLeft: "8px", paddingTop: "42px" }} hidden={!(customLevelValue.toString() == "")} error={customLevelValue.toString() == ""} id="component-error-text">{"Custom sublevel must contain a value!"}</FormHelperText> */}
                    </>
                    // : null
                  }

                </div>
                : null
            }

            <Dialog disableRestoreFocus onClose={() => setAddPDMCADDialog(false)} open={addPDMCADDialog} fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper, scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}>
              <DialogContent style={{ padding: "12px 18px" }}>
                <div className={globalClasses.containerDiv}>
                  <Typography style={{whiteSpace: "nowrap"}}>{"Test order: "}</Typography>
                  <TextField
                  value={GetEmpty(searchValue) ? "" : searchValue["orderName"]}
                  disabled
                  onChange={(e) => {
                    setTestCaseElAnchor(e.target);
                    setTestCaseSearch(e.target.value);
                  }}
                  InputProps={{
                    classes: { input: classes.inputStyle, adornedEnd: classes.endAdornment }
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  style={{ paddingTop: "0px", paddingLeft: "8px", maxWidth: "100%" }}
                />

                  <Tooltip title={"Cancel"} TransitionComponent={({ children }) => children}>
                    <IconButton style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton)} onClick={() => {
                      setAddPDMCADDialog(false);
                      console.log("row 858");

                    }}>
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </div>

                <Typography style={{ paddingTop: "8px" }}>{filteredConfigurations.length == 0 ? " " : "Configurations"}</Typography>
                <List>
                  {filteredConfigurations.sort((conf1, conf2) => new Date(conf1["lastRun"]).valueOf() - new Date(conf2["lastRun"]).valueOf()).reverse().map((confobj, index) => {
                    return (
                      <ListItem style={{ padding: "0" }} key={confobj + index} button onClick={handleConfigClickPDMCAD(confobj)}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText
                          primary={<Typography>{confobj.name}</Typography>}
                          secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)" }}>{ConvertedTimeString(confobj.lastRun)}</Typography>}
                        ></ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </DialogContent>
            </Dialog>

          </div>
        </Paper>
      </TabPanel>

      <Dialog disableRestoreFocus onClose={() => setAddMaterialDialog(false)} open={addMaterialDialog} fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper, scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}>
        <DialogContent style={{ padding: "12px 18px" }}>
          <div className={globalClasses.containerDiv}>
            <Typography style={{ whiteSpace: "nowrap", paddingRight: "8px"}}>{"Material: "}</Typography>
            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField id="outlined-basic" InputProps={{ classes: { input: classes.inputStyle } }} value={newMaterial} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} onChange={(e) => { setNewMaterial(e.target.value); }} />
            </Tooltip>
            <Tooltip title={"Cancel"} TransitionComponent={({ children }) => children}>
              <IconButton style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton)} onClick={() => {
                setAddMaterialDialog(false);
              }}>
                <Cancel />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Confirm"} TransitionComponent={({ children }) => children}>
              <IconButton style={{ marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton)} onClick={() => {
                handleAddMaterialConfirmClick();
              }}>
                <Check />
              </IconButton>
            </Tooltip>
          </div>

        </DialogContent>
      </Dialog>

      <Dialog disableRestoreFocus onClose={() => setAddConfigurationDialog(false)} open={addConfigurationDialog} fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper, scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}>
        <DialogContent style={{ padding: "12px 18px" }}>
          <div className={globalClasses.containerDiv}>
            <Typography style={{ whiteSpace: "nowrap"}}>{"Test order: "}</Typography>
            <Autocomplete
              id="testCaseSearch3"
              size="small"
              classes={{ input: classes.inputStyle }}
              style={{ width: "100%", marginLeft: "4px", marginRight: "8px" }}
              clearOnBlur
              selectOnFocus
              onChange={(e, option) => {
                handleTestOrderSearch("");
                setTestCaseSearch("");
                setShowConfigRadio(true);
                handleSetConfiguration(option["orderName"]);
              }}
              getOptionLabel={(option) => option ? option["orderName"] : ""}
              loading={loadingBatches}
              filterOptions={(options) => options}
              renderOption={(option, index) =>
                loadingBatches ?
                  <CircularProgress></CircularProgress>
                  :
                  option
                    ?
                    <MenuItem disabled={GetViewMode()} key={"CaseSearch" + option["orderName"] + index}
                      onClick={(e) => {
                        setShowConfigRadio(true);
                        handleSetConfiguration(option["orderName"]);
                      }}>
                      {/* <Typography>{filteredName["orderName"]}</Typography> */}
                      {
                        <ListItemText
                          style={{ margin: "0", paddingLeft: "2px", overflow: "ellipsis" }}
                          disableTypography
                          primary={<Typography className={globalClasses.smallFont}>{option["orderName"]}{option["description"] ? ", " + option["description"] : ""}</Typography>}
                          secondary={
                            <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)" }}>{option["material"] + "/" + option["materialRevision"] + ", Last run: " + ConvertedTimeString(option["lastRun"])}</Typography>
                          }
                        />
                      }
                    </MenuItem>
                    :
                    <></>
              }
              options={
                filteredOrders
              }
              renderInput={(params) => (
                <TextField {...params} FormHelperTextProps={{
                  className: globalClasses.helperText
                }} onChange={(e) => {
                  handleTestOrderSearch(e.target.value);
                  setTestCaseSearch(e.target.value)
                }}
                  id="outlined-basic" variant="outlined" onFocus={() => {
                    if (submitTestCaseSearch == "") {
                      handleTestOrderSearch("");
                    }
                  }} 
                  onBlur={() => {
                    handleTestOrderSearch("");
                    setTestCaseSearch("");
                  }}
                  InputLabelProps={{ shrink: true }} />
              )}
            />

            <Tooltip title={"Cancel"} TransitionComponent={({ children }) => children}>
              <IconButton style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton)} onClick={() => {
                setAddConfigurationDialog(false);
              }}>
                <Cancel />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Confirm"} TransitionComponent={({ children }) => children}>
              <IconButton style={{ marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton)} onClick={() => {
                handleConfigurationConfirmClick();
              }}>
                <Check />
              </IconButton>
            </Tooltip>
          </div>

          <Typography style={{ paddingTop: "8px" }}>{filteredConfigurations.length == 0 ? " " : "Configurations"}</Typography>
          <List>
            {filteredConfigurations.sort((conf1, conf2) => new Date(conf1["lastRun"]).valueOf() - new Date(conf2["lastRun"]).valueOf()).reverse().map((confobj, index) => {
              return (
                <ListItem style={{ padding: "0" }} key={confobj + index} button onClick={handleConfigClick(confobj)}>
                  <ListItemIcon>
                    <Checkbox
                      style={{ color: "#0071B9" }}
                      className={classes.checkbox}
                      checked={selectedConfigurations.indexOf(confobj) !== -1}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography>{confobj.name}</Typography>}
                    secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)" }}>{ConvertedTimeString(confobj.lastRun)}</Typography>}
                  ></ListItemText>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>


      <Dialog disableRestoreFocus onClose={() => setAddComparetoDialog(false)} open={addComparetoDialog} fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper, scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}>
        <DialogContent style={{ padding: "12px 18px" }}>
          <div className={globalClasses.containerDiv}>
            <Typography style={{whiteSpace: "nowrap"}}>{"Test order: "}</Typography>
            <Autocomplete
              id="testCaseSearch4"
              size="small"
              classes={{ input: classes.inputStyle }}
              style={{ width: "450px", marginLeft: "4px", marginRight: "8px" }}
              clearOnBlur
              selectOnFocus
              onChange={(e, option) => {
                handleTestOrderSearch("");
                setTestCaseSearch("");
                setShowConfigRadio(true);
                handleSetConfiguration(option["orderName"]);
              }}
              getOptionLabel={(option) => option ? option["orderName"] : ""}
              loading={loadingBatches}
              filterOptions={(options) => options}
              renderOption={(option, index) =>
                loadingBatches ?
                  <CircularProgress></CircularProgress>
                  :
                  option
                    ?
                    <MenuItem disabled={GetViewMode()} key={"CaseSearch" + option["orderName"] + index}
                      onClick={(e) => {
                        setShowConfigRadio(true);
                        handleSetConfiguration(option["orderName"]);
                      }}>
                      {/* <Typography>{filteredName["orderName"]}</Typography> */}
                      {
                        <ListItemText
                          style={{ margin: "0", paddingLeft: "2px", overflow: "ellipsis" }}
                          disableTypography
                          primary={<Typography className={globalClasses.smallFont}>{option["orderName"]}{option["description"] ? ", " + option["description"] : ""}</Typography>}
                          secondary={
                            <Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)" }}>{option["material"] + "/" + option["materialRevision"] + ", Last run: " + ConvertedTimeString(option["lastRun"])}</Typography>
                          }
                        />
                      }
                    </MenuItem>
                    :
                    <></>
              }
              options={
                filteredOrders
              }
              renderInput={(params) => (
                <TextField {...params} FormHelperTextProps={{
                  className: globalClasses.helperText
                }} onChange={(e) => {
                  handleTestOrderSearch(e.target.value);
                  setTestCaseSearch(e.target.value)
                }}
                  id="outlined-basic" variant="outlined" onFocus={() => {
                    if (submitTestCaseSearch == "") {
                      handleTestOrderSearch("");
                    }
                  }} 
                  onBlur={() => {
                    handleTestOrderSearch("");
                    setTestCaseSearch("");
                  }}
                  InputLabelProps={{ shrink: true }} />
              )}
            />

            <Tooltip title={"Cancel"} TransitionComponent={({ children }) => children}>
              <IconButton style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton)} onClick={() => {
                setAddComparetoDialog(false);
              }}>
                <Cancel />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Confirm"} TransitionComponent={({ children }) => children}>
              <IconButton style={{ marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton)} onClick={() => {
                handleComparetoConfirmClick();
              }}>
                <Check />
              </IconButton>
            </Tooltip>
          </div>

          <Typography style={{ paddingTop: "8px" }}>{filteredConfigurations.length == 0 ? " " : "Configurations"}</Typography>
          <List>
            {filteredConfigurations.map((confobj, index) => {
              return (
                <ListItem style={{ padding: "0" }} key={confobj + index} button onClick={handleComparetoClick(confobj)}>
                  <ListItemIcon>
                    <Checkbox
                      style={{ color: "#0071B9" }}
                      className={classes.checkbox}
                      checked={selectedComparetos.indexOf(confobj) !== -1}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography>{confobj.name}</Typography>}
                    secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)" }}>{ConvertedTimeString(confobj.lastRun)}</Typography>}
                  ></ListItemText>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}