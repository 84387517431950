import { makeStyles } from '@material-ui/core/styles';

export const GlobalUseStyles = makeStyles((theme) => ({
  smallFont:{
    fontSize: "1rem"
  },
  blueColor: {
    color: "#0071B9",
  },
  blueIcon: {
    backgroundColor: "#0071B9",
    color: "#fff",
  },
  grayColor: {
    backgroundColor: "#ccc",
    color: "#ccc" 
  },
  helperText: {
    position: "absolute",
    bottom: "-18px",
    fontSize: "0.66rem",
    overflow: "visible",
    whiteSpace: "nowrap",
    marginLeft: "0px"
  },
  checkbox: {
    "&:hover": {
      backgroundColor: "rgba(0.15, 0.15, 0.7, 0.05)" //"#4D9CCE"
    },
    "&.Mui-checked:hover": {
      backgroundColor: "rgba(0.15, 0.15, 0.7, 0.05)", // "#80b8dc"
    }
  },
  disabledButton: {
    backgroundColor: "#222222",
    color: "#222222",
    "&.Mui-disabled": {
      backgroundColor: "#ccc" // "#80b8dc"
    }
  },
  disabledColor: {
    color: "#222222"
  },
  blueButton: {
    backgroundColor: "#0071B9",
    color: "#fff",
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#009dff',
        borderColor: '#009d00',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#4dbbff',
        borderColor: '#4dbb00',
    }
  },
  redButton: {
      backgroundColor: "Darkred",
      color: "#fff",
      fontWeight: 'bold',
      '&:hover': {
          backgroundColor: 'Red',
          borderColor: '#009d00',
          boxShadow: 'none',
      },
      '&:active': {
          boxShadow: 'none',
          backgroundColor: '#4dbbff',
          borderColor: '#4dbb00',
      },
  },
  redButtonAlt: {
    color: "Darkred",
    fontWeight: 'bold',
    '&:hover': {
        color: 'Red',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#ff444411',
        borderColor: '#bb4d00',
    },
},
  whiteIcon: {
    color: "#ffffff",
  },
  successIcon: {
    arialabel: "Success",
    color: "#4BB543"
  },
  errorIcon: {
      arialabel: "Error",
      color: "#ff0033"
  },
  inProgressIcon: {
      arialabel: "In Progress",
      color: "#5A93D7"
  },
  NotRunIcon: {
      arialabel: "Not run",
      color: "gray"
  },
  cancelledIcon: {
      arialabel: "Cancelled",
      color: "#A9A9A9"
  },
  CompletedLabel: {
      color: "#88C167"
  },
  JobFailedLabel: {
      color: "#EF1C1B"
  },
  CancelledLabel: {
      color: "lightcoral"
  },
  mainTable: {
    tableLayout: "fixed",
    padding: "0 14px !important"
  },
  titleRow: {
    flexDirection: "column"
  },
  titleCell: {
      padding: "4px 16px",
      width: "20%"
  },
  customTableCell: {
    padding: "0px 16px",
    width: "20%"
  },
  customButton: {
    padding: "0"
  },
  dataRow: {
      flexDirection: "column",
      height: "12px",
      minHeight: "12px"
  },
  textBar: {
    display: "flex",
    flex: 1,
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "20px",
    paddingBottom: "18px"
},
createTestButtonDiv: {
  flex: "1",
  paddingLeft: "8px",
  paddingBottom: "8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: 'flex-end',
},
inputLabel: {
  "&.shrink": {
    transform: 'translate(12px, -16px) scale(0.75)',
  },
},
 addBorder: {
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "2px",

 },
 errorBorder: {
  borderColor: "#f44336",
  borderStyle: "solid",
  borderWidth: "1px",
  borderRadius: "2px",

},
 containerDiv: {
  justifyContent: "start",
  alignItems: "center",
  alignContent: "center",
  flexDirection: "row",
  display: "flex",
 },
 blueButtonGlow: {
  animation: "$blueGlow 1300ms infinite"
  },
  "@keyframes blueGlow": {
    "0%": {
      backgroundColor: "#0071B9",
      boxShadow: "0 0 0px #000"
    },
    "50%": {
      backgroundColor: "#0091D9",
      boxShadow: "0 0 3px #004169"
    },
    "100%": {
      backgroundColor: "#0071B9",
      boxShadow: "0 0 0px #000"
    }
  },
}));