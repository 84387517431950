export function successSnackbarOptions() {
    return {
        position: 'bottom-right',
        style: {
            color: "#ffffff",
            backgroundColor: "#318500",
            height: "48px"
        }
    };
}

export function warningSnackbarOptions() {
    return {
        position: 'bottom-right',
        style: {
            color: "#ffffff",
            backgroundColor: "#F2AF17",
            height: "48px"
        }
    };
}

export function errorSnackbarOptions() {
    return {
        position: 'bottom-right',
        style: {
            color: "#ffffff",
            backgroundColor: "#c71f25",
            height: "48px"
        }
    };
}

