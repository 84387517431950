import './App.css';
import React, { useEffect, useRef, useState } from 'react'

import Dashboard from './components/Dashboard';
import { Button, createTheme, ThemeProvider, Typography } from '@material-ui/core';
import SnackbarProvider from 'react-simple-snackbar';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth/authConfig";
import { useIsAuthenticated } from "@azure/msal-react";
import { useSelector, useStore } from 'react-redux'
import store from 'store/store';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { setAccessToken, setUser } from 'store/session';
import PDMSignIn from 'components/PDMSignIn';


const theme = createTheme ({
  typography: {
    fontFamily: ["Mulish", 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'].join(',')
  }
});

// function handleLogin(instance, accounts) {
//   instance.handleRedirectPromise()
//   .then((tokenResponse) => {
//       console.log("TOKENRESPONSE: ", tokenResponse);
//       let accountObj = null;
//       if (tokenResponse !== null) {
//           accountObj = tokenResponse.account;
//           const id_token = tokenResponse.idToken;
//           const access_token = tokenResponse.accessToken;
//           store.dispatch({type: 'setAccessToken', payload: access_token});
//           console.log('id_token', id_token);
//           console.log('access_token', access_token);
//       }
//   })
//   .catch(error => {
//       console.error(error);
//   });
//   instance.loginRedirect(loginRequest);

//   // .catch(e => {
//   //     console.error(e);
//   // }).then((loginResponse) => {
//   //     console.log("LOGIN: ", loginResponse);
      
//       //setToken(loginResponse["accessToken"]);
//       //dispatch({type: 'setAccessToken', payload: loginResponse["accessToken"]});
//       //RequestAccessToken(instance, setToken, accounts);
//   // });
// }
var consoleMaterialsResult = "";
export function FConsoleMaterials() {
  return consoleMaterialsResult;
}
function App() {
  const isAuthenticated = useIsAuthenticated();
  const [pdmUsername, setPdmUsername] = useState("");
  const token = store.getState();
  const [loggedIn, setLoggedIn] = useState(false);
  const { instance, accounts, inProgress } = useMsal();

  const handleLogin = () => {      

    if(!isAuthenticated && inProgress === InteractionStatus.None)
    {
      instance.loginRedirect(loginRequest);
    }
    else if(isAuthenticated && inProgress === InteractionStatus.None)
    {              
        //sessionStorage.clear();
        // Test PDM call to verify account?
        let tmpUsername = sessionStorage.getItem("pdmUsername");
        let tmpPW = sessionStorage.getItem("pdmPassword");
        // Test PDM query and if correct, setPdmUsername
        if (tmpUsername == null){
          setPdmUsername("");
        }
        else {
          setPdmUsername(tmpUsername);  
        }

         //consoleMaterials();                   
    }
    //  instance.handleRedirectPromise()
    // .then((tokenResponse) => {
    //     console.log("TOKENRESPONSE: ", tokenResponse);
    //     let accountObj = null;
    //     if (tokenResponse !== null) {
    //         accountObj = tokenResponse.account;
    //         const id_token = tokenResponse.idToken;
    //         const access_token = tokenResponse.accessToken;
    //         store.dispatch({type: 'setAccessToken', payload: access_token});
    //         console.log('id_token', id_token);
    //         console.log('access_token', access_token);
    //         setLoggedIn(true);
    //     }
    // })
    // .catch(error => {
    //     console.error(error);
    // });
  }
            
  const callback = instance.addEventCallback(message => {
    //console.log("LOGIN CALLBACK: ", message);
    switch (message.eventType){
      case EventType.LOGIN_SUCCESS:
        {
          store.dispatch(setAccessToken(message.payload["accessToken"]));
          // {type: 'setAccessToken', payload: message.payload["accessToken"]}
        }
    }
  })

  // return (
  //   // <TokenContext.Provider value={{token, setToken}}>
  //     <SignInButton handleLogin={() => {handleLogin()}} />
  //   // </TokenContext.Provider>
  // );

  useEffect(() => {
      handleLogin();      
  },[inProgress]);

  if (!isAuthenticated)
  {
    return (
      // <TokenContext.Provider value={{token, setToken}}>
        //<SignInButton handleLogin={() => {handleLogin()}} />
      // </TokenContext.Provider>
      <></>
      );
  }
  else if (isAuthenticated && pdmUsername == "")
    return (
      // PDM Login
      <SnackbarProvider>
      <PDMSignIn setPdmUsername={setPdmUsername} />
      </SnackbarProvider>
    );
  else
  {        
    return (
      // <TokenContext.Provider value={{token, setToken}}>
      <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Route path="/" component={Dashboard} />
          {/* <Dashboard /> */}
        </div>
      </ThemeProvider>
      </SnackbarProvider>
      // </TokenContext.Provider>
    );
  }
  
}

export default App;
