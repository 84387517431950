import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import NewsItem from './NewsItem'

const useStyles = makeStyles((theme) => ({
	newsMainDiv: {
		flexGrow: 1,
		
		// width: "80%",
		// margin: "auto"
	},
	// paper: {
	// 	padding: theme.spacing(2),
	// 	border: 'solid',
	// 	borderColor: 'cornflowerblue',
	// 	textAlign: 'center',
	// 	width: 250,
	// 	height: 250,
	// 	color: theme.palette.text.secondary,
	// },
	newsTitle: {
		backgroundColor: '#0071b9',
		color: 'white',
		textAlign: 'left',
		padding: '0px 0px 0px 20px',
		borderRadius: "5px",
		width: "calc(100% + 24px)",
		margin: "-12px",
	},
	gridContainer: {
		backgroundColor: "white"
	}
}));

export default function NewsBanner() {
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			slidesToSlide: 2 // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		}
	};
	const classes = useStyles();
	const xsVal = 12;
	const smVal = 6;
	const mdVal = 6;

	return (
		<div className={classes.newsMainDiv}>
			<div className={classes.newsTitle}>
				<h2>LATEST DESIGN AUTOMATION NEWS</h2>
			</div>
			<Grid container spacing={3} className={classes.gridContainer}>
				<Grid item xs={xsVal} sm={smVal} md={mdVal} className={classes.gridItem}>
					<NewsItem img={"update.jpg"} date="21 January 2021" title="NEW RELEASE COMING UP SOON" text="New Release coming up on 15.6.2021. Fixes and updates to Testing and Production environments." />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal}>
					<NewsItem img={"ipsum.jpg"} date="19 January 2021" title="Lorem Ipsum" text="Why do we use it?
				It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
			"/>
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal}>
					<NewsItem img={"NewYear.jpg"} date="1 January 2021" title="HAPPY NEW YEAR!!" text="From Design Automation Team" />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal}>
					<NewsItem img={"xmas.jpg"} date="20 December 2020" title="SUPPORT DURING HOLIDAYS" text="Support between holiday period 21.12.2020 -4.1.2021 is arranged …" />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal}>
					<NewsItem img={"update2.jpg"} date="14 December 2020" title="ANNOUNCEMENT" text="We are pleased to introduce our new product. Stay tuned for more updates." />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal} className={classes.gridItem}>
					<NewsItem img={"update3.jpg"} date="10 December 2020" title="UPDATES" text="New updates coming up. More info in the future." />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal}>
					<NewsItem img={"ipsum2.jpg"} date="6 December 2020" title="Lorem Ipsum" text="Integer sed urna pellentesque, lacinia mauris in, egestas lectus. Sed quis lobortis felis, eu lobortis tellus. Donec id purus et eros accumsan gravida. Mauris laoreet nibh mauris, nec vehicula nibh placerat aliquet. Donec a velit imperdiet, tincidunt leo a, faucibus nunc. Nunc elementum posuere nulla, vitae molestie ipsum placerat sed. Fusce et tempus dui, sodales varius nibh. Nulla gravida nunc at urna tristique, ut rhoncus elit posuere. Aliquam erat volutpat. Nunc ultricies nibh non iaculis auctor. Donec nec lorem felis. Praesent rhoncus lorem non est vehicula scelerisque. Nulla molestie lacus eget orci iaculis, id bibendum eros condimentum. Pellentesque facilisis vestibulum pharetra.

				Fusce vel dignissim purus, a porttitor lectus. Suspendisse arcu orci, pharetra vel ornare non, lacinia quis dui. Donec commodo nec orci bibendum rutrum. Praesent suscipit vehicula ex et malesuada. Integer gravida lectus eget nulla rhoncus, in facilisis odio tempor. Nulla ultricies massa vel tellus dapibus accumsan sit amet eu nulla. Ut molestie nibh non mauris laoreet tristique. Nunc diam erat, sagittis et mi commodo, fringilla rhoncus ante. Pellentesque volutpat justo vitae ex vehicula, non ultricies lorem porta. Aliquam tincidunt lacinia justo ut auctor. Ut quis magna at sapien suscipit ornare vel rhoncus tellus. Cras elit nibh, consectetur vitae felis ac, malesuada rhoncus sapien. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras non ligula sapien." />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal}>
					<NewsItem img={"update4.jpg"} date="22 November 2020" title="COMING UP" text="We are pleased to introduce our new product. More info in later news." />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal} className={classes.gridItem}>
					<NewsItem img={"update5.jpg"} date="21 November 2020" title="NEW RELEASE COMING UP SOON" text="New Release coming up on 15.6.2021. Fixes and updates to Testing and Production environments." />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal}>
					<NewsItem img={"update6.jpg"} date="10 November 2020" title="RELEASE INFORMATION" text="New information about the next release is coming up in the near future." />
				</Grid>
				<Grid item xs={xsVal} sm={smVal} md={mdVal}>
					<NewsItem img={"ipsum3.jpg"} date="7 November 2020" title="Lorem Ipsum" text="Fusce vel dignissim purus, a porttitor lectus. Suspendisse arcu orci, pharetra vel ornare non, lacinia quis dui. Donec commodo nec orci bibendum rutrum. Praesent suscipit vehicula ex et malesuada. Integer gravida lectus eget nulla rhoncus, in facilisis odio tempor. Nulla ultricies massa vel tellus dapibus accumsan sit amet eu nulla. Ut molestie nibh non mauris laoreet tristique. Nunc diam erat, sagittis et mi commodo, fringilla rhoncus ante. Pellentesque volutpat justo vitae ex vehicula, non ultricies lorem porta. Aliquam tincidunt lacinia justo ut auctor. Ut quis magna at sapien suscipit ornare vel rhoncus tellus. Cras elit nibh, consectetur vitae felis ac, malesuada rhoncus sapien. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras non ligula sapien." />
				</Grid>
			</Grid>
		</div>
	)

}