import React, { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton, ListItemText, makeStyles, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import {GlobalUseStyles} from "./GlobalUseStyles.js";
import clsx from 'clsx';
import { Add, CancelOutlined, GetApp, MoreHoriz, Error, ErrorOutline, CheckCircleOutline, PlayArrow } from "@material-ui/icons";
import CustomStatusIcon from "./CustomStatusIcon.js";
import { useSnackbar } from "react-simple-snackbar";
import { successSnackbarOptions, errorSnackbarOptions, warningSnackbarOptions } from "./SnackbarOptions.js";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { deleteCompare, downloadFromS3Base64, executeCompare, getCompare, getCompares, CancelBomCompare, CancellingBomCompare, CancellingCompareConsole } from "./RestService.js";
import InfiniteScroll from "react-infinite-scroller";
import { ConvertedTimeString } from "./Common.js";
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
    customTableCell: {
        padding: "0px 16px",
        //width: "20%"
      },
      tableContainer: {
        position: "absolute", 
        top: "90px", 
        bottom: 0, 
        left: 0, 
        right: 0, 
        width:"95%",
        margin: "28px",
        overflowY: "scroll"
    }
}))

async function handleDownload(instance, compareName) {
    await downloadFromS3Base64(instance, "Compare", compareName);
}


export default function BomCompare(props) {
    const classes = useStyles();
    const globalClasses = GlobalUseStyles();
    const [showFooter, setShowFooter] = React.useState(false);
    const [compareData, setCompareData] = useState([]);
    const [showCompareData, setShowCompareData] = useState([]);
    const [compareLoading, setCompareLoading] = useState(false);
    const [comparesLoaded, setComparesLoaded] = React.useState(0);
    const [endReached, setEndReached] = React.useState(false);
    const [valLen, setValLen] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [anchorElBomCompare, setAnchorElBomCompare] = React.useState(null);
    const [selectedBomCompare, setSelectedBomCompare] = useState(null);
    const [comparesUpdatedTime, setComparesUpdatedTime] = useState(null);
    const [reRender, setReRender] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);
    const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
    const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
    const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
    const { instance, accounts, inProgress } = useMsal();
    const [lock, setLock] = useState(false);
    const history = useHistory();

    const loading = async () => {
        if (compareLoading == false)
        {
            setCompareLoading(true);

            setComparesLoaded(comparesLoaded+25);
            setReRender(!reRender);
            setCompareLoading(false);

            if (comparesLoaded > compareData.length)
            {
                setEndReached(true);
            }
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setReRender(!reRender);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setReRender(!reRender);
    };

    const handleBomCompareClose = (event) => {
        setSelectedBomCompare(null);
        setAnchorElBomCompare(null);
    };

    const handleNewCompare = (event) => {
        props.setSelectedBomCompare(null);
        history.push("/bomcompare/edit");
    };

    const handleCompareDeleteClick = (event) => {
        if (confirm("Are you sure you want to delete BOM compare " + selectedBomCompare["name"] + "?"))
        {
            var delResult = null;
            var message = "";
            setAnchorElBomCompare(null);
            const data = async () => {
                delResult = await deleteCompare(instance, selectedBomCompare["compareID"], accounts[0].username);
            }
            data().catch((error)=>{
                console.error(error);
                message = "Error: " + error.message;
            }).then(() => {
                if (message != "")
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{message}</p></div>);
                } 
                else 
                {
                    let tmpCompareData = [...compareData];
                    var newCompareData = tmpCompareData.filter((obj) => {
                        return obj["compareID"] !== selectedBomCompare["compareID"];
                    });
                    setCompareData(newCompareData);
                    if (newCompareData.length == 0)
                    {
                        setShowEmpty(true);
                    }
                    setReRender(!reRender);
                    openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><CheckCircleOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{"Compare deleted successfully."}</p></div>);
                }
            });
        }
    };

    let emptyRows = 0;

    const HandleExecuteBC = (_selectedCompare) => {
        if (confirm("Are you sure you want to run comparison " + _selectedCompare["name"] + "?"))
        {
            console.log("yes: ", _selectedCompare);

            let message = "";
            let executeData = null;
            const executeCompareCall = async () => {
                executeData = await executeCompare(instance, _selectedCompare["compareID"], _selectedCompare["configurations"], _selectedCompare["compareTo"], _selectedCompare["options"], accounts[0].username);
                if (executeData["exception"])
                {
                    message += executeData["exception"];
                }
            };

            executeCompareCall().catch((error) => { message += error.message + "\n" }).then(() => {
                console.log("EXECUTED: ", executeData);
                if (message != "") {
                openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><Error style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
                return;
                }
                else {
                openSuccessSnackbar(<div style={{ whiteSpace: "nowrap" }}><CheckCircleOutline style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"BOM Compare execute started successfully."}</p></div>,300000);
                

                let tmpBCData = [...compareData];
                let tmpShowBCData = [...showCompareData];
                tmpBCData.forEach(element => {
                    if (element["compareID"] == executeData["compareID"])
                    {
                        element["status"] = "Processing";
                        element["lastRun"] = executeData["lastRun"];
                    }
                });
                tmpShowBCData.forEach(element => {
                    if (element["compareID"] == executeData["compareID"])
                    {
                        element["status"] = "Processing";
                        element["lastRun"] = executeData["lastRun"];
                    }
                });
                tmpBCData.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
                tmpShowBCData.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
                setCompareData([...tmpBCData]);
                setShowCompareData([...tmpShowBCData]);
                history.push("/bomcompare");
                }
            });
        }
    };

    const CancelButton = async (clickedCompare) => {
        console.log("clickedCompare: ", clickedCompare);
        let answer = window.confirm("Are you sure you want to cancel the run?");
        if (answer == true)
        {
            const cancelCompareResult = await CancelBomCompare(instance, clickedCompare["compareID"], clickedCompare["configurations"], clickedCompare["compareTo"], clickedCompare["options"], accounts[0].username, clickedCompare["name"],clickedCompare["lastRun"]);
            if (cancelCompareResult["exception"])
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{cancelCompareResult["exception"]}</p></div>)
            }            
            else
            {
              openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{"Bom Compare execute stopped."}</p></div>);
              clickedCompare["status"] = "Cancelled";
              handleCancelClick(clickedCompare);
              setReRender(!props.reRender);
            }  
        }
    };

    const CancellingButton = async (clickedCompare) => {
        setLock(true);
        console.log("clickedCompare: ", clickedCompare);
        let answer = window.confirm("Are you sure you want to cancel the run?");
        if (answer == true)
        {
            const cancelCompareResult = await CancellingBomCompare(instance, clickedCompare["compareID"], clickedCompare["configurations"], clickedCompare["compareTo"], clickedCompare["options"]);
            console.log("cancelCompareResult::"+cancelCompareResult);
            if (cancelCompareResult["exception"])
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{cancelCompareResult["exception"]}</p></div>)
                setLock(false);
            }            
            else
            {
              openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{"Bom Compare execute stopped."}</p></div>);
              clickedCompare["status"] = "Cancelled";
              handleCancelClick(clickedCompare);
              setReRender(!props.reRender);
            }  
            const cancelCompareConsoleResult = await CancellingCompareConsole(instance, accounts[0].username, clickedCompare["name"],clickedCompare["lastRun"]);
            setLock(false);
            if (cancelCompareConsoleResult == "Failed") {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{"Console side failed to cancel BOM Compare."}</p></div>)
            }
        }
    };

    const handleCancelClick = (clickedCompare) => {
        let tmpCompareData = compareData;
        var batch = tmpCompareData.findIndex((obj) => {
            return obj["compareID"] == clickedCompare["compareID"];
        });
        tmpCompareData[batch]["status"] = clickedCompare["status"];
        setCompareData(tmpCompareData);
    };

    useEffect(() => {
        const data = async () => {
            let foundCompare = [];
            const allData = await getCompares(instance);
            
            if(allData && allData.length > 0) {
              allData.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
              emptyRows = rowsPerPage - Math.min(rowsPerPage, allData.length - page * rowsPerPage);
              setValLen(allData.length);
              let tmpValData = [...allData];
              tmpValData = tmpValData.slice(0, 25);
              setCompareData([...allData]);
              setShowCompareData([...tmpValData]);
            }
            else
            {
                if ("exception" in allData)
                {
                    throw {"message": allData["exception"], "status": 404};
                }
            }
            /*const newData = await getCompares(instance, accounts[0].username, "Master Structure");
            const newData2 = await getCompares(instance, accounts[0].username, "Configuration Structure");
            const newData3 = await getCompares(instance, accounts[0].username, "PDM CAD Comparison");
            let allData = [];
            allData = allData.concat(newData);
            allData = allData.concat(newData2);
            allData = allData.concat(newData3);*/
            
        };
        data().catch((error) => {
            setShowEmpty(true);
            if ("status" in error && error["status"] == 404)
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><Error style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{error.message}</p></div>);
            }
            else
            {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><Error style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{error.message}</p></div>);
            }
        });
    },[]);

    useEffect(() => {
        compareData.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
        emptyRows = rowsPerPage - Math.min(rowsPerPage, compareData.length - page * rowsPerPage);
        setValLen(compareData.length);
        let tmpValData = [...compareData];
        tmpValData = tmpValData.slice(0, comparesLoaded);
        setShowCompareData([...tmpValData]);
    },[reRender]);

    const updateIntervalCompares = async () => {
        let processingCompares = compareData.filter((b) => b["status"] == "Processing")
        let tmpCompareData = [...compareData];
                      
        const compareUpdates = processingCompares.map((b) => {
            return getCompare(instance, b["compareID"]);
        });
        await Promise.all(compareUpdates).then(data => {
            data.forEach(element => {
                var compare = tmpCompareData.findIndex((obj) => {
                    return obj["compareID"] == element["compareID"];
                });
               
                if (tmpCompareData[compare] != element)
                {
                    tmpCompareData[compare] = element;
                }
            });
            setShowCompareData(tmpCompareData);
            setCompareData(tmpCompareData);
            var date = new Date();
            setComparesUpdatedTime(date);
              
        }).catch((err) => {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle"}}>{"Error: " + err}</p></div>);
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (compareData.length > 0)
            {
                updateIntervalCompares();
            }
          }, 45000);
        
          return () => {
            clearInterval(interval);
        };
    },[compareData]);

    return (
        <>
        <div className={globalClasses.textBar} style={{paddingBottom: "0"}}>
                    <Typography variant="h5" color="textPrimary" align="left">
                        {'BOM compare'}
                    </Typography>
                    {/* <p className={classes.topBarItem}>KONE Material:</p>
                <ReactDropdown options={materials} value={defaultMaterial} placeholder="-"
            onChange={onDropdownSelect} className={clsx(classes.materialDropdown, classes.topBarItem)} /> */}
                    <div className={globalClasses.createTestButtonDiv}>
                        <Button variant="contained" className={clsx(globalClasses.blueButton)} onClick={handleNewCompare}>
                            <Add /> New BOM Comparison
                        </Button>
                    </div>
                </div>
                
                <div>
                {comparesUpdatedTime != null
                            ?
                            <Typography variant="body1" color="textSecondary" align="right" style={{fontSize: "0.75rem", marginLeft: "auto", marginRight: "7px", marginTop: "auto"}}>
                                {"Last refreshed: " + new Date(comparesUpdatedTime).toLocaleString()}
                            </Typography>
                            :
                            <></>
                            }
                </div>

                {showEmpty == true 
        ?
        <></>
        :
        <>
                <TableContainer component={Paper} className={clsx(classes.tableContainer)}>
            <InfiniteScroll
            pageStart={0}
            loadMore={compareData.length == 0 ? ()=>{} : loading}
            hasMore={(endReached && !compareLoading) ? false : (true || false)}
            loader={ <CircularProgress key={0} />}
            useWindow={false}
            >
            <Table className={globalClasses.mainTable}>
                <TableHead>
                    <TableRow key={-2} className={globalClasses.titleRow}>
                            <TableCell key={"BCExecute"} className={globalClasses.titleCell} style={{width: "4%"}}>
                                {""}
                            </TableCell>
                        <TableCell key={"DataValidation"} className={globalClasses.titleCell}>
                            {""}
                        </TableCell>
                        <TableCell key={"Status"} className={globalClasses.titleCell}>
                            {""}
                        </TableCell>
                        <TableCell key={"Type"} className={globalClasses.titleCell}>
                            {""}
                        </TableCell>
                        <TableCell key={"Edit"} className={globalClasses.titleCell}>
                            {""}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {showCompareData.map((element, index) => {
                        return(
                        <TableRow key={element["name"] + "_" + index.toString()} className={globalClasses.titleRow}>
                        {/* <TableCell>
                            <Checkbox 
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                />
                            </TableCell> */}
                        <TableCell className={classes.customTableCell}>
                                {element["status"] != "Processing"
                                ?
                                <Tooltip title={"Run BOM Comparison"}>
                                    <IconButton className={clsx(globalClasses.customButton, globalClasses.blueColor)} onClick={(e)=>{
                                        HandleExecuteBC(element);
                                    }}>
                                    <PlayArrow />
                                    </IconButton>
                                </Tooltip>
                                :
                                <></>
                                }
                            </TableCell>

                            <TableCell className={classes.customTableCell}>
                        <ListItemText
                            style={{ margin: "0"}}
                            
                            disableTypography
                            primary={<Typography className={clsx({ //currentSelection.indexOf(index3) > -1,
                                [globalClasses.smallFont]: true
                            })}>{element["name"] + ", " + element["description"]}</Typography>}
                            secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{ConvertedTimeString(element["created"])}</Typography>}
                />
                        </TableCell>
                        <TableCell className={classes.customTableCell} align="center">
                                <div>
                            <Tooltip title={element["status"]}>
                                <span>
                                <CustomStatusIcon status={element["status"]}></CustomStatusIcon>
                                </span>
                            </Tooltip>
                                </div>
                        </TableCell>
                        <TableCell className={classes.customTableCell} align="center">
                            {/* <Typography className={globalClasses.smallFont}>{element["type"]}</Typography> */}
                            <ListItemText
                            style={{ margin: "0"}}
                            
                            disableTypography
                            primary={<Typography className={clsx({ //currentSelection.indexOf(index3) > -1,
                                [globalClasses.smallFont]: true
                            })}>{element["compareType"]}</Typography>}
                            secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{element["status"] == "Not run" ? " " : ConvertedTimeString(element["lastRun"])}</Typography>}
                />
                        </TableCell>
                        <TableCell className={classes.customTableCell} align="right">
                        {
                            //element["status"] == "Processing" 
                            ((element["status"] == "Processing" || element["status"] == "InProgress") && element["status"] != "Cancelled") 
                            ?
                            <IconButton title={"Cancel"} style={{marginRight: "8px"}} className={globalClasses.customButton} onClick={(e)=>{
                                // Cancel button functionality
                                //CancelButton(element);
                                CancellingButton(element);
                                e.stopPropagation();
                            }}>
                                <CancelOutlined />
                            </IconButton>
                            : 
                            <IconButton style={{width: "32px"}} className={globalClasses.customButton}>

                            </IconButton>
                        }
                        {element["status"] == "Success"
                        ?
                        <IconButton className={globalClasses.customButton} onClick={(e)=>{
                            //props.handleSetAnchorElBatch(e);
                            e.stopPropagation();
                            setSelectedBomCompare(element);                                                        
                            if (element["name"] != null)
                              handleDownload(instance, element["name"]);
                            }}>
                            <GetApp />
                        </IconButton>
                        :
                        null
                        }
                        <IconButton className={globalClasses.customButton} onClick={(e)=>{
                            setSelectedBomCompare(element);
                            setAnchorElBomCompare(e.target);
                            e.stopPropagation();
                            }}>
                            <MoreHoriz />
                        </IconButton>
                        </TableCell>
                    </TableRow>
                    );
                    })}
                    {/* {batchesExcel} */}
                </TableBody>
                {
                    showFooter ?
                    <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={valLen}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    
                                    />
                            </TableRow>
                        </TableFooter>
                        :
                        null
                    }
            </Table>
            </InfiniteScroll>
        </TableContainer>
        
            <Menu
                id="simple-menu"
                anchorEl={anchorElBomCompare}
                getContentAnchorEl={null}
                keepMounted={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElBomCompare)}
                onClose={handleBomCompareClose}
            // PaperProps={{
            //     style: {
            //       transform: 'translateX(0) translateY(64px)',
            //     },
            //   }}
            >
                <MenuItem 
                disabled={selectedBomCompare == null ? false : selectedBomCompare["status"] == "Processing" ? true : false}
                onClick={() => {
                    props.setSelectedBomCompare(selectedBomCompare);
                    history.push("/bomcompare/edit")
                }}>Edit</MenuItem>
                <MenuItem disabled={selectedBomCompare == null ? false : selectedBomCompare["status"] == "Processing" ? true : false} onClick={handleCompareDeleteClick}>Delete</MenuItem>
            </Menu>
        </>}
        </>
    );
}