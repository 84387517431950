import * as XLSX from 'xlsx';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import UniqueID from 'uniqid';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import { IconButton, ListItemIcon, Paper, Tooltip } from '@material-ui/core';
import { AssignmentTurnedIn, CallReceived } from '@material-ui/icons';

function ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569) * 86400 * 1000)).toUTCString();
}

const maxLengthTitle = 55;
const maxLengthDescription = 65;

const useStyles = makeStyles((theme) => ({
    excelItem: {
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
    },
    listItem: {
        height: "70px",
        display: "flex",
        justifyContent: "space-between"
    },
    overflowingText: {
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    leftItem: {
        width: "calc(30%)"
    },
    centerItems: {
        width: "calc(55%)",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
    },
    rightItem: {
        width: "calc(5%)",
        margin: "auto"
    },
    // resultButton: {
    //     '&:hover': {
    //         backgroundColor: "white"
    //     },
    //     '&:active': {
    //         boxShadow: 'none',
    //         backgroundColor: 'white',
    //         borderColor: 'white',
    //     },
    // },
}));

export const TestItems = (props) => {
    const indexHandler = props.indexHandler;
    const pathHandler = props.pathHandler;
    const matName = props.materialName;

    const classes = useStyles();

    const [components, setComponents] = useState([]);

    const handleItemClick = (name, desc) => {
        //console.log(item);
        // Open new
        indexHandler(3, name, desc);
        pathHandler(["CWS", name]);
    };

    const handleResultsClick = (e, name, desc) => {
        e.stopPropagation();
        indexHandler(4, name, desc);
        pathHandler(["CWS", name, "Results"]);
    };

    // read data, loop to create the list
    let name = props.excelPath;
    let excelData;
    let dataFetched = false;
    let result = [];

    // const promise = new Promise((resolve, reject)=>{
    //     const fileReader = new FileReader()
    //     fileReader.readAsArrayBuffer()
    // });

    useEffect(() => {
        if (name == "") {
            setComponents([]);
        }
        else {
            if (!dataFetched && name != "") {
                fetch(name).then(res => res.arrayBuffer()).then(ab => {
                    const wb = XLSX.read(ab, { type: "array" });
                    const wsName = wb.SheetNames[0];
                    const ws = wb.Sheets[wsName];
                    const data = XLSX.utils.sheet_to_json(ws);
                    excelData = data;

                }).then(() => {
                    excelData.map((d, index) => {
                        result.push(
                            <Paper key={UniqueID()} onClick={()=>handleItemClick(d.Title, d.Optional)} className={classes.excelItem}>
                                <ListItem className={classes.listItem}>
                                    {d.Title.length > maxLengthTitle ?
                                        <Tooltip title={d.Title} TransitionComponent={({ children}) => children}>
                                            <ListItemText
                                                className={clsx(classes.leftItem, classes.overflowingText)}
                                                primary={
                                                    d.Title.substring(0, maxLengthTitle) + "..."
                                                }
                                                secondary={ExcelDateToJSDate(d.DateAndTime)}
                                            />
                                        </Tooltip>
                                        :
                                        <ListItemText
                                            className={clsx(classes.leftItem, classes.overflowingText)}
                                            primary={
                                                d.Title
                                            }
                                            secondary={ExcelDateToJSDate(d.DateAndTime)}
                                        />
                                    }

                                    <div className={classes.centerItems}>
                                        {d.Status != null && d.Status.toLowerCase() == "completed" ?
                                            <Tooltip title="Results" TransitionComponent={({ children}) => children}>
                                                <ListItemIcon className={clsx(classes.alignLeftItem, classes.resultButton)} onClick={(e) => { handleResultsClick(e, d.Title, d.Optional) }}>
                                                    <IconButton>
                                                        <AssignmentTurnedIn />
                                                    </IconButton>
                                                </ListItemIcon>
                                            </Tooltip>

                                            : <ListItemIcon />}
                                        {d.Optional != null && d.Optional.length > maxLengthDescription ?
                                            <Tooltip title={d.Optional} TransitionComponent={({ children}) => children}>
                                                <ListItemText
                                                    className={clsx(classes.alignLeftItem, classes.overflowingText)}
                                                    primary={
                                                        d.Optional.substring(0, maxLengthDescription) + "..."
                                                    }
                                                />
                                            </Tooltip>
                                            :
                                            <ListItemText
                                                className={clsx(classes.leftItem, classes.overflowingText)}
                                                primary={
                                                    d.Optional != null ? d.Optional : ""
                                                }
                                            />
                                        }


                                    </div>
                                    <ListItemSecondaryAction className={classes.rightItem}>
                                        {d.Status == null ? 
                                        null
                                        // <QueryBuilder edge="end" aria-label="Success" htmlColor="#f0ec29" >

                                        // </QueryBuilder>
                                        :
                                        (d.Status.toLowerCase() == "completed" ?
                                            <SuccessIcon edge="end" aria-label="Success" htmlColor="#4BB543" >

                                            </SuccessIcon>
                                            :
                                            <ErrorIcon edge="end" aria-label="Error" htmlColor="#ff0033">

                                            </ErrorIcon>)
                                          }

                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Paper>
                        )
                    });
                    dataFetched = true;
                    setComponents(result);
                });
            }
        }
    }, [props.excelPath]);

    return components;
}