export function GetStringEmpty(str) {
    if (str == null)
    {
        return true;
    }
    else if (str.trim() == "")
    {
        return true;
    }
    return false;
}

export function GetArrayEmpty(arr) {
    if (arr == null)
    {
        return true;
    }
    else if (arr.length == 0)
    {
        return true;
    }
    return false;
}

export function GetEmpty(obj) {
    if (obj == null)
    {
        return true;
    }
    else if (Array.isArray(obj))
    {
        if (obj.length == 0)
        {
            return true;
        }
    }
    else if (typeof obj === 'string' || obj instanceof String)
    {
        if (obj.trim() == "")
        {
            return true;
        }
    }
    return false;
}

export function GetEmptyValue(obj, value) {
    if (obj == null)
    {
        return true;
    }
    if (!(value.toString() in obj))
    {
        return true;
    }
    if (obj[value] == null)
    {
        return true;
    }
    return false;
}

export function CheckMaterialsFound(resultMaterials, checkMaterials) {

    if (resultMaterials.length == 0)
    {
        return (checkMaterials.join(","));
    }
    else
    {
        var result = checkMaterials.filter(item => resultMaterials.indexOf(item) == -1);
        if (result.length > 0)
        {
            return (result.join(","));
        }
    }
    return "";
}

export function ConvertedTimeString(isoTime)
{
    var date = new Date(isoTime);
    var localTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toLocaleString();
    return localTime;
}