export const msalConfig = {
    auth: {
      clientId: "7edde5f5-c5d4-42ab-9c19-efbbd7f4cda8",
      authority: "https://login.microsoftonline.com/2bb82c64-2eb1-43f7-8862-fdc1d2333b50", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      //redirectUri: "http://localhost:3000",
      redirectUri: "https://cws-dev.kone.com"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["api://35206ef2-8713-4ea5-a87b-47ce18b38bee/Task.Consume"] // "User.Read", 
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  // export const graphConfig = {
  //     graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  // };