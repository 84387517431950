import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '@material-ui/icons';
import { Checkbox, FormControlLabel } from '@material-ui/core';

// export default function BatchOptionCheckbox(props) {
//   const [checked, setChecked] = useState(false);
// }

const BatchOptionCheckbox = ({ type = 'checkbox', label, name, checked = false, onChange, className, disabled = false }) => (
    <FormControlLabel
          value="start"
          control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            name={name}
            style={{color: "#0071B9"}}
            {...className === null ? null : className={className}}
            //color="primary"
          />}
          label={label}
          labelPlacement="end"
        />
    
//   <input type={type} name={name} checked={checked} onChange={onChange} />
);

BatchOptionCheckbox.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default BatchOptionCheckbox;