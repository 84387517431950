import { CheckCircleOutline, ErrorOutline, Cached, RemoveCircleOutline, Cancel } from "@material-ui/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import {GlobalUseStyles} from "./GlobalUseStyles.js";

export default function CustomStatusIcon(props) {
    const globalClasses = GlobalUseStyles();
    const [status, setStatus] = useState(null);

    useEffect(() => {
        if (props.status === "Success") {
            setStatus(<CheckCircleOutline className={globalClasses.successIcon} />);
        }
        else if (props.status === "Failed") {
            setStatus(<ErrorOutline className={globalClasses.errorIcon} />);
        }
        else if (props.status === "InProgress" || props.status === "Processing") {
            setStatus(<Cached className={globalClasses.inProgressIcon} />);
        }
        else if (props.status === "Not run") {
            setStatus(<RemoveCircleOutline className={globalClasses.NotRunIcon} />);
        }
        else if (props.status === "Cancelled") {
            setStatus(<Cancel className={globalClasses.cancelledIcon} />);
        }
        else {
            return null;
        }
    },[props.status]);
  

    return(status);
}
