import React, { Component } from "react";
import ReactDOM from "react-dom";
import ColumnResizer from "column-resizer";
import UniqueID from 'uniqid';

class ReactTable extends Component {
  constructor(props) {
    super(props);
    this.tableSelector = "#somethingUnique";
  }

  render() {
    return (
      <div>
        <table id="somethingUnique" cellSpacing="0">
        <thead key={UniqueID()}>
              <tr>
                {this.props.header}
              </tr>
            </thead>
            <tbody key={UniqueID()}>
              {this.props.body}
            </tbody>
        </table>
      </div>
    );
  }
}

export default ReactTable;