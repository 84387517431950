import { AppBar, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, Input, ListItemText, makeStyles, MenuItem, Paper, Popper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Tooltip, Typography, withStyles, List, ListItem } from "@material-ui/core";
import { CheckBox, CheckCircleOutline, Error, PlayArrow, Refresh, Remove, SaveAlt, Publish } from "@material-ui/icons";
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { GlobalUseStyles } from "./GlobalUseStyles";
import { useSnackbar } from "react-simple-snackbar";
import { successSnackbarOptions, errorSnackbarOptions, warningSnackbarOptions } from "./SnackbarOptions.js";
import { useMsal } from "@azure/msal-react";
import { createValidation, executeValidation, fetchValidationId, materialRevisionItemsExists, updateValidation, fetchPDMDescription } from "./RestService";
import { getSavedMaterialRevisions } from "./DataValidation";
import { CheckMaterialsFound, GetArrayEmpty, GetEmpty, GetStringEmpty } from './Common'
import config from "../config";
import Add from "@material-ui/icons/Add";
import * as XLSX from 'xlsx';

let savedMaterials = [];
let savedRevisions = [];

const PopperMy = function (props) {
  return (<Popper {...props} placement='bottom-start' modifiers={{
    flip: {
      enabled: false,
    }
  }}
    popperOptions={{
      placement: 'bottom',
    }}
  />)
}

const CustomRadio = withStyles({
  root: {
    color: "#0071B9",
    '&$checked': {
      color: "#0071B9",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CustomRadioDisabled = withStyles({
  root: {
    color: "#ccc",
    '&$checked': {
      color: "#ccc",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root": {
      flexWrap: "nowrap",
      overflowX: "hidden"  // or "hidden"
    }
  },
  dvDiv: {
    display: "flex",
    flex: 1,
    //height: "32px",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "row",
    paddingTop: "0px",
    marginTop: "0px",
    marginLeft: theme.spacing(4),
  },
  inputStyle: {
    height: "8px",
    //   "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &[type=number]": {
    //     "-webkit-appearance": "none",
    //     display: "none",
    //     height: "29px",
    // }
  },
  generateIdButton: {
    //marginRight: theme.spacing(2),
    marginLeft: "4px", //theme.spacing(1),
    width: "44px",
    minWidth: "44px",
    maxWidth: "44px",
    height: "29px",
    maxHeight: "29px",
    minHeight: "29px"
  },
  testInfoItem: {
    display: "flex",
    justifyContent: "center",
    marginRight: "4px"
  },
  textField: {
    minHeight: "24px",
    maxHeight: "32px",
    marginRight: "32px"
  },
  testInfoDesc: {
    flex: 1,
    paddingRight: theme.spacing(4)
  },
  testInfoName: {
    flex: 1,
    marginRight: theme.spacing(2)
  },
  testInfoID: {
    marginRight: 0, //theme.spacing(1),
    marginLeft: theme.spacing(2),
    //flex: 1,
    width: "190px",
    backgroundColor: "#F0F0F0",
  },
}))


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div

      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function EditDataValidation(props) {
  const classes = useStyles();
  const globalClasses = GlobalUseStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [allSubLevelsCheck, setAllSubLevelsCheck] = React.useState(false);
  const [levelsValue, setLevelsValue] = React.useState("allLevels");
  const [kmListMaterials, setKMListMaterials] = useState("");
  const [dataValidationName, setDataValidationName] = useState(props.selectedDataValidation == null ? "" : props.selectedDataValidation["name"]);
  const [dataValidationDesc, setDataValidationDesc] = useState(props.selectedDataValidation == null ? "" : props.selectedDataValidation["description"]);
  const [dataValidationPDMDesc, setDataValidationPDMDesc] = useState(props.selectedDataValidation == null ? "" : props.selectedDataValidation["pdmDescription"]); const [dvalertstatus, setdvalertstatus] = useState(props.selectedDataValidation == null ? "" : props.selectedDataValidation["dvalertstatus"]);
  const [customLevelValue, setCustomLevelValue] = React.useState(1);
  const [koneMaterial, setKoneMaterial] = useState("");
  const [koneMaterialRev, setKoneMaterialRev] = useState("");
  const [selectedDataValidation, setSelectedDataValidation] = useState(props.selectedDataValidation == null ? null : props.selectedDataValidation);
  const [lock, setLock] = useState(false);
  const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
  const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
  const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
  const [menuHeight, setMenuHeight] = useState("36px");
  const [windowHeight, setWindowHeight] = useState(500);
  const { instance, accounts, inProgress } = useMsal();
  const history = useHistory();
  const [skipModeCurrentType, setSkipModeCurrentType] = useState("KONE material");
  const [skipModeCurrentValue, setSkipModeCurrentValue] = useState("");
  const [skipModeValues, setSkipModeValues] = useState([]);
  const [reRender3, setReRender3] = useState(false);
  const excelInput = useRef(null);
  const [excelInputFile, setExcelInputFile] = useState(null);
  const [importExcel, setImportExcel] = useState(false);
  const [exportExcel, setExportExcel] = useState(false);
  const inputRef = useRef();

  const handleKMChange = (event, newValue) => {
    savedRevisions = [];
    let matrevs = getSavedMaterialRevisions();
    if (Array.isArray(matrevs)) {
      matrevs.forEach(element => {
        if (element === undefined) {
          savedRevisions.push("");
        }
        else {
          let matrev = element.split("/");
          if (matrev[0] == newValue) {
            if (savedRevisions.includes(matrev[1]) == false) {
              savedRevisions.push(matrev[1]);
            }
          }
        }

      });
    }
    savedRevisions = savedRevisions.map((e) => e || "");
    console.log(savedRevisions);
  }

  const convertToUppercase = (e) => {
    e.preventDefault();
    setKoneMaterial(e.target.value.toUpperCase());
  };

  const convertKMToUppercase = (e) => {
    e.preventDefault();
    setKMListMaterials(e.target.value.toUpperCase());
  };

  const convertVersionToUppercase = (e) => {
    e.preventDefault();
    setKoneMaterialRev(e.target.value.toUpperCase());
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleLevelsValueChange = (event) => {
    setLevelsValue(event.target.value);
    setCustomLevelValue(1);
    if (event.target.value == "allLevels") {
      setCustomLevelValue(-1);
    }
  };

  const CallFetchValidationId = async () => {  
    let message = "";                              
    fetchValidationId(instance).catch((error) => {message += error.message+"\n"}).then((response) => {
      if (message != "")
      {
        openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000)
      }
      else
      {
        setDataValidationName(response);
      }
    });
  }; 

  const handleRunClick = (event, type) => {
    setLock(true);
    let message = "";
    let optionsObj = {};
    let materials = [];
    let checkMaterials = [];
    let trimmedMaterials = [];

    if (type == "Structure") {
      materials.push(koneMaterial + "/" + koneMaterialRev);
      checkMaterials = [koneMaterial + "/" + koneMaterialRev];
      if (levelsValue == "customLevel")
        optionsObj["sublevel"] = customLevelValue.toString();
      else
        optionsObj["sublevel"] = "allLevels";
    }
    else {
      optionsObj["sublevel"] = 0;

      const kmListMats=[];
      var list="";
      for(var i=0;i<parseInt(skipModeValues.length.toString());i++)
      {
	kmListMats.push(skipModeValues[i].value);
	list+=skipModeValues[i].value+"\n";
      }
      list=list.substring(0, list.length-1);
      materials[0]=list;
      checkMaterials = kmListMats;
    }
    optionsObj["environment"] = config.environment;
    console.log("materials: ", materials);

    let checkStr = "";
    if (checkMaterials.length == 1) {
      checkStr = checkMaterials[0].trim();
    }
    else {
      trimmedMaterials = checkMaterials.map((mat) => mat.trim());
      checkStr = trimmedMaterials.join("||");
    }

    let materialsFound = [];
    const checkMaterialsCall = async () => {
      materialsFound = await materialRevisionItemsExists(instance, checkStr, accounts[0].username);
    };

    checkMaterialsCall().catch((error) => { message += error.message }).then(() => {
      if (message != "") {
        openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error during material check: " + message}</p></div>,300000)
        setLock(false);
      }
      else {
        var missingMats = CheckMaterialsFound(materialsFound, checkMaterials);
        if (missingMats != "") {
          openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not find the following materials: " + missingMats}</p></div>,300000)
          setLock(false);
        }
        else {
          // Materials exist, proceed to create/update and run
          let validationData = null;
          let executeData = null;

          if (selectedDataValidation == null) {

            const createValidationCall = async () => {
              validationData = await createValidation(instance, type, dataValidationName, dataValidationDesc, materials, optionsObj, dataValidationPDMDesc,dvalertstatus);
            };
            createValidationCall().catch((error) => { message += error.message + "\n" }).then(() => {
              console.log("CREATED: ", validationData);
              setSelectedDataValidation(validationData);
              const executeValidationCall = async () => {
                executeData = await executeValidation(instance, validationData["validationID"], materials, optionsObj, accounts[0].username);
              };

              executeValidationCall().catch((error) => { message += error.message + "\n" }).then(() => {
                console.log("EXECUTED: ", executeData);
                if (message != "") {
                  openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><Error style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
                  setLock(false);
                }
                else {
                  openSuccessSnackbar(<div style={{ whiteSpace: "nowrap" }}><CheckCircleOutline style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Validation execute started successfully."}</p></div>,300000);
                  setLock(false);
                  history.push("/datavalidation");
                }
              });

            });
          }
          else {
            const updateValidationCall = async () => {
              validationData = await updateValidation(instance, selectedDataValidation["validationID"], dataValidationName, dataValidationDesc, materials, optionsObj, dataValidationPDMDesc, dvalertstatus);
            };
            updateValidationCall().catch((error) => { message += error.message + "\n" }).then(() => {
              console.log("UPDATED: ", validationData);
              setSelectedDataValidation(validationData);
              const executeValidationCall = async () => {
                executeData = await executeValidation(instance, validationData["validationID"], materials, optionsObj, accounts[0].username);
              };

              executeValidationCall().catch((error) => { message += error.message + "\n" }).then(() => {
                console.log("EXECUTED: ", executeData);
                if (message != "") {
                  openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><Error style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
                  setLock(false);
                }
                else {
                  openSuccessSnackbar(<div style={{ whiteSpace: "nowrap" }}><CheckCircleOutline style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Validation execute started successfully."}</p></div>,300000);
                  setLock(false);
                  history.push("/datavalidation");
                }
              });

            });
          }


        }

      }
    })
  };

  const GetRunDisabled = () => {
    let returnValue = (GetStringEmpty(dataValidationName) || GetStringEmpty(dataValidationDesc));
    // Structure
    if (tabValue == 0) {
      returnValue = returnValue || GetStringEmpty(koneMaterial) || GetStringEmpty(koneMaterialRev) || GetStringEmpty(dataValidationPDMDesc);
    }
    // KM List
    else if (tabValue == 1) {
      //returnValue = returnValue || GetEmpty(kmListMaterials);
      returnValue = returnValue || GetEmpty(skipModeValues);
    }
    return returnValue || lock;
  };

  const GetSaveDisabled = () => {
    let returnValue = (GetStringEmpty(dataValidationName) || GetStringEmpty(dataValidationDesc) || (skipModeValues.length==0));
    return returnValue;
  };

  // Return true if View mode is on
  const GetViewMode = () => {
    // let returnValue = selectedDataValidation != null;

    // return returnValue;
    return false;
  };

  const getPDMDescription = () => {
    
    let message = "";
    let pdmDescription = "";             
    
    if(koneMaterial && koneMaterialRev) {
      const PDMFetchDescription = async () => {  
        pdmDescription = '';      
        if(koneMaterial && koneMaterialRev)                                                     
          pdmDescription = await fetchPDMDescription(instance,accounts[0].username,koneMaterial,koneMaterialRev);
          console.log(pdmDescription);
      }
      PDMFetchDescription().catch((error)=>{message += error.message+"\n"}).then(() => {
        if (message != "")
        {
          openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not retrieve PDM description from PDM"}</p></div>,300000)
          console.log("Fetching material data from PDM failed: ", message);
        }
        else if (pdmDescription != null && pdmDescription.length != null && pdmDescription.length == 0)
        {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not find PDM description " + koneMaterial + "/" + koneMaterialRev + " from PDM"}</p></div>,300000)
        }
        else if (pdmDescription != null && pdmDescription.length != null && pdmDescription.length > 0)
        {
          setDataValidationPDMDesc(pdmDescription);
        }
      });
    }
  }

  const addKMList = () => {
    return (
      <Paper className={globalClasses.addBorder} style={{maxHeight: 200, maxWidth: "400px", overflowY: 'auto'}}>
      <List style={{padding: "0"}}>
          <ListItem style={{padding: "0"}}>
          <div
          style={{minWidth: "135px", paddingLeft: "4px", paddingTop: "3px", paddingBottom: "3px", fontSize: "18px"}}
          className={globalClasses.addBorder}>
            KONE material
          </div>
          <TextField
          style={{paddingLeft: "4px", minWidth: "220px", maxWidth: "220px", textOverflow: "clip"}}
          className={globalClasses.addBorder}
          onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                  let tmpVal = skipModeCurrentValue.trim().replace(/ /g, '').toUpperCase();
                  if (tmpVal != "")
                  {
                      if (tmpVal.length > 100)
                      {
                          openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Maximum length of entry is 100 characters"}</p></div>,300000)
                      }
                      else
                      {
                          const foundVal = skipModeValues.find((val) => val.type == skipModeCurrentType && val.value == tmpVal)
                          console.log("FOUNDVAL: ", foundVal);
                          if (foundVal == undefined)
                          {
                              if (skipModeCurrentType == "KONE material")
                              {
                                  let tmpSplit = tmpVal.split("/");
                                  if (tmpSplit.length == 1)
                                  {
                                      tmpVal = tmpVal.substring(0, 98);
                                      tmpVal = tmpVal + "/*";
                                  }
                              }
                              let tmpSkipModeValues = [...skipModeValues]
                              tmpSkipModeValues.push({"value": tmpVal})
                              setSkipModeValues(tmpSkipModeValues);
                              //props.setSkipModeValues(tmpSkipModeValues);
                      }
                      }
                      
                  }
                ev.preventDefault();
              }
            }}
          onChange={(e) => {
              setSkipModeCurrentValue(e.target.value.toString());
          }}
          ></TextField>

              <IconButton edge="end" aria-label="add" style={{padding: 0, flexDirection: "column", marginLeft: "auto", marginRight: "8px"}}
              onClick={()=> {
                  let tmpVal = skipModeCurrentValue.trim().replace(/ /g, '').toUpperCase();
                  if (tmpVal != "")
                  {
                      if (tmpVal.length > 100)
                      {
                          openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Maximum length of entry is 100 characters"}</p></div>,300000)
                      }
                      else
                      {
                          const foundVal = skipModeValues.find((val) => val.type == skipModeCurrentType && val.value == tmpVal)
                          console.log("FOUNDVAL: ", foundVal);
                          if (foundVal == undefined)
                          {
                              if (skipModeCurrentType == "KONE material")
                              {
                                  let tmpSplit = tmpVal.split("/");
                                  if (tmpSplit.length == 1)
                                  {
                                      tmpVal = tmpVal + "/*";
                                  }
                              }
                              let tmpSkipModeValues = [...skipModeValues]
                              tmpSkipModeValues.push({"value": tmpVal})
                              setSkipModeValues(tmpSkipModeValues);
                              //props.setSkipModeValues(tmpSkipModeValues);
                          }
                      }
                      
                  }
              }}
              >
                <Add />
              </IconButton>
          </ListItem>
          {skipModeValues.map((val, index) => {
              return(
                  <ListItem key={val["type"]+index.toString()} style={{padding: "0"}}>
          <div
              style={{minWidth: "135px", paddingLeft: "4px", paddingTop: "3px", paddingBottom: "3px", fontSize: "18px"}}
              className={globalClasses.addBorder}>
                KONE material
          </div>
          <TextField
              style={{paddingLeft: "4px", minWidth: "220px", maxWidth: "220px", textOverflow: "clip"}}
              className={globalClasses.addBorder}
              onChange={(e) => {
                  val["value"] = e.target.value.toString().replace(/ /g, '');
                  setReRender3(!reRender3);
              }}
              value={val["value"].replace(/ /g, '').toUpperCase()}
              //value={val}
              ></TextField>

              <IconButton edge="end" aria-label="add" style={{padding: 0, flexDirection: "column", marginLeft: "auto", marginRight: "8px"}}
              className={globalClasses.redButtonAlt}
              onClick={()=> {
                  const foundVal = skipModeValues.find((tmpVal) => val.type == tmpVal.type && val.value == tmpVal.value)
                  console.log("FOUNDVAL: ", foundVal);
                  if (foundVal != undefined)
                  {
                      let tmpSkipModeValues = [...skipModeValues]
                      //const index = tmpSkipModeValues.findIndex(item => item["type"] == foundVal["type"] && item["value"] == foundVal["value"]);
                      console.log("index: ", index);
                      if (index !== -1)
                      {
                          tmpSkipModeValues.splice(index, 1);
                          setSkipModeValues(tmpSkipModeValues);
                          //props.setSkipModeValues(tmpSkipModeValues);
                      }   
                  }
              }}
              >
                <Remove />
              </IconButton>
          </ListItem>
              );
          })}
      </List>
      </Paper>
  );
  }

  const handeExcelInputFileChange = async (e) => {
var file=e.target.files[0];
setExcelInputFile(e.target.files[0]);

const data = await file.arrayBuffer();
    const reader = new FileReader();

const workbook = XLSX.read(data);
const worksheet = workbook.Sheets[workbook.SheetNames[0]];
const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
});

let tmpSkipModeValues = []

var tmpVal=jsonData.toString().split(",");
var leng=tmpVal.length;

for(var i=0;i<parseInt(leng);i++)
{
    if(tmpVal[i]) {
      let tempjsonstring = tmpVal[i].toString();
      tempjsonstring = tempjsonstring.replace(/\s/g,'');
      tmpSkipModeValues.push({"value": tempjsonstring});
      // tmpSkipModeValues.push({"value": tmpVal[i].toString().replace(/ /g, '')})
    }
}
setSkipModeValues(tmpSkipModeValues);
  }

  const handleExportClick = () => {

  var ws = XLSX.utils.json_to_sheet(skipModeValues, {skipHeader: 1});

  /* add to workbook */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

  /* generate an XLSX file */
  XLSX.writeFile(wb, dataValidationName + ".xlsx");
    //setExportExcel(!exportExcel);
  }

const handleImportClick = () => {
    excelInput.current.click();
  }

  useEffect(() => {

    if (savedMaterials === undefined || savedMaterials.length == 0) {
      let matrevs = getSavedMaterialRevisions();
      if (Array.isArray(matrevs)) {
        matrevs.forEach(element => {
          if (element === undefined) {
            savedMaterials.push("");
          }
          else {
            let matrev = element.split("/");
            if (savedMaterials.includes(matrev[0]) == false) {
              savedMaterials.push(matrev[0]);
            }
          }

        });
        console.log(savedMaterials);
      }
    }
    if (selectedDataValidation != null) {
      console.log("selectedDataValidation:", selectedDataValidation);
      if (selectedDataValidation["validationType"] == "Structure") {
        setTabValue(0);
        if (selectedDataValidation["materials"] != null) {
          let tempKM = selectedDataValidation["materials"][0].split("/");
          setKoneMaterial(tempKM[0]);
          setKoneMaterialRev(tempKM[1]);
        }

        if (selectedDataValidation["options"] != null) {
          let selectedConfigOptions = selectedDataValidation["options"];
          setCustomLevelValue(selectedConfigOptions["sublevel"][0]);
          console.log(customLevelValue);
          if (selectedConfigOptions["sublevel"][0] == "-1") {
            setLevelsValue("allLevels");
          }
          else if (selectedConfigOptions["sublevel"][0] == "allLevels") {
            setLevelsValue("allLevels");
          }
          else {
            setLevelsValue("customLevel");
          }
          console.log(levelsValue);
        }
      }
      else if (selectedDataValidation["validationType"] == "KM List") {
        setTabValue(1);
        //setKMListMaterials(selectedDataValidation["materials"].join("\n"));
	var root=selectedDataValidation["materials"].toString().split("\n").toString().split(",");
	var len=root.length;
        var tmpVal=[];
        for(var i=0;i<parseInt(len.toString());i++)
        {
          tmpVal.push({"value": root[i]});
        }
        setSkipModeValues(tmpVal);
        //console.log(kmListMaterials);
      }
    }

    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setMenuHeight((window.innerHeight - window.innerHeight * 0.5 < 285 && window.innerHeight - window.innerHeight * 0.5 > 225)
        ?
        (window.innerHeight * 0.35).toString() + "px"
        :
        window.innerHeight - window.innerHeight * 0.5 > 225
          ?
          (window.innerHeight * 0.5).toString() + "px"
          :
          "36px");
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Paper>

        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary"
          textColor="primary" >
          <Tab label="Structure" disabled={selectedDataValidation == null ? false : selectedDataValidation["validationType"] == "Structure" ? false : true} />
          <Tab label="KM List" disabled={selectedDataValidation == null ? false : selectedDataValidation["validationType"] == "KM List" ? false : true} />
        </Tabs>
      </Paper>

      <TabPanel value={tabValue} index={0}>
        <Paper style={{ paddingBottom: "12px" }}>

          <div className={classes.dvDiv} style={{ paddingTop: "4px", paddingBottom: "0" }}>
            <Typography className={classes.testInfoItem}>Name:</Typography>
            {/* <Tooltip title={testName} TransitionComponent={({ children }) => children}>
                        <TextField id="outlined-basic" defaultValue={testName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoName)} />
                    </Tooltip> */}

            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled error={GetStringEmpty(dataValidationName)} helperText={GetStringEmpty(dataValidationName) ? "Missing data validation name" : ""} FormHelperTextProps={{
                className: globalClasses.helperText
              }} InputProps={{ classes: { input: classes.inputStyle } }} key={"structurenamefield"} id="outlined-basic1" value={dataValidationName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoID)} onChange={(e) => { setDataValidationName(e.target.value); }} />
            </Tooltip>

            <Tooltip title={"Generate ID"}>
              <span>
                <Button disabled={GetViewMode()} size="small" className={clsx(classes.generateIdButton, globalClasses.blueButton, GetViewMode() && globalClasses.disabledButton)} onClick={() => { 
                  if (dataValidationName == "")
                  {
                    CallFetchValidationId();
                  }
                }}
                >

                  <Refresh />
                </Button>
              </span>
            </Tooltip>

            <Typography className={classes.testInfoItem} style={{ paddingLeft: "14px" }}>Description:</Typography>
            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled={GetViewMode()} error={GetStringEmpty(dataValidationDesc)} helperText={GetStringEmpty(dataValidationDesc) ? "Missing data validation description" : ""} FormHelperTextProps={{
                className: globalClasses.helperText
              }} id="outlined-basic2" InputProps={{ classes: { input: classes.inputStyle } }} key={"structuredescfield"} value={dataValidationDesc} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} onChange={(e) => { setDataValidationDesc(e.target.value); }} />
            </Tooltip>
            {/* <Typography>{"Run Data Validation for structure"}</Typography> */}
            <Tooltip title={"Run Data Validation"} TransitionComponent={({ children }) => children}>
              <span>
                <IconButton disabled={GetRunDisabled() || GetViewMode()} style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton, (GetRunDisabled() || GetViewMode()) && globalClasses.disabledButton)} onClick={(event) => {
                  handleRunClick(event, "Structure");
                }}>
                  <PlayArrow />
                </IconButton>
              </span>
            </Tooltip>
          </div>

          <div className={classes.dvDiv}>
            <RadioGroup aria-label="position" name="position" value={levelsValue} onChange={handleLevelsValueChange} style={{ paddingTop: "4px" }}>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                value="allLevels"
                control={
                  GetViewMode() ?
                    <CustomRadioDisabled style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />
                    :
                    <CustomRadio style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />}
                label="All sublevels"
                labelPlacement="start"
                disabled={GetViewMode()}
              />
              <FormControlLabel
                style={{ marginLeft: "8px" }}
                value="customLevel"
                control={
                  GetViewMode() ?
                    <CustomRadioDisabled style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />
                    :
                    <CustomRadio style={{ marginRight: "8px" }} className={clsx(globalClasses.checkbox, GetViewMode() && globalClasses.grayColor, !GetViewMode() && globalClasses.blueColor)} />}
                label="To sublevel"
                labelPlacement="start"
                disabled={GetViewMode()}
              />
            </RadioGroup>
            {
              levelsValue == "customLevel" ?
                <>
                  <TextField disabled={GetViewMode()} value={customLevelValue} type="number" onChange={(e, t) => { setCustomLevelValue(e.target.value); }} error={customLevelValue.toString() == ""} InputProps={{ classes: { input: classes.inputStyle } }} style={{ paddingTop: "42px", paddingLeft: "8px", width: "80px" }} id="outlined-basic" variant="outlined" size="small" ></TextField>
                  <FormHelperText style={{ paddingLeft: "8px", paddingTop: "42px" }} hidden={!(customLevelValue.toString() == "")} error={customLevelValue.toString() == ""} id="component-error-text">{"Custom sublevel must contain a value!"}</FormHelperText>
                </>
                : null
            }

            {/* <FormControlLabel
            style={{marginLeft: 0}}
          value=""
          control={<Checkbox style={{color: "#0071B9", marginRight: "8px"}} className={globalClasses.checkbox} checked={allSubLevelsCheck} onChange={() => {setAllSubLevelsCheck(!allSubLevelsCheck);}}/>}
          label="All sublevels"
          labelPlacement="start"
        />
        <FormControlLabel
            style={{marginLeft: "8px"}}
          value=""
          control={<TextField InputProps={{ classes: { input: classes.inputStyle } }} style={{paddingLeft: "4px", width: "80px"}} id="outlined-basic" variant="outlined" size="small" ></TextField>} 
          label="To sublevel:"
          labelPlacement="start"
        /> */}

          </div>
          <div className={classes.dvDiv} style={{ height: "32px", marginTop: "6px", paddingTop: "4px", paddingBottom: "18px" }}>
            <Typography>KONE material:</Typography>
            <FormControl className={classes.root} style={{ padding: "0px", marginLeft: "4px" }} >
              {/* <InputLabel shrink={true}>Orders</InputLabel> */}

              <Autocomplete
                freeSolo
                autoSelect
                disabled={GetViewMode()}
                options={savedMaterials}
                size="small"
                value={koneMaterial}
                classes={{ input: classes.inputStyle }}
                PopperComponent={PopperMy}
                getOptionLabel={(option) => option}
                ListboxProps={{ style: { maxHeight: menuHeight } }}
                //style={{width: "100%"}}
                style={{ width: "190px" }}
                onChange={(e, obj) => {
                  setKoneMaterial(obj);
                  handleKMChange(e, obj);
                  getPDMDescription();
                }}

                onBlur={(e, obj) => {
                  getPDMDescription();
                }}

                onMouseOut={(e, obj) => {
                  getPDMDescription();
                }}

                onKeyUp={(e)=>{
                  convertToUppercase(e);
                }}

                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <ListItemText primary={option} />
                  </React.Fragment>

                )
                }
                renderInput={(params) => (
                  <TextField error={GetStringEmpty(koneMaterial)} helperText={GetStringEmpty(koneMaterial) ? "Missing material" : ""} FormHelperTextProps={{
                    className: globalClasses.helperText
                  }} id="outlined-basic" variant="outlined" {...params} />
                )}
              />
            </FormControl>

            {/* <p>Revision</p> */}
            <FormControl className={classes.root} style={{ paddingRight: "4px", marginLeft: "4px" }} >
              <Autocomplete
                freeSolo
                autoSelect
                options={savedRevisions}
                size="small"
                style={{ width: "70px" }}
                disabled={GetViewMode() ? true : koneMaterial == "" ? true : false}
                value={koneMaterialRev}
                classes={{ input: classes.inputStyle }}
                getOptionLabel={(option) => option}
                ListboxProps={{ style: { maxHeight: '70vh' } }}
                onChange={(e, obj) => {
                  setKoneMaterialRev(obj);
                  getPDMDescription();
                }}
                onKeyUp={(e)=>{
                  convertVersionToUppercase(e);
              }}
                
                onBlur={(e, obj) => {
                  getPDMDescription();
                }}

                onMouseOut={(e, obj) => {
                  getPDMDescription();
                }}

                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <ListItemText primary={option} />
                  </React.Fragment>
                )
                }
                renderInput={(params) => (
                  <TextField error={GetStringEmpty(koneMaterialRev)} helperText={GetStringEmpty(koneMaterialRev) ? "Missing revision" : ""} FormHelperTextProps={{
                    className: globalClasses.helperText
                  }} id="outlined-basic" variant="outlined" {...params} />
                )}
              />
              {/*<FormControl key={"structurerevisionfield"} size="small" style={{ paddingRight: "4px", marginLeft: "4px" }}>
              <Select
                labelId="outlined-basic3"
                SelectDisplayProps={{ style: { paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 30 } }}
                id="outlined-basic3"
                variant="outlined"
                disabled={koneMaterial == "" ? true : false}
                value={koneMaterialRev == "" ? "" : koneMaterialRev}
                onChange={(e, obj) => {
                  setKoneMaterialRev(obj.props.value);
                  //handleRevisionChange(e, obj);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  autoFocus: false,
                }}
              >
                {koneMaterialTestRevisions.map((rev) => {
                    return (<MenuItem key={rev} value={rev}>{rev}</MenuItem>)
                  }
                  )}
              </Select>*/}
            </FormControl>
            <Typography>{' Description : ' + dataValidationPDMDesc}</Typography>
          </div>
        </Paper>
      </TabPanel>
      {/* KM LIST */}
      <TabPanel value={tabValue} index={1}>
        <Paper style={{ maxHeight: "70vh", paddingBottom: "18px" }}>

          <div className={classes.dvDiv} style={{ paddingTop: "4px", paddingBottom: "0px" }}>
            {/* <Typography>{"Run Data Validation for materials in KM list"}</Typography> */}
            <Typography className={classes.testInfoItem}>Name:</Typography>
            {/* <Tooltip title={testName} TransitionComponent={({ children }) => children}>
                        <TextField id="outlined-basic" defaultValue={testName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoName)} />
                    </Tooltip> */}

            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled error={GetStringEmpty(dataValidationName)} helperText={GetStringEmpty(dataValidationName) ? "Missing data validation name" : ""} FormHelperTextProps={{
                className: globalClasses.helperText
              }} InputProps={{ classes: { input: classes.inputStyle } }} id="outlined-basic" value={dataValidationName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoID)} onChange={(e) => { setDataValidationName(e.target.value); }} />
            </Tooltip>

            <Tooltip title={"Generate ID"}>
              <span>
                <Button disabled={GetViewMode()} size="small" className={clsx(classes.generateIdButton, globalClasses.blueButton, GetViewMode() && globalClasses.disabledButton)} onClick={() => { 
                  if (dataValidationName == "")
                  {
                    CallFetchValidationId();
                  }
                }}>

                  <Refresh />
                </Button>
              </span>
            </Tooltip>

            <Typography className={classes.testInfoItem} style={{ paddingLeft: "14px" }}>Description:</Typography>
            <Tooltip title={""} TransitionComponent={({ children }) => children}>
              <TextField disabled={GetViewMode()} error={GetStringEmpty(dataValidationDesc)} helperText={GetStringEmpty(dataValidationDesc) ? "Missing data validation description" : ""} FormHelperTextProps={{
                className: globalClasses.helperText
              }} id="outlined-basic" InputProps={{ classes: { input: classes.inputStyle } }} value={dataValidationDesc} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} onChange={(e) => { setDataValidationDesc(e.target.value); }} />
            </Tooltip>

            <Tooltip title={"Run Data Validation"} TransitionComponent={({ children }) => children}>
              <span>
                <IconButton disabled={GetRunDisabled() || GetViewMode()} style={{ marginLeft: "auto", marginRight: "8px", marginTop: "0px" }} className={clsx(globalClasses.blueButton, (GetRunDisabled() || GetViewMode()) && globalClasses.disabledButton)} onClick={(event) => {
                  handleRunClick(event, "KM List");
                }}>
                  <PlayArrow />
                </IconButton>
              </span>
            </Tooltip>
          </div>
<div className={classes.dvDiv} style={{ paddingTop: "24px", marginLeft:"50px", fontSize: "18px" }}>
Materials
</div>
<div className={classes.dvDiv} style={{ paddingTop: "12px", marginLeft:"50px" }}>
          {
            addKMList()
          }
</div>
          <div style={{ paddingTop: "14px", width: "800px" }}>
          <Box className={classes.testCasesButtonsDivBottom} align="right" width="90%">
                            <Tooltip title={"Save to file (.xlsx)"} TransitionComponent={({ children }) => children}>
                                <IconButton disabled={GetSaveDisabled()} className={clsx(classes.testCasesButton, globalClasses.blueButton)} onClick={handleExportClick}>
                                    <SaveAlt />
                                </IconButton>
                            </Tooltip>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type='file' id='file' ref={excelInput} onChange={handeExcelInputFileChange} onClick={(event)=> { event.currentTarget.value = null }} style={{display: 'none'}}/>
                            <Tooltip title={"Load from file (.xlsx)"} TransitionComponent={({ children }) => children}>
                                <IconButton className={clsx(classes.testCasesButton, globalClasses.blueButton)} onClick={handleImportClick}>
                                    <Publish />
                                </IconButton>
                            </Tooltip>
          </Box>
          </div>
        </Paper>
      </TabPanel>
    </>
  );
}
