import { Divider, IconButton, makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Folder } from "@material-ui/icons";
import clsx from 'clsx';
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-cellLeft": {
            paddingLeft: "0px"
        }
    },
    gridDiv: {
        height: "70.0vh",
        width: '100%',
        margin: "30px auto auto auto",
    },
    dataGrid: {
        "&:last-child": {
            overflowX: "hidden"
        }
    },
}));

export const ReportsPage = (props) => {
    const classes = useStyles();

    const columns = [
        { field: '', headerName: '', width: 60,
        renderCell: function customCellRenderer() {
            return (
                <IconButton>
                    <Folder />
                </IconButton>
            )
        }, },
        { field: 'reportName', headerName: 'Report name', width: 180 },
        { field: 'description', headerName: 'Description', flex: 1 },
    ];

    const rows = [
        // name|status|Trace|Result download|CAD Duration|CAD Check|CAD Loops
        { id: 1, reportName: 'Overall stats', description: '', },
        { id: 2, reportName: 'Downloaded packages', description: '', },
        { id: 3, reportName: 'CAD package stat', description: '', },
        { id: 4, reportName: 'CAD Robot stat', description: '', },
        { id: 5, reportName: 'KM stat', description: '', },
        { id: 6, reportName: 'KPI Metrics', description: '', },
        
    ];

    return (
        <div className={clsx(classes.gridDiv, classes.root)}>
            <DataGrid rowHeight={30} headerHeight={40} rows={rows} columns={columns} disableColumnMenu hideFooter className={classes.dataGrid} />
            </div>
    );
}