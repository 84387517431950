import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import session from './store/session'

const msalInstance = new PublicClientApplication(msalConfig);
// const store = createStore(session);

ReactDOM.render(
  // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      {/* <Provider store={store}> */}
      <HashRouter>
        <App />
      </HashRouter>
      {/* </Provider> */}
    </MsalProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
